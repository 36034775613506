<template>
  <div class="top-menu">
    <!-- 左侧 Logo -->
    <div class="logo">
      <svg width="60" class="ml_10" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1499.81 1499.81">
        <defs></defs>
        <path class="cls-1" d="M350.96,532.43h134.98v59.99h-75.74v150.73h-59.24v-210.72Z"/>
        <path class="cls-1" d="M1163.27,773.15h75.74c7.5,7.75,23.17,23.96,23.17,23.96,0,0-33.67,33.78-50.17,50.28h24c5.75,6,11.5,12,17.25,18v15.75h-75.74c-7.75-8.25-15.5-16.5-23.25-24.75,16.5-16.25,50.27-49.52,50.27-49.52,0,0-17.78.03-26.27.03-5-5.25-10-10.5-15-15.75v-18Z"/>
        <path class="cls-1" d="M1145.11,908.14h-33.75v-51.74c-12.25-12.25-24.5-24.5-36.75-36.75l24-24c9.75,9.5,19.5,19,29.25,28.5,9.75-9.5,19.5-19,29.25-28.5,8,7.75,16,15.5,24,23.25l-36,36v53.24Z"/>
        <path class="cls-1" d="M980.13,796.4c8,7.75,16,15.5,24,23.25-7.75,8-15.5,16-23.25,24-8-7.75-16-15.5-24-23.25,7.75-8,15.5-16,23.25-24Z"/>
        <path class="cls-1" d="M501.69,532.43h133.48v59.99h-74.24v15h74.24v59.99h-74.24v15.75h74.24v59.99h-133.48v-210.72Z"/>
        <path class="cls-1" d="M785.9,532.43v210.72h-59.24c-.25-19.75-.5-39.5-.75-59.24-9,21.75-18,43.5-27,65.24-18.25-7.5-36.5-15-54.74-22.5,27.49-64.74,55-129.49,82.49-194.23h59.24Z"/>
        <path class="cls-1" d="M801.65,532.43h59.24v210.72h-59.24v-210.72Z"/>
        <path class="cls-1" d="M876.64,683.16h133.48v59.99h-133.48v-59.99Z"/>
        <path class="cls-1" d="M277.47,757.41h133.48v59.99h-133.48v-59.99Z"/>
        <path class="cls-1" d="M425.95,757.41h59.24v59.99h-59.24v-59.99Z"/>
        <path class="cls-1" d="M800.9,757.41h134.98v210.72h-134.98v-59.99h75.74v-14.25h-75.74v-59.99h75.74v-16.5h-75.74v-59.99Z"/>
        <path class="cls-1" d="M575.18,758.16h210.72v210.72h-59.24v-151.48h-15.75v151.48h-59.24v-151.48h-17.25v151.48h-59.24v-210.72Z"/>
        <path class="cls-1" d="M1062.38,820.05c10,9.75,31.74,30.34,31.74,30.34l-23.58,24.27s-20.67-21.03-30.42-31.02c-10.25,10-20.5,20-30.75,30-7.75-7.75-15.5-15.5-23.25-23.25,10-10,20-20,30-30-9.75-9.75-19.5-19.5-29.25-29.25v-2.25c7.5-7.5,15-15,22.5-22.5,10,10,20,20,30,30,10-10,20-20,30-30,8,7.75,16,15.5,24,23.25l-30.99,30.4Z"/>
        <path class="cls-1" d="M500.94,833.9h59.99v133.48h-59.99v-133.48Z"/>
        <path class="cls-1" d="M350.21,834.65h59.99v133.48h-59.99v-133.48Z"/>
      </svg>
    </div>

    <!-- 中间的搜索框 -->
    <div class="search-bar">
      <TopSearch/>
    </div>

    <!-- 右侧图标 -->
    <div class="icon">
      <svg aria-label="通知" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img" viewBox="0 0 24 24" width="24"><title>通知</title><path d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path></svg>
    </div>
  </div>
</template>

<script>
import TopSearch from "@/components/TopSearch.vue";

export default {
  name: 'TopMenu',
  components: {TopSearch},
};
</script>

<style scoped>
.ml_10{
  margin-left: -10px;
}
.cls-1 {
  fill: var(--text-color);
  fill-rule: evenodd;
}
@media (max-width: 768px) {
  .top-menu {
      display: flex !important;
  }
}
.top-menu {
  display: none;
  align-items: center;
  justify-content: space-between;
  padding: 4px 10px 7px 10px;
  background-color: var(--background-color);
  color: var(--text-color);
  font-family: Arial, sans-serif;
  border-bottom: 1px solid #606060;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000000000000;
  width: 100%;
}

.logo {
  font-family: 'Segoe Script', sans-serif;
  font-size: 1.4em;
  font-weight: bold;
}

.search-bar {
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0px 3px;
  flex-grow: 1;
  margin: 0 14px;
}

.search-icon {
  color: #888;
  margin-right: 5px;
}

.search-bar input {
  background: none;
  border: none;
  color: #ddd;
  outline: none;
  width: 100%;
  font-size: 1em;
}

.icon {
  font-size: 1.4em;
  cursor: pointer;
}

.heart-icon {
  color: #888;
}
</style>
