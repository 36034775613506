<template>
  <div id="app">
    <router-view/>
    <CreatePostFlow />
    <NewPlanModal />
    <EditPlanModal />
    <NewPlanMenuModal />
    <FloatingMenu/>
  </div>
</template>

<script>
import { modalStore } from './stores/modalStore';
import CreatePostFlow from './components/CreatePostFlow.vue';
import NewPlanModal from './components/NewPlanModal.vue';
import EditPlanModal from './components/EditPlanModal.vue';
import NewPlanMenuModal from './components/NewPlanMenuModal.vue';
import FloatingMenu from "@/components/FloatingMenu.vue";

export default {
  name: 'App',
  components: {
    FloatingMenu,
    CreatePostFlow,
    NewPlanModal,
    NewPlanMenuModal,
    EditPlanModal,
  },
  setup() {
    const commModalStore = modalStore();
    return {
      commModalStore,
    };
  },
  data() {
    return {
    };
  },
};
</script>

<style src="./assets/theme.css"></style>

<style>
.theme_bg{
  background-color: var(--background-color);
  color: var(--text-color);
}
/* 定义过渡动画 */
.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
}

.el-message--success .el-message__content {
  color: #000000 !important;
  overflow-wrap: break-word;
}

/* 全局隐藏滚动条 */
* {
  scrollbar-width: none; /* 适用于 Firefox */
  -ms-overflow-style: none; /* 适用于 Internet Explorer 和 Edge */
}

*::-webkit-scrollbar {
  display: none; /* 适用于 Chrome、Safari 和 Opera */
}

</style>