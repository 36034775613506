// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import UserProfile from '@/views/UserProfile.vue';
import ExplorePage from '@/views/ExplorePage.vue';
import SignUp from '@/views/SignUp.vue';
import LoginPage from '@/views/LoginPage.vue';
import PassWord from '@/views/PassWord.vue';
import SetUp from '@/components/SetUp.vue';
import EditProfile from '@/components/EditProfile.vue';
import UserProfileSetUp from '@/views/UserProfileSetUp.vue';
import MessagePage from '@/views/MessagePage.vue';
import TimeLine from '@/views/TimeLine.vue';
import PortfolioPage from '@/views/PortfolioPage.vue';
import PhoneEditProfile from '@/views/PhoneEditProfile.vue';
import EmailVerification from '@/views/EmailVerification.vue';
import User from "@/views/User.vue";

// 定义路由规则
const routes = [
    {
        path: '/',
        name: 'HomePage',
        component: HomePage,
    },
    {
        path: '/user/:username',
        name: 'User',
        component: User,
        props: true
    },

    {
        path: '/UserProfile',
        name: 'UserProfile',
        component: UserProfile,
        props: false,
    },
    {
        path: '/ExplorePage',
        name: 'ExplorePage',
        component: ExplorePage,
        props: false,
    },
    {
        path: '/SignUp',
        name: 'SignUp',
        component: SignUp,
        props: false,
    },
    {
        path: '/LoginPage',
        name: 'LoginPage',
        component: LoginPage,
        props: false,
    },
    {
        path: '/PassWord',
        name: 'PassWord',
        component: PassWord,
        props: false,
    },
    {
        path: '/UserProfileSetUp',
        name: 'UserProfileSetUp',
        component: UserProfileSetUp,
        props: false,
    },
    {
        path: '/MessagePage',
        name: 'MessagePage',
        component: MessagePage,
        props: false,
    },
    {
        path: '/Post/:id',
        name: 'TimeLine',
        component: TimeLine,
        props: false,
    },
    {
        path: '/PortfolioPage',
        name: 'PortfolioPage',
        component: PortfolioPage,
        props: false,
    },
    {
        path: '/PhoneEditProfile',
        name: 'PhoneEditProfile',
        component: PhoneEditProfile,
        props: false,
    },
    {
        path: '/EmailVerification',
        name: 'EmailVerification',
        component: EmailVerification,
        props: false,
    },

    {
        path: '/SetUp',
        name: 'SetUp',
        component: SetUp,
        children: [
            {
                path: 'EditProfile',
                name: 'EditProfile',
                component: EditProfile,
                meta: { title: '编辑主页' },
            },
            // {
            //     path: 'notifications',
            //     component: Notifications,
            //     meta: { title: '通知' },
            // },
            // {
            //     path: 'privacy',
            //     component: PrivacySettings,
            //     meta: { title: '隐私设置' },
            // },
        ],
    },
];

// 创建路由实例
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

export default router;
