<template>
  <div class="search-container theme_bg">
    <el-input v-model="query" @input="handleInput" placeholder="搜索">
      <template #prefix>
        <i class="el-icon-search"></i>
      </template>
      <template #suffix>
        <svg v-if="query" @click="clearQuery" t="1729579274116" class="icon input_del_icon" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8706" id="mx_n_1729579274117" width="32" height="32">
          <path d="M513.852 144.914c-203.711 0-368.936 165.192-368.936 368.936 0 203.756 165.203 368.936 368.936 368.936 203.756 0 368.936-165.143 368.936-368.936 0.005-203.711-165.177-368.936-368.936-368.936zM665.109 616.241c6.27 6.218 10.153 14.838 10.153 24.363 0 0.025 0 0.050 0 0.076 0 0.003 0 0.008 0 0.017 0 19.086-15.472 34.558-34.558 34.558-9.555 0-18.204-3.877-24.46-10.146l-102.39-102.331-102.319 102.364c-6.255 6.272-14.905 10.153-24.462 10.153-0.016 0-0.031 0-0.046 0-19.061 0-34.558-15.463-34.558-34.598 0-0.018 0-0.037 0-0.056 0-9.517 3.866-18.129 10.115-24.354l102.379-102.435-102.355-102.349c-6.25-6.245-10.117-14.875-10.117-24.408 0-0.021 0-0.042 0-0.063 0-19.093 15.486-34.583 34.586-34.583 0.010 0 0.023 0 0.036 0 9.533 0 18.162 3.865 24.409 10.116l102.319 102.378 102.39-102.352c6.256-6.25 14.896-10.116 24.438-10.116 19.098 0 34.579 15.482 34.579 34.579 0 0.003 0 0.007 0 0.007 0 0.020 0 0.044 0 0.067 0 9.537-3.882 18.168-10.15 24.398l-102.321 102.321 102.33 102.396z" fill="#8a8a8a" p-id="8707"></path>
        </svg>
      </template>
    </el-input>

    <!-- 搜索类型选择框 -->
    <div v-if="showSearchType && query" class="search-type-options">
      <div @click="selectSearchType('user')" type="text" class="search-option">
        <svg data-v-721a6e56="" aria-label="搜索" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="22" role="img" viewBox="0 0 24 24" width="22"><title data-v-721a6e56="">搜索</title><path data-v-721a6e56="" d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><line data-v-721a6e56="" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="16.511" x2="22" y1="16.511" y2="22"></line></svg> 搜索相关用户
      </div>
      <div @click="selectSearchType('post')" type="text" class="search-option">
        <svg data-v-721a6e56="" aria-label="搜索" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="22" role="img" viewBox="0 0 24 24" width="22"><title data-v-721a6e56="">搜索</title><path data-v-721a6e56="" d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><line data-v-721a6e56="" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" x1="16.511" x2="22" y1="16.511" y2="22"></line></svg> 搜索相关帖子
      </div>
    </div>

    <!-- 搜索结果展示 -->
    <div v-if="query && searchType" class="search-results">
      <el-scrollbar v-if="allResults && allResults.length > 0">
        <el-list>
          <el-list-item v-for="(result, index) in allResults" :key="index">
            <router-link :to="searchType === 'user' ? `/user/${result.name}` : `/post/${result.id}`" class="result-link">
              <div class="result-item">
                <img :src="result.avatar || result.image" class="avatar" alt="avatar" />
                <div class="result-info">
                  <span>{{ result.name || result.title }}</span>
                  <span class="result-desc">{{ result.desc }}</span>
                </div>
              </div>
            </router-link>
          </el-list-item>
        </el-list>
      </el-scrollbar>
      <div v-else>暂无结果</div>
    </div>

    <!-- 近期搜索 -->
    <div v-else class="recent-searches">
      <div class="recent-header">
        <span>近期搜索</span>
        <div type="text" @click="clearAllRecentSearches" class="clear-all-button">全部清除</div>
      </div>
      <el-list>
        <el-list-item v-for="(recent, index) in recentSearches" :key="index" class="recent-list-item" @click="goDetail(recent)">
            <div class="recent-item">
              <img :src="recent.avatar" class="avatar" alt="avatar" />
              <div class="recent-info">
                <span>{{ recent.name }}</span>
                <span class="recent-desc">{{ recent.desc }}</span>
              </div>
            </div>
        </el-list-item>
      </el-list>
    </div>
  </div>
</template>

<script>
import { getRecommUserRequest, searchRequest } from "@/api/api";

export default {
  data() {
    return {
      query: '',
      searchType: '', // 搜索类型
      showSearchType: false, // 是否显示搜索类型选择框
      recentSearches: [],
      allResults: [], // 存储搜索结果
    };
  },
  mounted() {
    this.getCommUserData();
  },
  methods: {
    goDetail(user){
      this.$router.push({name: 'User', params: {username: user.user_name}});
    },
    getCommUserData() {
      getRecommUserRequest().then((res) => {
        if (res.code === 200) {
          this.recentSearches = res.data;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    handleInput() {
      if (!this.query) {
        this.showSearchType = false;
        return;
      }
      // 输入内容后显示搜索类型选择框
      this.showSearchType = true;
      // 发起搜索请求，根据输入的关键词动态展示搜索结果
      searchRequest({ keyword: this.query }).then((res) => {
        if (res.code === 200) {
          this.allResults = res.data;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    selectSearchType(type) {
      this.searchType = type; // 设置搜索类型
      this.showSearchType = false; // 隐藏搜索类型选择框
      // 根据选择的搜索类型执行相应的搜索
      searchRequest({ keyword: this.query, type: this.searchType }).then((res) => {
        if (res.code === 200) {
          this.allResults = res.data;
        }
      }).catch(err => {
        console.log(err);
      });
    },
    clearQuery() {
      this.query = '';
      this.showSearchType = false;
      this.searchType = '';
    },
    removeResult(index) {
      this.allResults.splice(index, 1);
    },
    removeRecentSearch(index) {
      this.recentSearches.splice(index, 1);
    },
    clearAllRecentSearches() {
      this.recentSearches = [];
    },
  },
};
</script>

<style scoped>
.input_del_icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

/* 保留原样式，不做改动 */
.search-container {
  width: 350px;
  position: relative;
}

.el-input {
  width: 100%;
}

.search-results,
.recent-searches {
  position: relative;
  top: 15px;
  width: 100%;
  background: var(--background-color);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  overflow-y: auto;
  z-index: 10;
}

.recent-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
}

.clear-all-button {
  color: #409EFF;
  font-size: 14px;
  cursor: pointer;
}

.result-item,
.recent-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.result-info,
.recent-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.result-desc,
.recent-desc {
  font-size: 14px;
  color: #888;
}

.result-link {
  text-decoration: none;
  color: inherit;
}

.remove-button {
  color: #888;
  font-size: 16px;
}

.recent-list-item {
  border-bottom: 1px solid #f0f0f0;
}

/* 搜索类型选择框样式 */
.search-type-options {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 9px;
}

.search-option {
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--text-color);
  cursor: pointer;
  gap: 10px;
}

.search-option i {
  margin-right: 8px;
}
</style>
