// src/stores/messageStore.js
import { defineStore } from 'pinia';

export const PostDetailStore = defineStore('PostDetailStore', {
    state: () => ({
        post_info: {},//详情页的帖子信息

    }),
    actions: {
        setPostInfo(value){
            this.post_info=value;
        },
        getPostInfo(){
            return this.post_info;
        },


    },
});
