<template>
  <div class="profile-container" @mouseenter="checkButtons" @mouseleave="hideButtons">
    <div class="profile-list" ref="profileList">
      <div @click="goTouser(user)" v-for="(user, index) in users" :key="index" class="profile-item">
        <div class="profile-avatar">
          <img v-if="user.avatar" :src="user.avatar" alt="avatar" class="profile-avatar-img" />
          <svg v-else t="1731673675159" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5345" width="" height=""><path d="M1023.878734 511.939367A511.939367 511.939367 0 1 1 511.939367 0a511.939367 511.939367 0 0 1 511.939367 511.939367z" fill="#D9D9D9" p-id="5346"></path><path d="M511.939367 255.969684a191.977263 191.977263 0 1 0 191.977263 191.977262 191.977263 191.977263 0 0 0-191.977263-191.977262z m0 479.943156c-108.787116 0-305.243848 43.834808-365.716685 134.384084a511.939367 511.939367 0 0 0 731.433371 0C817.183215 779.747649 620.726483 735.91284 511.939367 735.91284z" fill="#8C8C8C" p-id="5347"></path></svg>
        </div>
        <span class="profile-name">{{ user.name }}</span>
      </div>
    </div>
    <div v-if="showPrevButton" class="prev-button" @click="scrollPrev">
      <svg t="1729080470985" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="10204" ><path d="M452.266667 507.733333l-29.866667 29.866667 29.866667 29.866667 115.2 115.2 29.866666-29.866667-115.2-115.2L597.333333 422.4l-29.866666-29.866667-115.2 115.2z m81.066666 388.266667c200.533333 0 362.666667-162.133333 362.666667-362.666667S733.866667 170.666667 533.333333 170.666667 170.666667 332.8 170.666667 533.333333 332.8 896 533.333333 896z m0-42.666667C358.4 853.333333 213.333333 708.266667 213.333333 533.333333S358.4 213.333333 533.333333 213.333333 853.333333 358.4 853.333333 533.333333 708.266667 853.333333 533.333333 853.333333z" fill="#444444" p-id="10205"></path></svg>
    </div>
    <div v-if="showNextButton" class="next-button" @click="scrollNext">
      <svg t="1729080357758" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="9140" ><path d="M614.4 507.733333l29.866667 29.866667-29.866667 29.866667-115.2 115.2-29.866667-34.133334 115.2-115.2L469.333333 422.4l29.866667-29.866667 115.2 115.2zM533.333333 896C332.8 896 170.666667 733.866667 170.666667 533.333333S332.8 170.666667 533.333333 170.666667 896 332.8 896 533.333333 733.866667 896 533.333333 896z m0-42.666667c174.933333 0 320-145.066667 320-320S708.266667 213.333333 533.333333 213.333333 213.333333 358.4 213.333333 533.333333 358.4 853.333333 533.333333 853.333333z" fill="#444444" p-id="9141"></path></svg>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import {getRecommUserRequest} from "@/api/api";

export default defineComponent({
  name: 'ProfileCarousel',
  data() {
    return {
      users: [

      ],
      showPrevButton: false,
      showNextButton: false,
      visibleItems: 6, // 一次展示的数量，用于计算
    };
  },

  methods: {
    getCommUserData(){
      getRecommUserRequest().then(res=>{
        if(res.code===200){
          this.users=res.data;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    goTouser(user) {
      // 跳转到用户详情页的逻辑
      this.$router.push({name: 'User', params: {username: user.user_name}});
    },
    // 滚动到上一页
    scrollPrev() {
      const profileList = this.$refs.profileList;
      const itemWidth = profileList.querySelector('.profile-item').offsetWidth;
      profileList.scrollBy({ left: -itemWidth * this.visibleItems, behavior: 'smooth' });
    },
    // 滚动到下一页
    scrollNext() {
      const profileList = this.$refs.profileList;
      const itemWidth = profileList.querySelector('.profile-item').offsetWidth;
      profileList.scrollBy({ left: itemWidth * this.visibleItems, behavior: 'smooth' });
    },
    // 检查按钮的显示
    checkButtons() {
      const profileList = this.$refs.profileList;
      this.showPrevButton = profileList.scrollLeft > 0;
      this.showNextButton = profileList.scrollLeft + profileList.clientWidth < profileList.scrollWidth;
    },
    // 隐藏按钮
    hideButtons() {
      this.showPrevButton = false;
      this.showNextButton = false;
    }
  },
  mounted() {
    this.getCommUserData();
    this.checkButtons();
    this.$refs.profileList.addEventListener('scroll', this.checkButtons);
  },
  beforeUnmount() {
    this.$refs.profileList.removeEventListener('scroll', this.checkButtons);
  }
});
</script>

<style scoped>
.profile-container {
  width: 600px; /* 限制容器最大宽度 */
  overflow: hidden; /* 隐藏垂直方向的滚动条 */
  border: none;
  position: relative; /* 确保定位正确 */
}

.profile-list {
  display: flex; /* 使用 flex 布局 */
  flex-wrap: nowrap; /* 禁止换行 */
  overflow-x: scroll; /* 允许横向滚动 */
  -ms-overflow-style: none; /* IE 和 Edge 浏览器隐藏滚动条 */
  scrollbar-width: none; /* Firefox 隐藏滚动条 */
}

.profile-list::-webkit-scrollbar {
  display: none; /* Chrome 和 Safari 隐藏滚动条 */
}

.profile-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;
  margin: 10px 2px;
  flex-shrink: 0;
}

.profile-avatar {
  width: 52px; /* 头像宽度 */
  height: 52px; /* 头像高度 */
  border-radius: 50%; /* 设置圆形 */
  border: 2px solid transparent;
  background: linear-gradient(white, white), radial-gradient(circle at top left, #f77737, #e1306c, #e600f2, #833ab4, #e3630b, #e6c727);
  background-origin: border-box;
  background-clip: content-box, border-box;
  display: flex !important;
  align-content: center;
  justify-content: center;
}
.profile-avatar-img {
  width: 47px;
  height: 47px;
  border-radius: 50%;
}

.profile-name {
  display: block;
  margin-top: 8px;
  font-size: 12px;
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
  font-weight: 350;
}

.next-button, .prev-button {
  position: absolute;
  top: 20%;
  transform: translateY(-30%);
  cursor: pointer;
  border: none;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  opacity: 0.8;
}
.next-button .icon, .prev-button .icon{
  width: 32px;
  height: 32px;
}

.next-button {
  right: 10px;
}

.prev-button {
  left: 10px;
}


</style>
