<template>
  <div class="forgot-password-container theme_bg">
    <div class="forgot-password-box">
      <h1 class="logo">Realtime.Art</h1>
      <div class="icon-lock">
        <i class="el-icon-lock"></i>
      </div>
      <h2 class="title">无法登录？</h2>
      <p class="instruction">
        请使用您注册时填写的邮箱进行密码找回操作。
      </p>
      <el-form :model="form" :rules="rules" ref="forgotPasswordForm" @submit.prevent="onSubmit" class="forgot-password-form">
        <el-form-item prop="emailOrPhone">
          <el-input v-model="form.emailOrPhone" placeholder="邮箱" @focus="handleFocus('emailOrPhone')" @blur="handleBlur('emailOrPhone')" :class="{ active: activeInput === 'emailOrPhone' }" />
        </el-form-item>
        <el-form-item prop="code" :rules="codeRules">
          <el-input class="get-code-input" v-model="form.code" placeholder="请输入验证码" />
          <el-button
              :disabled="isCounting"
              @click="handleGetCode"
              class="get-code-btn"
              type="info"
          >
            {{ isCounting ? countdown + 's' : '发送验证码' }}
          </el-button>

        </el-form-item>
        <el-form-item prop="newPassword">
          <el-input
              v-model="form.newPassword"
              type="password"
              placeholder="请输入新密码"
          ></el-input>
        </el-form-item>

        <el-form-item  prop="confirmPassword">
          <el-input
              v-model="form.confirmPassword"
              type="password"
              placeholder="请确认新密码"
          ></el-input>
        </el-form-item>
        <el-button type="primary"  size="large" class="send-link-button" @click="onSubmit">提交重置密码</el-button>
      </el-form>

      <p class="back-to-login-link" @click="goToLoginPage">返回登录</p>
    </div>

    <div class="download-app">
      <ResponsiveFooter/>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus';
import ResponsiveFooter from "@/components/ResponsiveFooter.vue";
import {resetPwdRequest, sendResetCodeRequest} from "@/api/api";
export default {
  name: 'InstagramForgotPassword',
  components: {
    ResponsiveFooter,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
  data() {
    return {
      countdown: 0,
      isCounting: false,
      form: {
        emailOrPhone: '',
      },
      activeInput: null,
      rules: {
        emailOrPhone: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const phoneRegex = /^[0-9]{7,}$/;
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (value.includes('@')) {
                if (!emailRegex.test(value)) {
                  callback(new Error('请输入正确的邮箱地址'));
                } else {
                  callback();
                }
              } else {
                if (!phoneRegex.test(value) && value.length < 4) {
                  callback(new Error('请输入正确的账号或电话号码'));
                } else {
                  callback();
                }
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              const pattern = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,}$/;
              if (!pattern.test(value)) {
                callback(new Error('密码必须包含字母、数字和特殊字符'));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        confirmPassword: [
          { required: true, message: '请确认新密码', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              if (value !== this.form.newPassword) {
                callback(new Error('两次密码输入不一致'));
              } else {
                callback();
              }
            },
            trigger: ['blur', 'change'],
          },
        ],

      },
    };
  },
  methods: {
    // 获取验证码
    async handleGetCode() {

      //判断邮箱一栏不能为空并且不能为非邮箱格式,如果不符合则提示错误
      this.$refs.forgotPasswordForm.validateField('emailOrPhone').then(() => {
        this.isCounting = true;
        this.countdown = 60;

        // 调用后端接口发送验证码
        try {
          sendResetCodeRequest({email:this.form.emailOrPhone}).then(response => {
            if (response.code === 200) {
              ElMessage.success('验证码已发送到您的邮箱,请注意查收');
            } else {
              ElMessage.error(response.message || '验证码发送失败');
              this.isCounting = false; // 失败时停止倒计时
            }
          }).catch(error => {
            console.error('发送验证码失败:', error);
            ElMessage.error('发送验证码失败，请稍后再试');
            this.isCounting = false; // 请求失败时停止倒计时
          });

        } catch (error) {
          console.error('发送验证码失败:', error);
          ElMessage.error('发送验证码失败，请稍后再试');
          this.isCounting = false; // 请求失败时停止倒计时
        }

        // 开始倒计时
        const interval = setInterval(() => {
          if (this.countdown === 0) {
            clearInterval(interval);
            this.isCounting = false;
          } else {
            this.countdown--;
          }
        }, 1000);
      }).catch(() => {
        ElMessage.error('请输入正确的邮箱地址');
      });


    },
    goToLoginPage() {
      this.$router.push({ name: 'LoginPage' });
    },
    goToSignUp() {
      this.$router.push({ name: 'SignUp' });
    },
    handleFocus(input) {
      this.activeInput = input;
    },
    handleBlur(input) {
      if (this.activeInput === input) {
        this.activeInput = null;
      }
    },
    onSubmit() {
      this.$refs.forgotPasswordForm.validate((valid) => {
        if (valid) {
          resetPwdRequest(this.form).then(response => {
            if (response.code === 200) {
              ElMessage.success('密码重置成功');
              this.$router.push({ name: 'LoginPage' });
            } else {
              ElMessage.error(response.message || '密码重置失败');
            }
          }).catch(error => {
            console.error('密码重置失败:', error);
            ElMessage.error('密码重置失败，请稍后再试');
          });
        } else {
          console.log('Validation Failed');
          ElMessage({
            message: '请完成所有必填项并确保输入正确',
            type: 'error',
          });
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.get-code-btn {
  width: 86px;
  font-size: 13px;
}
.get-code-btn:hover {
  background: #525252;
  font-size: 13px;
}
.download-app {
  margin-top: 20px;
  text-align: center;
  position: fixed;
  bottom: 50px;
}
.get-code-input{
  width: calc(100% - 86px);
}
.forgot-password-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.forgot-password-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  text-align: center;
}

.logo {
  font-family: 'Lobster', cursive;
  font-size: 36px;
  margin-bottom: 20px;
}

.icon-lock {
  font-size: 48px;
  color: #333;
  margin-bottom: 20px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.instruction {
  font-size: 14px;
  margin-bottom: 20px;
}

.forgot-password-form {
  width: 100%;
}

.send-link-button {
  width: 100%;
  background-color: #3897f0;
  color: #fff;
  margin-top: 10px;
}

.reset-password-link {
  margin-top: 15px;
  color: #00376b;
  cursor: pointer;
}

.or-divider {
  margin: 20px 0;
  position: relative;
  width: 100%;
  text-align: center;
}

.or-divider span {
  background-color: #fff;
  padding: 0 10px;
  color: #999;
}

.create-account-link {
  margin-top: 10px;
  font-weight: bold;
  cursor: pointer;
}

.back-to-login-link {
  margin-top: 20px;
  color: #3897f0;
  cursor: pointer;
}

.el-input.active::placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  color: #bbb;
}
</style>
