// src/stores/messageStore.js
import { defineStore } from 'pinia';

export const StatusStore = defineStore('StatusStore', {
    state: () => ({
        UserIsCollect: false,//是否在收藏夹

    }),
    actions: {
        setUserIsCollect(UserIsCollect){
            this.UserIsCollect=UserIsCollect;
        },
        getUserIsCollect(){
            return this.UserIsCollect;
        },


    },
});
