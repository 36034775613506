<template>
  <div class="post-container theme_bg">
    <!-- 帖子内容部分 -->
    <div class="post_header_1" v-if="postDetail">
      <div class="post_header_1_inner">
        <div class="post_header_1_left">
          <el-avatar :src="postDetail.plan?postDetail.plan.user.avatar:postDetail.avatar" class="post-avatar"/>
          <div class="post-username">
            <a :href="'/user/' + postDetail.plan?postDetail.plan.user.id:postDetail.username" target="_blank" style="text-decoration: none;">
              {{ postDetail.plan?postDetail.plan.user.name:postDetail.username }}
            </a>
          </div>
        </div>
        <div class="action_modal_btn" v-if="postDetail.is_author" @click="showModal">
          <svg
              aria-label="更多选项"
              class="x1lliihq x1n2onr6 x5n08af"
              fill="currentColor"
              height="24"
              role="img"
              viewBox="0 0 24 24"
              width="24"
          >
            <title>更多选项</title>
            <circle cx="12" cy="12" r="1.5"></circle>
            <circle cx="6" cy="12" r="1.5"></circle>
            <circle cx="18" cy="12" r="1.5"></circle>
          </svg>
        </div>
        <!-- ActionModal 组件 -->
        <ActionModal v-model:isActionModalVisible="isModalVisible" @actionConfirmed="handleAction"/>
      </div>

      <div class="post-header">

        <div class="post-details">

          <span class="post-content" v-html="highlightMentions(postDetail.description)"></span>
          <div class="post-hashtags">
            <span v-for="hashtag in postDetail.hashtags" :key="hashtag" class="hashtag">#{{ hashtag }}</span>
          </div>
          <div class="post-tags">
            <span v-for="tag in postDetail.tags" :key="tag" class="tag">@{{ tag }}</span>
          </div>
          <span class="post-timestamp">{{ postDetail.post_time }}</span>
        </div>
      </div>
      <!-- 按热度和按版本按钮 -->
      <div class="bx_btn_group">
        <el-button :type="order_type===0?'primary':'info'" @click="change_order_type(0)">按热度</el-button>
        <el-button :type="order_type===1?'primary':'info'" @click="change_order_type(1)">按版本</el-button>
      </div>
    </div>

    <div class="post_header_mid" v-if="comments">
      <!-- 评论区域 -->
      <div class="comments-container" v-if="comments.length>0">
        <div v-for="(comment,index) in comments" :key="comment.id" class="comment-item">
          <el-avatar :src="comment.user.avatar"/>
          <div class="comment-content">
            <div class="comment-header">
            <span class="username">
              <a :href="'/user/' + comment.user.id" target="_blank" style="text-decoration: none;">
                {{ comment.user.name }}
              </a>
            </span>

              <!-- 评论点赞按钮 -->
              <button @click="toggleLikeComment(index,comment.id,comment.is_liked_by_me)" class="comment-like-button">
                <svg
                    v-if="comment.is_liked_by_me"
                    aria-label="取消赞"
                    class="comment-like-icon pl_icon"
                    fill="currentColor"
                    height="16"
                    role="img"
                    viewBox="0 0 48 48"
                    width="16"
                >
                  <title>取消赞</title>
                  <path
                      d="M34.6
                   3.1c-4.5 0-7.9 1.8-10.6
                   5.6-2.7-3.7-6.1-5.5-10.6-5.5C6
                   3.1 0 9.6 0 17.6c0 7.3 5.4 12
                   10.6 16.5.6.5 1.3 1.1 1.9
                   1.7l2.3 2c4.4 3.9 6.6 5.9
                   7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2
                   1.6-.5c1-.6 2.8-2.2
                   7.8-6.8l2-1.8c.7-.6 1.3-1.2
                   2-1.7C42.7 29.6 48 25 48
                   17.6c0-8-6-14.5-13.4-14.5z"
                  ></path>
                </svg>
                <svg
                    v-else
                    aria-label="赞"
                    class="comment-like-icon pl_icon pl_icon_2"
                    fill="currentColor"
                    height="16"
                    role="img"
                    viewBox="0 0 24 24"
                    width="16"
                >
                  <title>赞</title>
                  <path
                      d="M16.792
                   3.904A4.989 4.989 0 0 1 21.5
                   9.122c0 3.072-2.652 4.959-5.197
                   7.222-2.512 2.243-3.865
                   3.469-4.303
                   3.752-.477-.309-2.143-1.823-4.303-3.752C5.141
                   14.072 2.5 12.167 2.5
                   9.122a4.989 4.989 0 0 1
                   4.708-5.218 4.21 4.21 0 0 1
                   3.675 1.941c.84 1.175.98 1.763
                   1.12 1.763s.278-.588
                   1.11-1.766a4.17 4.17 0 0 1
                   3.679-1.938m0-2a6.04 6.04 0 0
                   0-4.797 2.127 6.052 6.052 0 0
                   0-4.787-2.127A6.985 6.985 0 0 0
                   .5 9.122c0 3.61 2.55 5.827
                   5.015 7.97.283.246.569.494.853.747l1.027.918a44.998
                   44.998 0 0 0 3.518 3.018 2
                   2 0 0 0 2.174 0 45.263
                   45.263 0 0 0
                   3.626-3.115l.922-.824c.293-.26.59-.519.885-.774
                   2.334-2.025 4.98-4.32
                   4.98-7.94a6.985 6.985 0 0
                   0-6.708-7.218Z"
                  ></path>
                </svg>

              </button>
            </div>
            <div class="comment-body">
              <span v-html="highlightMentions(comment.content)"></span>
            </div>
            <div class="comment-actions">
              <div class="comment-actions_left">
                {{ comment.likes }} 次赞
                <div type="text" style="cursor: pointer;" @click="toggleReplyInput(comment, comment.user.username)">回复</div>
                <!-- 删除按钮，只有在符合条件时显示 -->
<!--                <el-button-->
<!--                    v-if="canDeleteComment(comment.user.username)"-->
<!--                    type="text"-->
<!--                    @click="deleteComment(comment.id)"-->
<!--                >-->
<!--                  删除-->
<!--                </el-button>-->
              </div>


            </div>

            <!-- 回复列表 -->
            <div class="replies" v-if="comment.replies&&comment.replies.length > 0">
              <div v-for="(reply,reply_index) in comment.replies" :key="reply.id" class="reply-item">
                <div class="reply-content">
                  <div class="reply-content-ddddd">
                    <el-avatar class="reply-content_content" :src="reply.user.avatar"/>
                    <div class="reply-content_1">
                      <div class="reply-content_1_up">
                        <div class="username">
                          <a :href="'/user/' + reply.user.id" target="_blank" style="text-decoration: none;">
                            {{ reply.user.name }}
                          </a>
                        </div>
                        <span v-if="reply.replyTo">回复 @{{ reply.replyTo }}</span>
                      </div>
                      <div>{{reply.content}}</div>
                    </div>
                  </div>


                  <!-- 回复点赞按钮 -->
                  <button @click="toggleLikeReply(index,reply_index,reply.id,reply.is_liked_by_me)" class="reply-like-button">
                    <svg
                        v-if="reply.is_liked_by_me"
                        aria-label="取消赞"
                        class="comment-like-icon pl_icon"
                        fill="currentColor"
                        height="16"
                        role="img"
                        viewBox="0 0 48 48"
                        width="16"
                    >
                      <title>取消赞</title>
                      <path
                          d="M34.6
                       3.1c-4.5 0-7.9 1.8-10.6
                       5.6-2.7-3.7-6.1-5.5-10.6-5.5C6
                       3.1 0 9.6 0 17.6c0 7.3 5.4 12
                       10.6 16.5.6.5 1.3 1.1 1.9
                       1.7l2.3 2c4.4 3.9 6.6 5.9
                       7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2
                       1.6-.5c1-.6 2.8-2.2
                       7.8-6.8l2-1.8c.7-.6 1.3-1.2
                       2-1.7C42.7 29.6 48 25 48
                       17.6c0-8-6-14.5-13.4-14.5z"
                      ></path>
                    </svg>
                    <svg
                        v-else
                        aria-label="赞"
                        class="comment-like-icon pl_icon pl_icon_2"
                        fill="currentColor"
                        height="16"
                        role="img"
                        viewBox="0 0 24 24"
                        width="16"
                    >
                      <title>赞</title>
                      <path
                          d="M16.792
                       3.904A4.989 4.989 0 0 1 21.5
                       9.122c0 3.072-2.652 4.959-5.197
                       7.222-2.512 2.243-3.865
                       3.469-4.303
                       3.752-.477-.309-2.143-1.823-4.303-3.752C5.141
                       14.072 2.5 12.167 2.5
                       9.122a4.989 4.989 0 0 1
                       4.708-5.218 4.21 4.21 0 0 1
                       3.675 1.941c.84 1.175.98 1.763
                       1.12 1.763s.278-.588
                       1.11-1.766a4.17 4.17 0 0 1
                       3.679-1.938m0-2a6.04 6.04 0 0
                       0-4.797 2.127 6.052 6.052 0 0
                       0-4.787-2.127A6.985 6.985 0 0 0
                       .5 9.122c0 3.61 2.55 5.827
                       5.015 7.97.283.246.569.494.853.747l1.027.918a44.998
                       44.998 0 0 0 3.518 3.018 2
                       2 0 0 0 2.174 0 45.263
                       45.263 0 0 0
                       3.626-3.115l.922-.824c.293-.26.59-.519.885-.774
                       2.334-2.025 4.98-4.32
                       4.98-7.94a6.985 6.985 0 0
                       0-6.708-7.218Z"
                      ></path>
                    </svg>

                  </button>
                </div>
                <div class="reply-actions">
                  <div class="reply-actions_left">
                    {{ reply.likes }} 次赞
                    <div style="cursor: pointer;" type="text" @click="toggleReplyInput(comment, reply.username)">回复</div>
                    <!-- 删除按钮，只有在符合条件时显示 -->
<!--                    <el-button-->
<!--                        v-if="canDeleteComment(reply.username)"-->
<!--                        type="text"-->
<!--                        class="reply-delete"-->
<!--                        @click="deleteReply(comment.id, reply.id)"-->
<!--                    >-->
<!--                      删除-->
<!--                    </el-button>-->
                  </div>



                </div>
              </div>

              <!-- 加载更多回复按钮 -->
              <div v-if="comment.hasMoreReplies" class="load-more-button">
                <button @click="loadReplies(comment.id)" :disabled="isLoading">
                  {{ isLoading ? '加载中...' : '加载更多回复' }}
                </button>
              </div>


            </div>

            <!-- 回复输入框 -->
            <div v-if="comment.showReplyInput" class="reply-input-container">
              <div class="comment-input-container">
                <!-- 表情按钮 -->
                <div class="emoji-button" @click="toggleEmojiPicker(comment)">
                  <!-- 表情图标 -->
                  <svg
                      t="1729750205868"
                      class="icon icon_bq"
                      viewBox="0 0 1040 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="20459"
                      width="24"
                      height="24"
                  >
                    <path
                        d="M512.075261
                     1024A511.774217 511.774217 0 1 1 730.482434
                     48.769072a37.630457 37.630457 0 1 1-32.061149
                     68.035867 436.513303 436.513303 0 1 0
                     250.468323 395.270322 37.630457 37.630457 0 0 1
                     75.260914 0 512.526826 512.526826 0 0
                     1-512.075261 511.924739z"
                        fill=""
                        p-id="20460"
                    ></path>
                    <path
                        d="M333.857416
                     344.0929a57.348817 57.348817 0 1 0 57.348817
                     57.348817 57.499339 57.499339 0 0
                     0-57.348817-57.348817zM686.53006
                     344.0929a57.348817 57.348817 0 1 0 57.348817
                     57.348817 57.348817 57.348817 0 0
                     0-57.348817-57.348817zM515.236219
                     783.165074c-162.864619
                     0-262.359547-141.942084-262.359547-219.159782a30.104366
                     30.104366 0 0 1 60.208731 0c0
                     48.618551 76.314567 158.951051
                     202.150816 158.951051s193.571072-134.114949
                     193.571072-158.951051a30.104366 30.104366 0 0
                     1 60.208731 0c0 54.488902-90.012054
                     219.159782-253.779803
                     219.159782zM1009.549904
                     207.720123h-67.132735V139.985301a30.104366
                     30.104366 0 1 0-60.208732
                     0v67.734822h-67.734822a30.104366 30.104366
                     0 0 0-30.104366 30.104366 30.104366
                     30.104366 0 0 0 30.104366
                     30.104366h67.734822v67.734823a30.104366
                     30.104366 0 0 0 60.208732
                     0v-67.734823h67.734823a30.104366 30.104366
                     0 0 0 30.104365-30.104366 30.104366
                     30.104366 0 0 0-30.706453-30.104366z"
                        fill=""
                        p-id="20461"
                    ></path>
                  </svg>
                </div>
                <div v-if="comment.showEmojiPicker" class="emoji-picker_content">
                  <emoji-picker @emoji-click="addEmojiToComment($event, comment)" class="emoji-picker"></emoji-picker>
                </div>
                <el-input
                    id="reply_input"
                    v-model="comment.newReply"
                    :placeholder="'回复 ' + (comment.replyToUsername ? '@' + comment.replyToUsername : '')"
                    class="comment-input"
                    @input="checkMention(comment)"
                    :ref="'commentInput' + comment.id"
                ></el-input>
              </div>
              <div v-if="mentionMode && filteredUsers.length > 0" class="mention-dropdown">
                <div
                    v-for="user in filteredUsers"
                    :key="user.id"
                    @click="selectMentionInComment(user.username, comment)"
                    class="mention-item"
                >
                  <el-avatar :src="user.avatar"/>
                  <span>{{ user.username }}</span>
                </div>
              </div>
              <el-button type="primary" @click="addReply(comment)" class="comment-submit">发布</el-button>
            </div>
          </div>
        </div>

        <!-- 加载更多按钮 -->
        <div v-if="hasMoreComments" class="load-more-button">
          <button @click="loadComments" :disabled="isLoading">
            {{ isLoading ? '加载中...' : '加载更多评论' }}
          </button>
        </div>


      </div>
      <div v-else> 暂无评论</div>
    </div>

    <!-- 原帖子的回复输入框 -->
    <div class="reply_group" v-if="postDetail">
      <div class="post-footer">
        <div class="post-footer_left">
          <!-- 帖子点赞 -->
          <button @click="toggleLikePost" class="post-like-button">
            <svg
                v-if="postDetail.is_liked_by_me"
                :class="{ 'like-animation': animateLike }"
                aria-label="取消赞"
                class="comment-like-icon pl_icon"
                fill="currentColor"
                height="24"
                role="img"
                viewBox="0 0 48 48"
                width="24"
                @animationend="resetLikeAnimation"
            >
              <title>取消赞</title>
              <path
                  d="M34.6 3.1c-4.5
             0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6
             3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6
             16.5.6.5 1.3 1.1 1.9 1.7l2.3
             2c4.4 3.9 6.6 5.9 7.6
             6.5.5.3 1.1.5 1.6.5s1.1-.2
             1.6-.5c1-.6 2.8-2.2
             7.8-6.8l2-1.8c.7-.6 1.3-1.2
             2-1.7C42.7 29.6 48 25 48
             17.6c0-8-6-14.5-13.4-14.5z"
              ></path>
            </svg>
            <svg
                v-else
                aria-label="赞"
                class="comment-like-icon pl_icon pl_icon_2"
                fill="currentColor"
                height="24"
                role="img"
                viewBox="0 0 24 24"
                width="24"
            >
              <title>赞</title>
              <path
                  d="M16.792
             3.904A4.989 4.989 0 0 1 21.5 9.122c0
             3.072-2.652 4.959-5.197 7.222-2.512
             2.243-3.865 3.469-4.303
             3.752-.477-.309-2.143-1.823-4.303-3.752C5.141
             14.072 2.5 12.167 2.5
             9.122a4.989 4.989 0 0 1
             4.708-5.218 4.21 4.21 0 0 1
             3.675 1.941c.84 1.175.98 1.763
             1.12 1.763s.278-.588
             1.11-1.766a4.17 4.17 0 0 1
             3.679-1.938m0-2a6.04 6.04 0 0
             0-4.797 2.127 6.052 6.052 0 0
             0-4.787-2.127A6.985 6.985 0 0 0
             .5 9.122c0 3.61 2.55 5.827
             5.015 7.97.283.246.569.494.853.747l1.027.918a44.998
             44.998 0 0 0 3.518 3.018 2
             2 0 0 0 2.174 0 45.263
             45.263 0 0 0
             3.626-3.115l.922-.824c.293-.26.59-.519.885-.774
             2.334-2.025 4.98-4.32
             4.98-7.94a6.985 6.985 0 0
             0-6.708-7.218Z"
              ></path>
            </svg>
          </button>
          <span class="post-likes">{{ postDetail.likes }} 次赞</span>
          <span class="post-time">{{ postDetail.post_time }}</span>
        </div>

        <div class="post-footer_right">
          <!-- 帖子收藏 -->
          <button @click="toggleBookmarkPost" class="post-bookmark-button">
            <svg
                v-if="postDetail.is_favorited_by_me"
                aria-label="移除"
                class="x1lliihq x1n2onr6 x5n08af"
                fill="none"
                height="24"
                role="img"
                viewBox="0 0 24 24"
                width="24"
            >
              <title>移除</title>
              <path
                  d="M20 22a.999.999 0 0
             1-.687-.273L12 14.815l-7.313
             6.912A1 1 0 0 1 3
             21V3a1 1 0 0 1 1-1h16a1 1 0 0
             1 1 1v18a1 1 0 0 1-1 1Z"
              ></path>
            </svg>
            <svg
                v-else
                t="1731033614322"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="12630"
                width="24"
                height="24"
            >
              <path
                  d="M800
             128v712.8L566.3 661.6l-3.9-3-4.4-2.4c-14.1-7.7-30-11.7-46-11.7s-31.9
             4.1-46 11.7l-4.4 2.4-3.9
             3L224 840.8V128h576m32-64H192c-17.7
             0-32 14.3-32 32v810.1c0 18.6 15.3
             32 32 32 5.1 0 10.3-1.2
             15.3-4l289.4-221.8c4.8-2.6
             10-3.9 15.3-3.9 5.3 0 10.5
             1.3 15.3 3.9l289.4
             221.8c5 2.7 10.2 4 15.3
             4 16.7 0 32-13.4
             32-32V96c0-17.7-14.3-32-32-32z"
                  fill=""
                  p-id="12631"
              ></path>
            </svg>
          </button>

        </div>

      </div>
      <div class="reply_group_inner">
        <div class="comment-input-container">
          <!-- 添加表情包按钮 -->
          <div class="emoji-button" @click="toggleEmojiPicker(post)">
            <!-- 表情图标 -->
            <svg
                t="1729750205868"
                class="icon icon_bq"
                viewBox="0 0 1040 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="20459"
                width="24"
                height="24"
            >
              <path
                  d="M512.075261
                 1024A511.774217 511.774217 0 1 1 730.482434
                 48.769072a37.630457 37.630457 0 1 1-32.061149
                 68.035867 436.513303 436.513303 0 1 0
                 250.468323 395.270322 37.630457 37.630457 0 0 1
                 75.260914 0 512.526826 512.526826 0 0
                 1-512.075261 511.924739z"
                  fill=""
                  p-id="20460"
              ></path>
              <path
                  d="M333.857416
                 344.0929a57.348817 57.348817 0 1 0 57.348817
                 57.348817 57.499339 57.499339 0 0
                 0-57.348817-57.348817zM686.53006
                 344.0929a57.348817 57.348817 0 1 0 57.348817
                 57.348817 57.348817 57.348817 0 0
                 0-57.348817-57.348817zM515.236219
                 783.165074c-162.864619
                 0-262.359547-141.942084-262.359547-219.159782a30.104366
                 30.104366 0 0 1 60.208731 0c0
                 48.618551 76.314567 158.951051
                 202.150816 158.951051s193.571072-134.114949
                 193.571072-158.951051a30.104366 30.104366 0 0
                 1 60.208731 0c0 54.488902-90.012054
                 219.159782-253.779803
                 219.159782zM1009.549904
                 207.720123h-67.132735V139.985301a30.104366
                 30.104366 0 1 0-60.208732
                 0v67.734822h-67.734822a30.104366 30.104366
                 0 0 0-30.104366 30.104366 30.104366
                 30.104366 0 0 0 30.104366
                 30.104366h67.734822v67.734823a30.104366
                 30.104366 0 0 0 60.208732
                 0v-67.734823h67.734823a30.104366 30.104366
                 0 0 0 30.104365-30.104366 30.104366
                 30.104366 0 0 0-30.706453-30.104366z"
                  fill=""
                  p-id="20461"
              ></path>
            </svg>
          </div>
          <div v-if="showEmojiPicker" class="emoji-picker_content">
            <emoji-picker @emoji-click="addEmojiToComment($event, postDetail)" class="emoji-picker"></emoji-picker>
          </div>
          <el-input
              v-model="newComment"
              placeholder="说点什么..."
              class="comment-input"
              ref="mainCommentInput"
          ></el-input>
        </div>
<!--        <div v-if="mentionMode && filteredUsers.length > 0" class="mention-dropdown">-->
<!--          <div-->
<!--              v-for="user in filteredUsers"-->
<!--              :key="user.id"-->
<!--              @click="selectMentionInComment(user.username, post)"-->
<!--              class="mention-item"-->
<!--          >-->
<!--            <el-avatar :src="user.avatar"/>-->
<!--            <span>{{ user.username }}</span>-->
<!--          </div>-->
<!--        </div>-->
        <el-button type="primary" @click="addComment" class="comment-submit">发布</el-button>
      </div>
    </div>
  </div>
</template>


<script>
/* eslint-disable */
import 'emoji-picker-element';
import ActionModal from "@/components/ActionModal.vue";
import {ElAvatar, ElButton, ElInput, ElMessage} from 'element-plus';
import {mapActions} from "pinia";
import {PostDetailStore} from "@/stores/PostDetailStore";
import {
  commentLikeRequest, commentReplyRequest,
  commentRequest,
  getCommentsRequest,
  getPostDetailRequest,
  postFavoriteRequest,
  postLikeRequest
} from "@/api/api";

export default {
  name: 'InstagramComment',
  components: {ActionModal, ElAvatar, ElButton, ElInput},
  props: {
    postId: {
      type: Number,
      required: true,
    },
  },
  watch: {

  },

  data() {
    return {
      post_id:this.postId,
      loadedComments: 0, // 当前已加载的评论数量
      commentsPerLoad: 20, // 每次加载的评论数量
      totalComments: 0,  // 总评论数量
      isLoading: false,  // 是否正在加载评论
      hasMoreComments: false, // 是否还有更多评论
      isModalVisible: false,
      comments: [], // 评论列表
      newComment: '', // 原帖子的回复内容
      animateLike: false, // 动画状态
      mentionMode: false,
      selectedUser: null,
      allUsers: [

      ],
      filteredUsers: [],
      postDetail:null,
      order_type:0,//排序方式：0-按热度，1-按版本
      showEmojiPicker:false,
    };
  },
  mounted() {
    console.log(this.postId);
    this.loadData()
    this.loadComments()
  },
  methods: {
    loadData() {
      getPostDetailRequest({id: this.post_id}).then(res => {
        this.postDetail = res.data;
        //计算post发布时间，换算成X小时、x天前
        let post_time = new Date(this.postDetail.created_at);
        let now_time = new Date();
        let time = now_time.getTime() - post_time.getTime();
        let hours = time / 1000 / 60 / 60;
        if (hours < 24) {
          this.postDetail.post_time =  Math.floor(hours) + "小时前";
        } else {
          this.postDetail.post_time = Math.floor(hours / 24) + "天前";
        }
        console.log(res);
      });
    },
    //切换排序类型
    change_order_type(type){
      this.comments=[];
      this.order_type=type;
      this.loadComments()
    },
    //加载评论
    loadComments() {
      // if (this.isLoading || !this.hasMoreComments) return; // 防止重复加载
      // this.isLoading = true;
      getCommentsRequest(this.postId, {type:this.order_type})
          .then(response => {
            console.log(response)
            this.comments.push(...response.data.data); // 添加新的评论
            // const newComments = response.data.comments;
            // this.comments.push(...newComments); // 添加新的评论
            // this.loadedComments += newComments.length;
            // this.totalComments = response.data.totalComments; // 获取评论的总数
            //
            // // 如果评论数量大于20，则显示“加载更多”按钮
            // if (this.totalComments > 20) {
            //   this.hasMoreComments = true;
            // } else {
            //   this.hasMoreComments = false;
            // }
            //
            // // 如果返回的评论少于每次加载的数量，说明没有更多评论
            // if (newComments.length < this.commentsPerLoad) {
            //   this.hasMoreComments = false;
            // }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },

    loadReplies(commentId) {
      const comment = this.comments.find(c => c.id === commentId);
      if (!comment || comment.loadedReplies >= comment.repliesCount || this.isLoading) return;

      this.isLoading = true;
      // 假设你有一个 API 请求来获取评论的回复
      api.fetchReplies(commentId, comment.loadedReplies, comment.repliesPerLoad)
          .then(response => {
            const newReplies = response.data.replies;
            comment.replies.push(...newReplies); // 添加新的回复
            comment.loadedReplies += newReplies.length;

            // 判断是否显示“加载更多”按钮
            if (comment.repliesCount > 3 && comment.loadedReplies < comment.repliesCount) {
              comment.hasMoreReplies = true;
            } else {
              comment.hasMoreReplies = false;
            }
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    // 显示模态框
    showModal() {
      this.isModalVisible = true;
    },

    // 处理用户确认的操作
    handleAction(action) {
      console.log('用户确认的操作:', action);
      if (action === 'deleteNode') {
        // 执行删除节点的操作
        console.log('执行删除节点操作');
      } else if (action === 'deletePlan') {
        // 执行删除整个计划的操作
        console.log('执行删除整个计划操作');
      } else if (action === 'editNode') {
        // 执行编辑节点的操作
        console.log('执行编辑节点操作');
      }
    },
    //点赞帖子
    toggleLikePost() {
      let type=1;
      if(this.postDetail.is_liked_by_me){
        //已经点赞提交取消
        type =0;
      }else{
        type =1;
      }
      this.isLikedAnimated = true;
      postLikeRequest(this.postDetail.id,{type:type}).then(response => {
        if(response.code===200){
          this.postDetail.is_liked_by_me = !this.postDetail.is_liked_by_me;
          this.isLikedAnimated = false;
          this.postDetail.likes= response.data.likes;
          ElMessage({
            message: type===1 ? '已点赞' : '取消点赞',
            type: type===1 ? 'success' : 'info',
          });
        }
      });

    },

    resetLikeAnimation() {
      // 动画完成后重置状态
      this.animateLike = false;
    },
    //收藏帖子
    toggleBookmarkPost() {
      let type=1;
      if(this.postDetail.is_favorited_by_me){
        //已经点赞提交取消
        type =0;
      }else{
        type =1;
      }
      postFavoriteRequest( this.postDetail.id,{type:type}).then(response => {
        if(response.code===200){
          this.postDetail.is_favorited_by_me = !this.postDetail.is_favorited_by_me;
          this.postDetail.favorites= response.data.favorites;
          ElMessage({
            message: type===1 ? '已收藏' : '已取消收藏',
            type: type===1 ? 'success' : 'info',
          });
        }
      });
    },

    //添加评论
    addComment() {
      if (this.newComment.trim()) {
        commentRequest(this.postId, {content: this.newComment}).then(response => {
          if(response.code===200) {
            ElMessage({
              message: '评论成功',
              type: 'success',
            });
            this.comments.push(response.data);
            // this.loadComments()
          }
        });
        this.newComment = '';

      }else{
        ElMessage({
          message: '请输入评论内容',
          type: 'warning',
        });
      }
    },
    //点赞评论
    toggleLikeComment(index,commentId,isLike) {
      let type=1;
      if (isLike) {
        type =0;
      }else{
        type =1;
      }
      commentLikeRequest(commentId,{type:type}).then(response => {
        if(response.code===200){
            this.comments[index]=response.data;
        }
      });

    },
    //点赞评论的评论
    toggleLikeReply(comment_index,reply_index,commentId,isLike) {
      let type=1;
      if (isLike) {
        type =0;
      }else{
        type =1;
      }
      commentLikeRequest(commentId,{type:type}).then(response => {
        if(response.code===200){
          this.comments[comment_index].replies[reply_index]=response.data;
        }
      });
    },

    // 设定回复目标并打开回复框
    setReplyTarget(comment, replyToUsername = null) {
      // 更新目标用户名
      comment.replyToUsername = replyToUsername;
      // 显示回复输入框
      comment.showReplyInput = true;

      // 聚焦到回复输入框
      this.$nextTick(() => {
        const inputRef = this.$refs['commentInput' + comment.id];
        if (inputRef && inputRef.focus) {
          inputRef.focus();
        }
      });
    },

    // 切换回复输入框显示状态
    toggleReplyInput(comment, replyToUsername = null) {
      // 如果是相同的回复对象，直接隐藏
      if (comment.showReplyInput && comment.replyToUsername === replyToUsername) {
        comment.showReplyInput = false;
        comment.replyToUsername = null;
      } else {
        // 否则设定新的回复目标
        this.setReplyTarget(comment, replyToUsername);
      }

      // 使用 nextTick 确保 DOM 更新完成后再执行
      this.$nextTick(() => {
        const target = document.querySelector('#reply_input');

        if (target) {
          // 滚动到目标元素
          target.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
          });
          // 滚动完成后再做偏移，确保目标元素不会被遮挡
          window.scrollBy(0, -120);  // 向上滚动50px，根据需要调整偏移量
          // 聚焦到目标元素
          target.focus();
        } else {
          console.error('目标元素没有找到');
        }
      });
    },
    // 添加回复
    addReply(comment) {
      if (comment.newReply.trim()) {
        commentReplyRequest(comment.id, {content: comment.newReply}).then(response => {
          if(response.code===200) {
            ElMessage({
              message: '回复成功',
              type: 'success',
            });
            comment.replies.push(response.data);
            // 重置回复输入框
            comment.newReply = '';
            comment.showReplyInput = false;
            comment.replyToUsername = null;
            comment.showEmojiPicker = false;
          }
        });

      }
    },
    canDeleteComment(commentAuthor) {
      // 如果当前用户是帖子作者，或者是评论/回复的作者，返回true
      return this.currentUser === this.postDetail.username || this.currentUser === commentAuthor;
    },
    deleteComment(commentId) {
      // 在删除前检查权限
      const comment = this.findCommentById(this.comments, commentId);
      if (comment && this.canDeleteComment(comment.username)) {
        this.comments = this.comments.filter((c) => c.id !== commentId);
      } else {
        // 无权限删除
        alert('您没有权限删除此评论');
      }
    },
    deleteReply(commentId, replyId) {
      const parentComment = this.findCommentById(this.comments, commentId);
      if (parentComment) {
        const reply = this.findReplyById([parentComment], replyId);
        if (reply && this.canDeleteComment(reply.username)) {
          parentComment.replies = parentComment.replies.filter((r) => r.id !== replyId);
        } else {
          // 无权限删除
          alert('您没有权限删除此回复');
        }
      }
    },
    findCommentById(comments, id) {
      return comments.find((comment) => comment.id === id);
    },
    findReplyById(comments, id) {
      for (let comment of comments) {
        for (let reply of comment.replies) {
          if (reply.id === id) return reply;
        }
      }
      return null;
    },
    toggleEmojiPicker(target) {
      this.showEmojiPicker = !this.showEmojiPicker;
    },
    addEmojiToComment(event, target) {
      const emoji = event.detail.unicode;
      this.newComment += emoji;
      // if (target === this.post) {
      //   this.newComment += emoji;
      // } else {
      //   target.newReply += emoji;
      // }
      this.showEmojiPicker = false;
    },
    checkMention(target) {
      let content = '';
      if (target === this.post) {
        content = this.newComment;
      } else {
        content = target.newReply;
      }
      const mentionStartIndex = content.lastIndexOf('@');
      if (mentionStartIndex !== -1) {
        const query = content.substring(mentionStartIndex + 1);
        this.mentionMode = true;
        this.filterUsers(query);
      } else {
        this.mentionMode = false;
      }
    },
    filterUsers(query) {
      if (!query) {
        this.filteredUsers = this.allUsers;
      } else {
        this.filteredUsers = this.allUsers.filter((user) =>
            user.username.toLowerCase().includes(query.toLowerCase())
        );
      }
    },
    selectMentionInComment(username, target) {
      let content = '';
      if (target === this.post) {
        content = this.newComment;
      } else {
        content = target.newReply;
      }
      const mentionIndex = content.lastIndexOf('@');
      if (mentionIndex !== -1) {
        if (target === this.post) {
          this.newComment = content.slice(0, mentionIndex) + `@${username} `;
        } else {
          target.newReply = content.slice(0, mentionIndex) + `@${username} `;
        }
      } else {
        if (target === this.post) {
          this.newComment += `@${username} `;
        } else {
          target.newReply += `@${username} `;
        }
      }
      this.mentionMode = false;
      this.selectedUser = null;
    },
    highlightMentions(content) {
      return content.replace(/@[A-Za-z0-9._]+/g, (match) => {
        const username = match.substring(1);
        return `<a href="/user/${username}" target="_blank" style="color: #1e90ff; text-decoration: none;">${match}</a>`;
      });
    },
  },
};
</script>


<style scoped>
/* 回复输入框的样式 */
.reply-content_1 {
  width: calc(100% - 32px);
}

.comment-input-container {
  display: flex;
  align-items: center;
}

.comment-input {
  flex: 1;
}

/* 表情按钮的样式 */
.emoji-button {
  margin-right: 10px;
  cursor: pointer;
}

.emoji-picker_content {
  position: absolute;
  z-index: 20;
}

/* 提及下拉框的样式 */
.mention-dropdown {
  background: var(--background-color);
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  width: 300px;
}

.mention-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mention-item:hover {
  background: #444;
}

/* 按热度和按版本按钮的样式 */
.bx_btn_group {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.hot_load {
  border: 1px solid #ccc;
  background: var(--hover-li);
  height: 35px;
  width: 70px;
  line-height: 35px;
  text-align: center;
  border-radius: 7px;
  margin-right: 13px;
}

.version_load {
  border: 1px solid #ccc;
  background: var(--hover-li);
  height: 35px;
  width: 70px;
  line-height: 35px;
  text-align: center;
  border-radius: 7px;
}

.btn_active_style {
  color: #39f !important;
}

/* 按热度和按版本按钮的样式 */
.bx_btn_group {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.hot_load {
  border: 1px solid #ccc;
  background: var(--hover-li);
  height: 35px;
  width: 70px;
  line-height: 35px;
  text-align: center;
  border-radius: 7px;
  margin-right: 13px;
}

.version_load {
  border: 1px solid #ccc;
  background: var(--hover-li);
  height: 35px;
  width: 70px;
  line-height: 35px;
  text-align: center;
  border-radius: 7px;
}

.btn_active_style {
  color: #39f !important;
}

/* 回复输入框的样式 */
.reply-input-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
}

.comment-input-container {
  display: flex;
  align-items: center;
}

.comment-input {
  flex: 1;
}

/* 提及下拉框的样式 */
.mention-dropdown {
  background: var(--background-color);
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  width: 300px;
}

.mention-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mention-item:hover {
  background: #444;
}


/* 回复输入框的样式 */


.comment-input-container {
  display: flex;
  align-items: center;
}

.comment-input {
  flex: 1;
}

/* 提及下拉框的样式 */
.mention-dropdown {
  background: var(--background-color);
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  width: 300px;
}

.mention-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.mention-item:hover {
  background: #444;
}

/* 其他样式保持不变 */
/* ...（保留您原有的其他样式）... */
</style>


<style>
.action_modal_btn {
  position: absolute;
  top: 10px;
  right: 40px;
  z-index: 10;
}



.btn_active_style {
  color: #39f !important;
}

.version_load {
  border: 1px solid #ccc;
  background: var(--hover-li);
  height: 35px;
  width: 70px;
  line-height: 35px;
  text-align: center;
  border-radius: 7px;
}

.hot_load {
  border: 1px solid #ccc;
  background: var(--hover-li);
  height: 35px;
  width: 70px;
  line-height: 35px;
  text-align: center;
  border-radius: 7px;
  margin-right: 13px;
}

.bx_btn_group {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.dashed-line {
  display: flex;
  align-items: center;
  color: #777; /* 文本颜色 */
  font-size: 16px; /* 文本大小 */
  font-weight: bold;
}

.dashed-line::before,
.dashed-line::after {
  content: '';
  flex: 1;
  border-bottom: 1px dashed #777; /* 虚线样式 */
  margin: 0 10px; /* 虚线和文字的间距 */
}

/* 保留原有的样式，或根据需要进行调整 */
.post-container {
  /* ... */
}

.comment-input-container {
  display: flex;
  align-items: center;
  width: calc(100% - 70px);
}

.emoji-button {
  margin-right: 10px;
  cursor: pointer;
}

.like-animation {
  animation: heart-beat 0.6s ease-in-out;
}

@keyframes heart-beat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>

<style scoped>
.reply-content_1_up {
  display: flex;
  flex-direction: row;
  font-size: 12px;
  margin-bottom: 5px;
}

.btn_active_style {
  color: #39f !important;
}

.icon_bq {
  fill: var(--text-color) !important;
}

.reply_group {
  position: absolute;
  width: 100%;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 120px;
  background: var(--background-color);
  border-top: 1px solid #000000;
  z-index: 10;
}
@media (max-width: 768px) {
  .reply_group {
    bottom: 50px !important;
    position: fixed;
    left: 0px;
  }
  .post-container{
    height: auto !important;
  }
  .comments-container {
    margin-bottom: 210px !important;
    min-height: 300px;
    height: auto !important;
  }
}

.reply_group_inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-right: 40px;
}

svg.comment-like-icon.pl_icon {
  fill: #ff0000 !important;
}

svg.comment-like-icon.pl_icon_2 {
  fill: var(--text-color) !important;
}

.comment-header {
  margin-bottom: 10px;
}

emoji-picker.emoji-picker {
  position: absolute;
  top: -435px;
}

.emoji-picker_content {
  position: relative;
}

.comment-like-button {
  background: none;
  border: none;
  color: var(--text-color) !important;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.reply-like-button {
  background: none;
  border: none;
  color: var(--text-color) !important;
  font-size: 12px;
  display: flex;
  align-items: self-start;
  justify-content: center;
  gap: 5px;
}

.post-like-button {
  background: none;
  border: none;
}

.post-bookmark-button {
  background: none;
  border: none;
}

.post-bookmark-button svg {
  fill: var(--text-color) !important;
}

.comment-actions.el-button > span {
  color: var(--reply-color) !important;
}
.comment-actions_left {
  font-size: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 15px;
  color: var(--reply-color) !important;
}
.comment-actions .el-button {
  font-size: 12px;
  color: var(--reply-color) !important;
}
.reply-actions_left {
  margin-left: 42px;
  font-size: 12px;
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--reply-color) !important;
}
.reply-actions_left span {
  font-size: 12px;
  margin-top: 2px;
  color: var(--reply-color) !important;
}
.post-container {
  width: 100%;
  margin: 0 auto;
  padding: 20px 20px 0px 20px;
  display: flex;
  border-radius: 10px;
  height: 100vh;
  flex-direction: column;
  position: relative;
  font-size: 14px;
}

.post-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-direction: column;
}

.post_header_1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px 0px;
  position: relative;
  padding: 10px 0px 10px 0px;
  justify-content: space-between;
}

.post_header_1_inner {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 1px solid #383838;
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.post_header_1_left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.post_header_mid {
  padding-top: 10px;
  flex-grow: 1;
  overflow-y: scroll;
  border-top: 1px solid #383838;
}

.post-avatar {
  margin-right: 10px;
}

.post-details {
  width: 100%;
  flex: 1;
}

.post-username a {
  font-weight: bold;
  color: var(--text-color) !important;
  margin-right: 5px;
}

.post-content {
  margin-top: 5px;
  color: var(--text-color);
}

.post-hashtags .hashtag, .post-tags .tag {
  color: #1e90ff;
  cursor: pointer;
}

.post-hashtags, .post-tags {
  margin-top: 5px;
}

.post-timestamp {
  font-size: 0.9em;
  color: #888;
  margin-top: 5px;
}

.post-footer {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
  position: relative;
  justify-content: space-between;
  width: 100%;
  padding-right: 28px;
  margin-left: -8px;
}

@media (max-width: 768px) {

}

.post-footer_left {
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  font-size: 13px;
}

.comments-container {
  width: 100%;
  background: var(--background-color);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 150px;
  overflow-y: scroll;

}

.com_title {
  font-size: 14px;
  margin-bottom: 15px;
  width: 100%;
  text-align: center;
}

.comment-item {
  display: flex;
  margin-bottom: 15px;
  padding-bottom: 10px;
}

.comment-content {
  margin-left: 10px;
  flex: 1;
}

.comment-header {
  display: flex;
  justify-content: space-between;
}

.username a {
  font-weight: 500;
  font-size: 13px;
  color: var(--text-color) !important;
}

.comment-actions {
  display: flex;
  gap: 10px;
  margin: 5px 0;
  color: #888;
  justify-content: space-between;
}

.replies {
  margin-left: 0px;
  margin-top: 10px;
}

.comment-item .el-avatar.el-avatar--circle {
  width: 32px;
  height: 32px;
}

.reply-item {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  width: 100%;
}

.reply-content {
  margin-left: 0px;
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  width: 100%;
  word-break: break-all;
}

.reply-content-ddddd {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.reply-actions {
  display: flex;
  gap: 10px;
  margin-left: 0px;
  color: #888;
}

.reply-delete {
  color: #ff4d4f;
}

.reply-box {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.reply-input {
  flex: 1;
}

.emoji-picker {
  margin: 10px 0;
}

.comment-input {
  width: calc(100% - 50px);
}

.comment-submit {
  margin-top: 0px;
}

/* CSS for like animation */
.like-animation {
  animation: heart-beat 0.6s ease-in-out;
}

@keyframes heart-beat {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

.mention-dropdown {
  background: var(--background-color);
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-top: 5px;
  position: absolute;
  z-index: 10;
  max-height: 200px;
  overflow-y: auto;
  width: 300px;
  min-width: 300px;
  top: -209px;
}

.mention-item {
  padding: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.comment-body {
  width: 100%;
  display: flex;
  word-break: break-all;
}

.mention-item:hover {
  background: #444;
}

.user-option {
  display: flex;
  align-items: center;
}

.user-option el-avatar {
  margin-right: 10px;
}
</style>
