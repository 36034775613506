<template>
  <div class="new_plan">
    <el-dialog
        class="theme_bg"
        v-model="isVisible"
        :width="dialogWidth"
        @close="hidePlanMenuModal"
        @open="getUsersPlan"
        title="选择作品发布方式"
    >
      <div class="step_1_content">
        <!-- 计划列表展示 -->
        <div class="new_plan_two" :style="newPlanTwoStyle">
          <div class="newplan_group" :style="newPlanGroupStyle" @click="createPlan(true)">
            <svg t="1730981709079" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="8572" width="32" height="32">
              <path
                  d="M469.333334 213.333334 469.333334 469.333334 213.333334 469.333334 213.333334 554.666666 469.333334 554.666666 469.333334 810.666664 554.666666 810.666664 554.666666 554.666666 810.666664 554.666666 810.666664 469.333334 554.666666 469.333334 554.666666 213.333334 469.333334 213.333334Z"
                  fill="#0785f7" p-id="8573"></path>
            </svg>
            创建独立作品
          </div>
          <div class="newplan_group" :style="newPlanGroupStyle" @click="createPlan(false)">
            <svg t="1730981709079" class="icon" viewBox="0 0 1024 1024" version="1.1"
                 xmlns="http://www.w3.org/2000/svg" p-id="8572" width="32" height="32">
              <path
                  d="M469.333334 213.333334 469.333334 469.333334 213.333334 469.333334 213.333334 554.666666 469.333334 554.666666 469.333334 810.666664 554.666666 810.666664 554.666666 554.666666 810.666664 554.666666 810.666664 469.333334 554.666666 469.333334 554.666666 213.333334 469.333334 213.333334Z"
                  fill="#0785f7" p-id="8573"></path>
            </svg>
            创建新计划
          </div>
        </div>

        <div class="plan-list" v-if="paginatedPlans.length > 0">
          <el-carousel class="plan_lb" :interval="0" height="" arrow="always">
            <el-carousel-item v-for="(planGroup, index) in paginatedPlans" :key="index">
              <div class="plan-item-group">
                <div v-for="plan in planGroup" :key="plan.id" class="plan-item" @click="addPlanNode(plan)">
                  <div v-if="plan.isModified" class="modified-label">
                    <!-- SVG 图标 -->
                  </div>
                  <img :src="plan.cover?'https://bbs-oss.realtime.xyz/'+ plan.cover: defaultCover" alt="封面" class="plan-cover"/>
                  <div class="plan-info">
                    <div class="pl_name">{{ plan.title }}</div>
                    <div class="pl_intrs" v-if="plan.status === 0">新创建</div>
                    <div class="pl_intrs" v-if="plan.status === 1">更新中</div>
                    <div class="pl_intrs" v-if="plan.status === 2">已完结</div>
                    <div class="pl_intrs" v-if="plan.is_independent===0">进度：{{ plan.progress }}%</div>
                  </div>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import defaultCover from '@/assets/default-cover.jpg';
import { getUsersPlanRequest } from "@/api/api";
import { modalStore } from "@/stores/modalStore";
import { mapActions, mapState } from 'pinia';
import {ElMessage} from "element-plus";

export default {
  name: 'NewPlanMenuModal',
  props: {},
  data() {
    return {
      plans: [],
      defaultCover: defaultCover,
    };
  },
  computed: {
    ...mapState(modalStore, ['isShowPlanMenu','selectPlan']),
    isVisible() {
      return this.isShowPlanMenu;
    },
    paginatedPlans() {
      const groupedPlans = [];
      for (let i = 0; i < this.plans.length; i += 4) {
        groupedPlans.push(this.plans.slice(i, i + 4));
      }
      return groupedPlans;
    },
    newPlanTwoStyle() {
      return this.plans.length > 0 ? {} : { width: '100%' };
    },
    dialogWidth() {
      return this.plans.length > 0 ? '90%' : '400px';
    },
    newPlanGroupStyle() {
      return this.plans.length > 0 ? { } : {width: '180px'};
    }
  },
  methods: {
    ...mapActions(modalStore, ['hidePlanMenuModal','showCreateModal','showPlanModal','setSelectPlan']),
    getWidthValue() {
      return window.innerWidth < 768 ? '360px' : '400px';
    },
    handleResize() {
      this.widthValue = this.getWidthValue();
    },
    getUsersPlan() {
      getUsersPlanRequest({}).then(res => {
        this.plans = res;
      })
    },
    addPlanNode(planInfo) {
      if(planInfo.is_independent===1&&planInfo.nodes.length>0){
        ElMessage({
          message: '该独立作品已经上传过作品。如需编辑请从详情中点击编辑',
          type: 'warning'
        });
      }else{
        this.setSelectPlan(planInfo);
        this.showCreateModal();
        this.hidePlanMenuModal();
      }

    },
    createPlan(isIndependent) {
      this.showPlanModal(isIndependent);
      this.hidePlanMenuModal();
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>


<style>
.step_1_content .el-carousel__container{
  min-height: 300px;
}
@media (min-width: 1000px) {
  .step_1_content{
    height: 375px !important;
  }
}
@media (max-width: 600px) {
  .step_1_content .el-carousel__container{
    height: 200px !important;
  }
  .plan-item{
    font-size: 10px;
  }
  .new_plan_two{
    font-size: 10px;
  }
}
@media (min-width: 600px) and (max-width: 768px) {
  .step_1_content .el-carousel__container{
    height: 250px !important;
  }
}
@media (min-width: 769px) and (max-width: 1000px) {
  .step_1_content .el-carousel__container{
    height: 400px !important;
  }
}
.step_1_content{
  height: auto;
}
.plan-list .el-carousel__indicators--horizontal {
  bottom: 0px;
  }
.el-textarea__inner {
  background-color: var(--background-color) !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */
{
  opacity: 0;
}

/* 更改日期选择器面板的背景颜色 */
.el-picker-panel {
  background-color: #2c2c2c; /* 将 #f0f0f0 替换为你想要的背景颜色 */
}

/* 如果你想更改日期选择器中日期的背景颜色 */
.el-date-table td.available {
  background-color: var(--background-color); /* 将 #ffffff 替换为你想要的背景颜色 */
}

.el-date-table td.current {
  background-color: #cce4ff; /* 将 #cce4ff 替换为当前日期的背景颜色 */
}

::v-deep .new_plan .el-picker-panel__body-wrapper {
  background: var(--background-color) !important;
  border: 1px solid #666666;
}

.new_plan_two {
  display: flex;
  flex-direction: row;
  width: 33.33%;
}
@media (max-width: 768px) {
  .new_plan_two {
    width: 100%;
  }
}
@media (max-width: 600px) {
  .new_plan_two {
    width: 100%;
  }
  .plan-item {
    width: calc(50% - 10px) !important;
  }
}
.step_1_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  margin-top: 50px;
}

@media (max-width: 1000px) {
  .step_1_content{
    flex-direction: column;
    align-items: center;
  }
  .new_plan_two{
    width: 50%;
  }
  .plan-item{
    height: auto !important;
  }
  .plan-list{
    width: 100% !important;
  }

}


.new_plan .el-dialog__close {
  background: #666666 !important;
  color: #fff !important;
  top: -7px;
  position: absolute;
  right: -13px;
  width: 30px !important;
  height: 30px !important;
  border-radius: 50%;
}

.new_plan .el-dialog__headerbtn svg {
  fill: var(--text-color) !important;
}

.new_plan .el-picker-panel__body-wrapper {
  background: var(--background-color) !important;
}

.el-picker-panel__icon-btn .el-icon {
  color: var(--text-color) !important;
}

.plan_lb {
  position: relative;
}


.new_plan .el-carousel__item {
  left: 0px;
}

.new_plan .el-overlay-dialog {
  background: rgba(0, 0, 0, 0.6);
}

.new_plan .el-carousel__arrow--right {
  right: 0px !important;
}

.new_plan .el-carousel__arrow--left {
  left: 0px !important;
}

.new_plan .el-dialog {
  background: var(--background-color) !important;
  box-shadow: 0 0 20px 5px rgba(255, 255, 255, 0.2) !important;
  color: var(--text-color);
  max-width: 1200px;
}

@media (max-width: 800px) {
  .new_plan .el-dialog {
    width: 100% !important;
  }
}
</style>
<style scoped>
.plan_time {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.plan_time_left {
  width: 50%;
}

.plan_time_right {
  width: 50%;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 1000px) {
  .plan_time {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .plan_time_left {
    width: 100%;
  }

  .plan_time_right {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}

.pl_intrs {
  height: 25px;
  line-height: 25px;
  margin: 0px;
}

.pl_name {
  height: 25px;
  line-height: 25px;
  margin: 0px;
}

.plan-list .el-carousel.el-carousel--horizontal {
  width: 100%;
}

.plan-list {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 66.666%;
}


.modified-label {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  height: 32px;
  width: 32px;
  display: flex;
}

.plan-item-group {
  display: flex;
  justify-content: flex-start;
  padding-left: 0px;
  margin-top: 0px;
}

.plan-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 6px 1%;
  width: 24%;
  border: 1px solid #ccc;
  aspect-ratio: 1 / 1.5;
}

.newplan_group {
  width: 48%;
  aspect-ratio: 1 / 1.57;
  border: 1px dashed #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 6px 2%;
  z-index: 10;
}



.plan-cover {
  width: 100%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  border: 1px solid #dcdcdc;
  aspect-ratio: 1 / 1;
}

.plan-info {
  text-align: center;
  margin-top: 10px;
}

.actions {
  text-align: center;
  margin-top: 10px;
}
</style>
