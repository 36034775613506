import axios from 'axios';
import router from "@/router";
import {ElMessage} from "element-plus";
// 创建 axios 实例，域名可配置
const apiClient = axios.create({
    baseURL: 'https://bbs-api.realtime.art/api', // 使用环境变量配置域名
    // baseURL: 'http://bbs.com/api', // 使用环境变量配置域名
    timeout: 100000, // 请求超时时间
});

// 请求拦截器：在每次请求之前将缓存中的 token 添加到请求头
apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token'); // 从缓存中获取 token
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`; // 将 token 添加到请求头
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// 响应拦截器：直接返回数据部分
apiClient.interceptors.response.use(
    (response) => {
        // console.log(response)
        if(response.status===401){
            ElMessage({
                message: '您的邮箱号码还未验证，请先验证邮箱！',
                type: 'error',
            });
            return router.replace('/EmailVerification');
        }else{
            return response.data; // 只返回响应数据部分
        }

    },
    (error) => {
        if(error.status===403){
            // localStorage.removeItem('token')
            ElMessage({
                message: '您的邮箱号码还未验证，请先验证邮箱！',
                type: 'error',
            });
            return router.replace('/EmailVerification');
        }
        if(error.status===401){
            ElMessage({
                message: '您还未登录，请先登录！',
                type: 'error',
            });
            return router.replace('/LoginPage');
        }
        return Promise.reject(error);
    }
);

// 封装 get 请求
export const getRequest = (url, params = {}) => {
    return apiClient.get(url, { params });
};

// 封装 post 请求
export const postRequest = (url, data = {}) => {
    return apiClient.post(url, data);
};

// 封装 post 请求
export const changePwdRequest = (data) => {
    return apiClient.post('/user/change_pwd', data);
};

// 使用示例
// import { getRequest, postRequest } from './api';
// getRequest('/example', { param1: 'value1' }).then(response => console.log(response));
// postRequest('/example', { key: 'value' }).then(response => console.log(response));
// 封装 login 请求
export const loginRequest = (data) => {
    return apiClient.post('/auth/login', data);
};


export const reSendEmailRequest = (data) => {
    return apiClient.post('/send_code', data);
};
export const verifyCodeRequest = (data) => {
    return apiClient.post('/verify_code', data);
};
export const sendResetCodeRequest = (data) => {
    return apiClient.post('/auth/send_reset_code', data);
};

export const resetPwdRequest = (data) => {
    return apiClient.post('/auth/reset-password', data);
};


export const logoutRequest = (data) => {
    return apiClient.post('/auth/logout', data);
};
// 封装 register 请求
export const registerRequest = (data) => {
    return apiClient.post('/auth/register', data);
};

// 封装 password recovery 请求
export const recoverPasswordRequest = (data) => {
    return apiClient.post('/auth/recover-password', data);
};

// 封装 post blog 请求
export const postBlogRequest = (data) => {
    return apiClient.post('/blogs', data);
};

// 封装 like 请求
export const likeRequest = (blogId) => {
    return apiClient.post(`/blogs/${blogId}/like`);
};

// 封装 comment 请求
export const commentRequest = (blogId, data) => {
    return apiClient.post(`/post/${blogId}/comment`, data);
};

// 封装 comment 请求
export const getCommentsRequest = (blogId, data) => {
    return apiClient.post(`/post_comments/${blogId}`, data);
};

// 封装 bookmark 请求
export const bookmarkRequest = (blogId) => {
    return apiClient.post(`/blogs/${blogId}/bookmark`);
};

// 封装 点赞帖子 请求
export const postLikeRequest = (blogId,data) => {
    return apiClient.post(`/post/${blogId}/like`,data);
};

// 封装 点赞评论 请求
export const commentLikeRequest = (blogId,data) => {
    return apiClient.post(`/comment/${blogId}/like`,data);
};

// 封装 回复评论 请求
export const commentReplyRequest = (blogId,data) => {
    return apiClient.post(`/comment/${blogId}/reply`,data);
};
// 封装 收藏帖子 请求
export const postFavoriteRequest = (blogId,data) => {
    return apiClient.post(`/post/${blogId}/favorite`,data);
};
// 封装 关注用户操作请求
export const userFollowRequest = (user_id,data) => {
    return apiClient.post(`/follow/${user_id}`,data);
};

// 获取通知列表
export const noticeRequest = (data) => {
    return apiClient.get(`/notifications`,data);
};

export const createPlanRequest=(data)=>{
    return apiClient.post('/plans',data)
}

export const updatePlanRequest=(data)=>{
    return apiClient.post('/plans_update',data)
}

export const uploadAvatarRequest=(data)=>{
    return apiClient.post('/upload_avatar',data)
}
export const updateProfileequest=(data)=>{
    return apiClient.post('/update_profile',data)
}
export const updatePlanNodeRequest=(data)=>{
    return apiClient.post('/update_plan_node',data)
}
export const getUsersPlanRequest=(data)=>{
    return apiClient.get('/plans_list',data)
}
export const getPlanRequest=(data)=>{
    return apiClient.post('/user_plans_list',data)
}
export const getMyFavoriteRequest=(data)=>{
    return apiClient.post('/my_favorite',data)
}
export const getUserFavoriteRequest=(data)=>{
    return apiClient.post('/user_favorite',data)
}

export const userInfoRequest=(data)=>{
    return apiClient.post('/user_info',data)
}

export const getRecommUserRequest=(data)=>{
    return apiClient.get('/get_recomm_user',data)
}


export const getPostDetailRequest=(data)=>{
    return apiClient.post('/post_detail',data)
}
export const searchRequest=(data)=>{
    return apiClient.post('/search',data)
}
export const RecentConversationsRequest=(data)=>{
    return apiClient.get('messages/recent-conversations',data)
}

export const friendsRequest=(data)=>{
    return apiClient.post('friend_list',data)
}
export const messageHistoryRequest=(data)=>{
    return apiClient.post('messages/conversation',data)
}

export const messageSendRequest=(data)=>{
    return apiClient.post('messages/send',data)
}
export const exploreRequest=(data)=>{
    return apiClient.get('/explore',data)
}

export const getPostListRequest=(data)=>{
    return apiClient.get('/get_new_posts',data)
}
export default apiClient;
