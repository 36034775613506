// src/data/authors.js

export const authors = [
    {
        id: 1,
        name: '作者A',
        artworks: [
            {
                id: 'A1',
                title: '作品A1',
                images: [
                    'https://via.placeholder.com/600x400?text=作品A1-总览-1',
                    'https://via.placeholder.com/600x400?text=作品A1-总览-2'
                ],
                versions: [
                    {
                        id: 'A1-V1',
                        uploadPlan: {
                            startDate: '2024-01-01',
                            intervalDays: 7,
                            totalUploads: 3,
                            endDate: '2024-01-29'
                        },
                        uploads: [
                            {
                                date: '2024-01-01',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户X', comment: '很棒的作品！'},
                                    {user: '用户Y', comment: '喜欢这个风格。'}
                                ],
                                images: [
                                    require('@/assets/demo1.jpg'),
                                    require('@/assets/demo2.jpg'),
                                    require('@/assets/demo5.jpg'),

                                ]
                            },
                            {
                                date: '2024-01-08',
                                status: 'late',
                                feedbacks: [
                                    {user: '用户Z', comment: '期待更多！'}
                                ],
                                images: [
                                    require('@/assets/demo3.jpg'),
                                    require('@/assets/demo5.jpg'),
                                ]
                            },
                            {
                                date: '2024-01-15',
                                status: 'missed',
                                feedbacks: [],
                                images: []
                            },
                            {
                                date: '2024-01-22',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户X', comment: '很棒的作品！'},
                                    {user: '用户Y', comment: '喜欢这个风格。'}
                                ],
                                images: [
                                    require('@/assets/demo4.jpg'),
                                    require('@/assets/demo5.jpg'),
                                ]
                            },

                            {
                                date: '2024-01-29',
                                status: 'late',
                                feedbacks: [
                                    {user: '用户X', comment: '很棒的作品！'},
                                    {user: '用户Y', comment: '喜欢这个风格。'}
                                ],
                                images: [
                                    require('@/assets/demo4.jpg'),
                                    require('@/assets/demo5.jpg'),
                                ]
                            },

                        ]
                    }
                ]
            },
            {
                id: 'A2',
                title: '作品A2',
                images: [
                    'https://via.placeholder.com/600x400?text=作品A2-总览-1',
                    'https://via.placeholder.com/600x400?text=作品A2-总览-2'
                ],
                versions: [
                    {
                        id: 'A2-V1',
                        uploadPlan: {
                            startDate: '2024-03-01',
                            intervalDays: 10,
                            totalUploads: 3,
                            endDate: '2024-03-21'
                        },
                        uploads: [
                            {
                                date: '2024-03-01',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户Z', comment: '非常有创意！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品A2-V1-上传-1'
                                ]
                            },
                            {
                                date: '2024-03-11',
                                status: 'missed',
                                feedbacks: [],
                                images: []
                            },
                            {
                                date: '2024-03-21',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户W', comment: '完美的结尾！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品A2-V1-上传-2',
                                    'https://via.placeholder.com/600x400?text=作品A2-V1-上传-3'
                                ]
                            }
                        ]
                    },
                    {
                        id: 'A2-V2',
                        uploadPlan: {
                            startDate: '2024-04-01',
                            intervalDays: 7,
                            totalUploads: 3,
                            endDate: '2024-04-15'
                        },
                        uploads: [
                            {
                                date: '2024-04-01',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户Y', comment: '开始得很好！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品A2-V2-上传-1'
                                ]
                            },
                            {
                                date: '2024-04-08',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户X', comment: '进展顺利！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品A2-V2-上传-2'
                                ]
                            },
                            {
                                date: '2024-04-15',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户Z', comment: '期待更多！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品A2-V2-上传-3'
                                ]
                            }
                        ]
                    }
                ]
            },
        ]
    },
    {
        id: 2,
        name: '作者B',
        artworks: [
            {
                id: 'B1',
                title: '作品B1',
                images: [
                    'https://via.placeholder.com/600x400?text=作品B1-总览-1',
                    'https://via.placeholder.com/600x400?text=作品B1-总览-2'
                ],
                versions: [
                    {
                        id: 'B1-V1',
                        uploadPlan: {
                            startDate: '2024-01-05',
                            intervalDays: 6,
                            totalUploads: 3,
                            endDate: '2024-01-19'
                        },
                        uploads: [
                            {
                                date: '2024-01-05',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户A', comment: '精彩！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B1-V1-上传-1'
                                ]
                            },
                            {
                                date: '2024-01-11',
                                status: 'late',
                                feedbacks: [
                                    {user: '用户B', comment: '继续保持！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B1-V1-上传-2'
                                ]
                            },
                            {
                                date: '2024-01-17',
                                status: 'missed',
                                feedbacks: [],
                                images: []
                            }
                        ]
                    },
                    {
                        id: 'B1-V2',
                        uploadPlan: {
                            startDate: '2024-02-05',
                            intervalDays: 5,
                            totalUploads: 3,
                            endDate: '2024-02-15'
                        },
                        uploads: [
                            {
                                date: '2024-02-05',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户C', comment: '很有潜力！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B1-V2-上传-1'
                                ]
                            },
                            {
                                date: '2024-02-10',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户D', comment: '期待下一步！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B1-V2-上传-2'
                                ]
                            },
                            {
                                date: '2024-02-15',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户E', comment: '完成得很好！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B1-V2-上传-3'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                id: 'B2',
                title: '作品B2',
                images: [
                    'https://via.placeholder.com/600x400?text=作品B2-总览-1',
                    'https://via.placeholder.com/600x400?text=作品B2-总览-2'
                ],
                versions: [
                    {
                        id: 'B2-V1',
                        uploadPlan: {
                            startDate: '2024-03-05',
                            intervalDays: 8,
                            totalUploads: 3,
                            endDate: '2024-03-21'
                        },
                        uploads: [
                            {
                                date: '2024-03-05',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户F', comment: '非常喜欢！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B2-V1-上传-1'
                                ]
                            },
                            {
                                date: '2024-03-13',
                                status: 'late',
                                feedbacks: [],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B2-V1-上传-2'
                                ]
                            },
                            {
                                date: '2024-03-21',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户G', comment: '完美！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B2-V1-上传-3'
                                ]
                            }
                        ]
                    },
                    {
                        id: 'B2-V2',
                        uploadPlan: {
                            startDate: '2024-04-05',
                            intervalDays: 7,
                            totalUploads: 3,
                            endDate: '2024-04-19'
                        },
                        uploads: [
                            {
                                date: '2024-04-05',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户H', comment: '很棒！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B2-V2-上传-1'
                                ]
                            },
                            {
                                date: '2024-04-12',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户I', comment: '继续加油！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B2-V2-上传-2'
                                ]
                            },
                            {
                                date: '2024-04-19',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户J', comment: '完成得很好！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品B2-V2-上传-3'
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    },
    {
        id: 3,
        name: '作者C',
        artworks: [
            {
                id: 'C1',
                title: '作品C1',
                images: [
                    'https://via.placeholder.com/600x400?text=作品C1-总览-1',
                    'https://via.placeholder.com/600x400?text=作品C1-总览-2'
                ],
                versions: [
                    {
                        id: 'C1-V1',
                        uploadPlan: {
                            startDate: '2024-01-10',
                            intervalDays: 4,
                            totalUploads: 3,
                            endDate: '2024-01-18'
                        },
                        uploads: [
                            {
                                date: '2024-01-10',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户K', comment: '非常有趣！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C1-V1-上传-1'
                                ]
                            },
                            {
                                date: '2024-01-14',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户L', comment: '喜欢这个部分。'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C1-V1-上传-2'
                                ]
                            },
                            {
                                date: '2024-01-18',
                                status: 'late',
                                feedbacks: [],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C1-V1-上传-3'
                                ]
                            }
                        ]
                    },
                    {
                        id: 'C1-V2',
                        uploadPlan: {
                            startDate: '2024-02-10',
                            intervalDays: 6,
                            totalUploads: 3,
                            endDate: '2024-02-22'
                        },
                        uploads: [
                            {
                                date: '2024-02-10',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户M', comment: '期待中！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C1-V2-上传-1'
                                ]
                            },
                            {
                                date: '2024-02-16',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户N', comment: '继续努力！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C1-V2-上传-2'
                                ]
                            },
                            {
                                date: '2024-02-22',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户O', comment: '完成得很好！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C1-V2-上传-3'
                                ]
                            }
                        ]
                    }
                ]
            },
            {
                id: 'C2',
                title: '作品C2',
                images: [
                    'https://via.placeholder.com/600x400?text=作品C2-总览-1',
                    'https://via.placeholder.com/600x400?text=作品C2-总览-2'
                ],
                versions: [
                    {
                        id: 'C2-V1',
                        uploadPlan: {
                            startDate: '2024-03-10',
                            intervalDays: 5,
                            totalUploads: 3,
                            endDate: '2024-03-20'
                        },
                        uploads: [
                            {
                                date: '2024-03-10',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户P', comment: '非常喜欢！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C2-V1-上传-1'
                                ]
                            },
                            {
                                date: '2024-03-15',
                                status: 'late',
                                feedbacks: [],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C2-V1-上传-2'
                                ]
                            },
                            {
                                date: '2024-03-20',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户Q', comment: '完美的作品！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C2-V1-上传-3'
                                ]
                            }
                        ]
                    },
                    {
                        id: 'C2-V2',
                        uploadPlan: {
                            startDate: '2024-04-10',
                            intervalDays: 7,
                            totalUploads: 3,
                            endDate: '2024-04-24'
                        },
                        uploads: [
                            {
                                date: '2024-04-10',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户R', comment: '开始得很好！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C2-V2-上传-1'
                                ]
                            },
                            {
                                date: '2024-04-17',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户S', comment: '继续加油！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C2-V2-上传-2'
                                ]
                            },
                            {
                                date: '2024-04-24',
                                status: 'uploaded',
                                feedbacks: [
                                    {user: '用户T', comment: '完成得很好！'}
                                ],
                                images: [
                                    'https://via.placeholder.com/600x400?text=作品C2-V2-上传-3'
                                ]
                            }
                        ]
                    }
                ]
            }
        ]
    }
];
