<template>
  <el-footer class="footer theme_bg ">
    <div class="footer-links">
<!--      <a v-for="(link, index) in links" :key="index" href="javascript:void(0)" class="footer-link">-->
<!--        {{ link }}-->
<!--      </a>-->

      <div class="dp7_3">
        © All rights reserved. Realtime.xyz created with love by Silence Moon and his friends.<br>
        <a class="footer-link" href="https://beian.miit.gov.cn/#/Integrated/recordQuery"><span style="opacity: 1;color: white;">沪ICP备20022738号-1</span></a>
        <a class="footer-link" href="/userterms"><span style="opacity: 1;color: white;">本站常年法律顾问及知识产权法务支持为上海协力律师事务所</span></a>
      </div>
    </div>
<!--    <div class="footer-info">-->
<!--      <span>中文(简体)</span>-->
<!--      <span>© 2024 Instagram from Meta</span>-->
<!--    </div>-->
  </el-footer>
</template>





<script>
import { ElFooter } from 'element-plus';

export default {
  name: 'ResponsiveFooter',  // 定义组件名字
  components: {
    ElFooter,
  },
  data() {
    return {
      links: [
        "Meta", "关于", "博客", "工作", "帮助", "API", "隐私", "条款", "地点",
        "Instagram Lite", "Threads", "联系人上传和非用户", "Meta Verified"
      ],
    };
  },
};
</script>

<style scoped>
.footer {
  background-color: #fff;
  color: #666;
  padding: 20px;
  font-size: 13px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0.5;
}
@media (max-width: 768px) {
  .footer{
    margin-bottom: 100px;
  }
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
}

.footer-link {
  color: rgb(115, 115, 115);
  text-decoration: none;
  font-size: 13px;
  font-weight: 400;
}

.footer-info {
  font-size: 12px;
  color: #999;
}

@media (max-width: 768px) {
  .footer {
    font-size: 12px;
  }
}
</style>
