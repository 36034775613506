<template>
  <div class="action_content">
    <!-- 主模态框 -->
    <el-dialog class="action_bg"
        v-model="dialogVisible"
        width="80%"
        @close="resetSelectedAction"
    >
      <ul class="option-list">
        <li style="color: #ed4956;" @click="editNode">编辑节点</li>
        <li style="color: #ed4956;" @click="confirmDeleteNode">删除节点</li>
        <li @click="confirmDeletePlan">删除整个计划</li>
      </ul>
    </el-dialog>

    <!-- 确认框 -->
    <el-dialog class="theme_bg"
        v-model="confirmDialogVisible"
        :title="confirmTitle"
        width="30%"
        @close="resetSelectedAction"
    >
      <p>{{ confirmMessage }}</p>
      <template v-slot:footer>
        <el-button type="info" @click="confirmDialogVisible = false">取消</el-button>
        <el-button type="primary" @click="confirmAction">确认</el-button>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'ActionModal',
  props: {
    isActionModalVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      confirmDialogVisible: false,
      confirmTitle: '',
      confirmMessage: '',
      selectedAction: null,
    };
  },
  computed: {
    // 使用计算属性与父组件的 `isActionModalVisible` 同步
    dialogVisible: {
      get() {
        return this.isActionModalVisible;
      },
      set(val) {
        this.$emit('update:isActionModalVisible', val);
      },
    },
  },
  methods: {
    resetSelectedAction() {
      this.selectedAction = null;
      this.confirmDialogVisible = false;
    },

    confirmDeleteNode() {
      this.selectedAction = 'deleteNode';
      this.confirmTitle = '删除节点';
      this.confirmMessage = '删除节点将会清空当前节点的数据，确认要删除？';
      this.dialogVisible = false; // 先关闭主模态框
      this.$nextTick(() => {
        this.confirmDialogVisible = true; // 然后打开确认框
      });
    },

    confirmDeletePlan() {
      this.selectedAction = 'deletePlan';
      this.confirmTitle = '删除整个计划';
      this.confirmMessage = '警告！删除整个计划会删除这个计划内所有节点的数据，确认要删除？';
      this.dialogVisible = false; // 先关闭主模态框
      this.$nextTick(() => {
        this.confirmDialogVisible = true; // 然后打开确认框
      });
    },

    editNode() {
      this.selectedAction = 'editNode';
      this.confirmTitle = '编辑节点';
      this.confirmMessage = '您正在编辑当前节点的内容。';
      this.dialogVisible = false; // 先关闭主模态框
      this.$nextTick(() => {
        this.confirmDialogVisible = true; // 然后打开确认框
      });
    },

    confirmAction() {
      this.confirmDialogVisible = false; // 关闭确认框
      this.$emit('actionConfirmed', this.selectedAction);
    },
  },
};
</script>


<style>
.action_content .el-overlay-dialog {
  background: rgba(0, 0, 0, 0.6);
}
.action_bg{
  background-color: var(--action_bg) !important;
  max-width: 500px !important;
}
</style>
<style scoped>

.option-list {
  padding: 0;
  list-style: none;
}

.option-list li {
  padding: 15px 15px;
  cursor: pointer;
  text-align: center;
  color: var(--text-color);
  border-bottom: 1px solid var(--item-border-bottom) !important;
}

.option-list li:hover {
  background-color: var(--hover-li);
}

.dialog-footer {
  text-align: right;
}
</style>
