<template>
  <div class="follow-list-container theme_bg">
    <div class="tj_font">为你推荐</div>
    <!-- 遍历用户列表，显示用户信息 -->
    <div v-for="(user, index) in users" :key="user.id" class="follow-list-item">
      <el-popover
          placement="bottom"
          popper-class="custom-popover"
          width="300"
          trigger="hover"
      >
        <template #reference>
          <div class="user-info" @click="goUser(user)">
            <img v-if="user.avatar"  :src="user.avatar" alt="avatar" class="avatar" />
            <svg v-else t="1731673675159" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="5345" width="39" height="39"><path d="M1023.878734 511.939367A511.939367 511.939367 0 1 1 511.939367 0a511.939367 511.939367 0 0 1 511.939367 511.939367z" fill="#D9D9D9" p-id="5346"></path><path d="M511.939367 255.969684a191.977263 191.977263 0 1 0 191.977263 191.977262 191.977263 191.977263 0 0 0-191.977263-191.977262z m0 479.943156c-108.787116 0-305.243848 43.834808-365.716685 134.384084a511.939367 511.939367 0 0 0 731.433371 0C817.183215 779.747649 620.726483 735.91284 511.939367 735.91284z" fill="#8C8C8C" p-id="5347"></path></svg>

            <div class="user-details">
              <p class="username">{{ user.name }}</p>
              <p class="fullname"></p>
            </div>
            <el-button
                size="default"
                class="follow-button"
                :type="user.is_followed_by_me ? 'info' : 'primary'"
                @click.stop="toggleFollow(index)">
              {{ user.is_followed_by_me ? '已关注' : '关注' }}
            </el-button>
          </div>
        </template>
        <!-- 使用 FollowComponent 作为弹出内容，传递虚拟数据和关注状态 -->
        <FollowComponent :user="user" @toggle-follow="toggleFollow(index)" />
      </el-popover>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import FollowComponent from './FollowComponent.vue';
import {getRecommUserRequest, userFollowRequest} from "@/api/api";
import {ElMessage} from "element-plus";

export default defineComponent({
  name: 'FollowList',
  components: {
    FollowComponent,
  },
  data() {
    return {
      users: [
        // 添加更多用户
      ],
    };
  },
  mounted() {
    this.getCommUserData();
  },
  methods: {
    goUser(user){
      this.$router.push({name: 'User', params: {username: user.user_name}});
    },
    getCommUserData(){
      getRecommUserRequest().then(res=>{
        if(res.code===200){
          this.users=res.data;
        }
      }).catch(err=>{
        console.log(err);
      })
    },
    // 点击关注按钮，设置为已关注状态
    toggleFollow(index) {
      let select_user=this.users[index];
      let type=1;
      if(select_user.is_followed_by_me){
        //已经关注提交取消
        type =0;
      }else{
        type =1;
      }
      userFollowRequest(select_user.id,{type:type}).then(response => {
        if(response.code===200){
          this.users[index].is_followed_by_me = !this.users[index].is_followed_by_me;
          ElMessage({
            message: type===1 ? '已关注' : '已取消关注',
            type: type===1 ? 'success' : 'info',
          });
        }else{
          ElMessage({
            message: response.message,
            type: 'error',
          });
        }
      });
    },
  },
});
</script>

<style>
@media (min-width: 1200px) {
  .custom-popover {
    transform: translateY(-10px) !important; /* 调整垂直偏移量 */
    transform: translateX(-45px) !important; /* 调整横向偏移量 */
  }
}
</style>
<style scoped>
.follow-list-container {
  width: 100%;
  background-color: #333;
  padding: 20px 0px;
  border-radius: 8px;
  color: #fff;
}

.tj_font {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
  padding-left: 10px;
}

.follow-list-item {
  display: flex;
  align-items: center;
  margin-bottom: 0px;
  padding: 20px 30px 0px 0px;
  border-radius: 8px;
  transition: background-color 0.3s;
  position: relative;
}

.user-info {
  display: flex;
  align-items: center;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.user-details {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  padding-left: 10px;
}

.user-details .username {
  font-weight: bold;
}

.user-details .fullname {
  font-size: 12px;
  color: #aaa;
}

.follow-button {
  padding: 4px 8px;
  border-radius: 4px;
  cursor: pointer;
}

.follow-button:hover {
  background-color: #777;
}



.user-info {
  flex: 1;
  margin-left: 10px;
}

.username {
  font-weight: bold;
  font-size: 12px;
}

</style>
