<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main>
        <div class="img_container">
          <ImgTest/>
        </div>

      </el-main>
    </el-container>
    <BottomMenu/>
  </div>
</template>

<script>
// import MasonryGrid from '../components/MasonryGrid.vue';
import ImgTest from "@/components/ImgTest.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";

export default {
  components: {
    LeftSidebar,
    BottomMenu,
    ImgTest,
  },
  data() {
    return {

    };
  }
};
</script>

<style scoped>
.img_container{
  margin: 30px auto;
}
@media (min-width: 1001px) {
  .img_container{
    max-width: 1000px;
  }
}
@media (max-width: 1000px) {
  .img_container {
    margin: 30px 0px !important;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .el-main {
    --el-main-padding: 0px !important;
    box-sizing: border-box;
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    padding: 10px 0px !important;
  }
}


/* 左菜单 */
.left_side {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side_kong {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

.left_side_kong::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}
@media (max-width: 1160px) {

  .left_side {
    height: 100vh;
    width: 80px !important;
  }

  .left_side_kong {
    height: 100vh;
    width: 80px !important;
  }
}
@media (max-width: 767px) {
  .left_side {
    display: none;
  }

  .left_side_kong {
    display: none;
  }
}
.left_side {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
/* 左菜单结束 */
</style>
