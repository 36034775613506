// src/stores/messageStore.js
import { defineStore } from 'pinia';

export const modalStore = defineStore('modalStore', {
    state: () => ({
        isShowCreate: false,
        isShowPlan: false,
        isShowPlanMenu: false,
        isShowEditPlan: false,
        isShowNewMsg: false,
        myPlanList:[],
        selectPlan:{},//选择的计划信息
        isIndependent:false,//是否独立作品
    }),
    actions: {
        setSelectPlan(selectPlan){
            this.selectPlan=selectPlan;
        },
        getSelectPlan(){
            return this.selectPlan;
        },
        setMyPlanList(myPlanList){
            this.myPlanList=myPlanList;
        },
        getMyPlanList(){
            return this.myPlanList;
        },
        changeIsIndependent(newValue){
            this.isIndependent=newValue;
        },
        showNewMsgModal(){
            console.log("显示")
            this.isShowNewMsg=true;
        },
        hideNewMsgModal(){
            console.log("隐藏")
            this.isShowNewMsg=false;
        },

        // 显示创建计划
        showPlanModal(isIndependent) {
            this.isIndependent=isIndependent;
            this.isShowPlan=true;
        },
        hidePlanModal() {
            this.isShowPlan=false;
        },

        // 显示创建计划
        showEditPlanModal(isIndependent) {
            this.isIndependent=isIndependent;
            this.isShowEditPlan=true;
        },
        hideEditPlanModal() {
            this.isShowEditPlan=false;
        },

        // 显示创建计划
        showPlanMenuModal() {
            this.isShowPlanMenu=true;
        },
        hidePlanMenuModal() {
            this.isShowPlanMenu=false;
        },

        // 显示创建计划
        showCreateModal() {
            this.isShowCreate=true;
        },
        hideCreateModal() {
            this.isShowCreate=false;
        },

    },
});
