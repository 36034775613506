<template>
  <div class="login-container theme_bg">
    <div class="login-box">
      <h1 class="logo">Realtime.Art</h1>
      <el-form :model="form" :rules="rules" ref="loginForm" @submit.prevent="onSubmit" class="login-form">
        <el-form-item prop="email">
          <el-input v-model="form.email" placeholder="邮箱" @focus="handleFocus('email')" @blur="handleBlur('email')" :class="{ active: activeInput === 'email' }" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" placeholder="密码" type="password" @focus="handleFocus('password')" @blur="handleBlur('password')" :class="{ active: activeInput === 'password' }" />
        </el-form-item>
        <el-button type="primary"  size="large" class="login-button" @click="onSubmit">登录</el-button>
      </el-form>
      <p class="forgot-password-link" @click="goToPassWord">忘记密码了？</p>
      <p class="signup-link" @click="goToSignUp">没有账户？<a href="#" style="color: #3897f0;text-decoration: none">立即注册</a></p>
    </div>
    <div class="download-app">
      <ResponsiveFooter/>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus';
import ResponsiveFooter from "@/components/ResponsiveFooter.vue";
import {postRequest} from "@/api/api";
import {mapActions} from "pinia";
import {UserStore} from "@/stores/UserStore";
export default {
  name: 'LoginPage',
  components: {
    ResponsiveFooter,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
      },
      activeInput: null,
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // const phoneRegex = /^[0-9]{7,}$/;
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (value.includes('@')) {
                if (!emailRegex.test(value)) {
                  callback(new Error('请输入正确的邮箱地址'));
                } else {
                  callback();
                }
              } else {
                callback(new Error('请输入正确的邮箱地址'));
                // if (!phoneRegex.test(value) && value.length < 4) {
                //   callback(new Error('请输入正确的账号或电话号码'));
                // } else {
                //   callback();
                // }
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不少于 6 个字符', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    ...mapActions(UserStore,['setUserInfo']),
    goToPassWord() {
      this.$router.push({ name: 'PassWord' });
    },
    goToSignUp() {
      this.$router.push({ name: 'SignUp' });
    },

    handleFocus(input) {
      this.activeInput = input;
    },
    handleBlur(input) {
      if (this.activeInput === input) {
        this.activeInput = null;
      }
    },
    onSubmit() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          postRequest("/auth/login", this.form)
              .then(res=>{
                if(res.code===200){
                  ElMessage({
                    message: '登录成功',
                    type: 'success',
                  });
                  localStorage.setItem('token',res.token)
                  localStorage.setItem('user_info',JSON.stringify(res.user_info))
                  this.setUserInfo(res.user_info)
                  this.$router.replace({ name: 'HomePage' });
                }else{
                  ElMessage({
                    message: res.message,
                    type: 'error',
                  });
                }
              })
              .catch(err=>{
                console.log(err)
                ElMessage({
                  message: err.message,
                  type: 'error',
                });
              });
        } else {
          console.log('Validation Failed');
          ElMessage({
            message: '请完成所有必填项并确保输入正确',
            type: 'error',
          });
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.download-app {
  margin-top: 20px;
  text-align: center;
  position: fixed;
  bottom: 50px;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.login-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  text-align: center;
}

.logo {
  font-family: 'Lobster', cursive;
  font-size: 36px;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
}

.login-button {
  width: 100%;
  background-color: #3897f0;
  color: #fff;
  margin-top: 10px;
}

.forgot-password-link {
  margin-top: 15px;
  color: #3897f0;
  cursor: pointer;
}

.signup-link {
  margin-top: 20px;
}


.app-links img {
  width: 120px;
  margin: 10px;
}

.el-input.active::placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  color: #bbb;
}
</style>
