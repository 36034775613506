<template>
  <div class="follow-component">
    <!-- 用户头像和基本信息 -->
    <div class="header" @click="goUser(user)">
      <el-avatar :src="user.avatar" size="large" />
      <div class="user-info">
        <p class="user-name">{{ user.name }} <el-icon><i class="el-icon-check"></i></el-icon></p>
        <p class="user-fullname">{{ user.fullName }}</p>
      </div>
    </div>

    <!-- 数据统计部分 -->
    <div class="stats">
      <div class="stat-item">
        <p>{{ user.post_count }}</p>
        <p>帖子</p>
      </div>
      <div class="stat-item">
        <p>{{ user.followers_count }}</p>
        <p>粉丝</p>
      </div>
      <div class="stat-item">
        <p>{{ user.following_count }}</p>
        <p>已关注</p>
      </div>
    </div>

    <!-- 用户图片展示部分 -->
    <div class="user-images">
      <img v-for="(item, index) in user.recent_nodes" :key="index" :src="'https://bbs-oss.realtime.xyz/'+item.cover" class="image-item"  @click="go_detail(item)"/>
    </div>

    <!-- 按钮部分，动态切换关注和已关注按钮 -->
    <div class="action-buttons">
      <el-button
          :class="userData.is_followed_by_me ? 'message_btn' : 'message_btn_none'"
          type="primary"
          size="large"
          class=""
          @click="message_to_user(user)"
      >
        发送消息
      </el-button>
      <el-button
          :class="userData.is_followed_by_me ? 'followed_style' : 'unfollowed_style'"
          :type="userData.is_followed_by_me ? 'info' : 'primary'"
          size="large"
          class="follow-button"
          @click="toggleFollow">
        {{userData.is_followed_by_me ? '已关注' : '关注' }}
      </el-button>
    </div>
  </div>
</template>

<script>
import { userFollowRequest} from "@/api/api";
import {ElMessage} from "element-plus";

export default {
  name: 'FollowComponent',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      userData: this.user,
    };
  },
  methods: {
    message_to_user(user){
      this.$router.push({name: 'MessagePage', params: {username: user.user_name}});
    },
    goUser(user){
      this.$router.push({name: 'User', params: {username: user.user_name}});
    },
    //跳转详情
    go_detail(item){
      this.$router.push({name: 'TimeLine', params: {id: item.nodes[0].id}});
    },
    // 点击关注按钮，设置为已关注状态
    toggleFollow() {
      let type=1;
      if(this.userData.is_followed_by_me){
        //已经关注提交取消
        type =0;
      }else{
        type =1;
      }
      userFollowRequest(this.userData.id,{type:type}).then(response => {
        if(response.code===200){
          this.userData.is_followed_by_me = !this.userData.is_followed_by_me;
          ElMessage({
            message: type===1 ? '已关注' : '已取消关注',
            type: type===1 ? 'success' : 'info',
          });
        }else{
          ElMessage({
            message: response.message,
            type: 'error',
          });
        }
      });
    },

  },
};
</script>

<style scoped>
.message_btn_none {
  display: none;
}
.message_btn {
  display: block;
  width: calc(50% - 5px);
  border: none;
}
.message_btn:hover{
}
.follow-button {
  border: none;

  width: calc(50% - 5px);
}
.followed_style{

}
.unfollowed_style{

}
.follow-button:hover{

}

.follow-component {
  width: 300px;
  padding: 10px;
  background-color: var(--el-popover-background);
  border-radius: 8px;
  //box-shadow: 0 2px 13px rgb(255 255 255 / 44%);
  box-sizing: border-box;
  margin-left: -12px;
  margin-top: -20px;
  height: 324px;
}

/* 用户头像和信息布局 */
.header {
  display: flex;
  align-items: center;
}

.user-info {
  margin-left: 10px;
}

.user-name {
  font-size: 14px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.user-fullname {
  font-size: 13px;
  color: #888;
  margin-top: -9px;
}

/* 统计信息部分 */
.stats {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.stat-item {
  text-align: center;
}

.stat-item p:first-child {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: -13px;
}

/* 用户图片展示部分 */
.user-images {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.image-item {
  width: 32%;
  height: auto;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 4px;
  aspect-ratio: 1 / 1;
}

/* 按钮布局 */
.action-buttons {
  display: flex;
  justify-content: center;
}


</style>
