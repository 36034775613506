<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main class="set_main">
       <SetUp/>


      </el-main>
    </el-container>

    <!--    底部菜单组件-->
    <BottomMenu/>
  </div>
</template>

<script>
// import AvatarUploader from "@/components/AvatarUploader.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import SetUp from "@/components/SetUp.vue";
import LeftSidebar from "@/components/LeftSidebar.vue";

export default {
  name: 'UserProfileSetUp',
  components: {
    LeftSidebar,
    SetUp,
    BottomMenu,
    // AvatarUploader,
  },
  data() {
    return {
      username: 'dylan_wang_1220',
      avatarUrl: 'https://via.placeholder.com/150', // 替换为实际头像 URL
    };
  },
};
</script>

<style scoped>
.set_main{
  height: 100vh;
  padding: 0px !important;
}
/* 左菜单 */
.left_side {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side_kong {
  height: 100vh;
  width: 192px;
  scrollbar-width: none;
}

.left_side::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

.left_side_kong::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

@media (max-width: 1160px) {

  .left_side {
    height: 100vh;
    width: 80px !important;
  }

  .left_side_kong {
    height: 100vh;
    width: 80px !important;
  }
}

@media (max-width: 767px) {
  .left_side {
    display: none;
  }

  .left_side_kong {
    display: none;
  }
}

.left_side {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

/* 左菜单结束 */



</style>