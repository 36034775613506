<template>
  <el-form :model="form" ref="formRef" label-width="100px" class="binding-form">
    <el-form-item label="手机号" prop="phone" :rules="phoneRules">
      <el-input v-model="form.phone" placeholder="请输入手机号" />
    </el-form-item>

    <el-form-item label="验证码" prop="captcha" :rules="captchaRules">
          <el-input
              class="telcode_input"
              v-model="form.captcha"
              placeholder="请输入验证码"
          />

          <el-button
              class="code_btn"
              :disabled="isCounting"
              @click="handleGetCaptcha"
              type="info"
              size="large"
          >
            {{ isCounting ? countdown + 's' : '获取验证码' }}
          </el-button>

    </el-form-item>

    <el-form-item>
      <el-button type="primary" size="large" class="get_captcha" @click="submit" :loading="loading">
        提交
      </el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import { ElMessage } from 'element-plus';

export default {
  name:'handleGetCaptcha',
  data() {
    return {
      form: {
        phone: '',
        captcha: '',
      },
      countdown: 0,
      isCounting: false,
      loading: false,
      phoneRules: [
        { required: true, message: '手机号不能为空', trigger: 'blur' },
        {
          pattern: /^[1][3,4,5,7,8,9][0-9]{9}$/,
          message: '请输入有效的手机号',
          trigger: 'blur',
        },
      ],
      captchaRules: [
        { required: true, message: '验证码不能为空', trigger: 'blur' },
        { len: 6, message: '验证码应该是6位', trigger: 'blur' },
      ],
    };
  },
  methods: {
    // 获取验证码方法
    handleGetCaptcha() {
      if (!this.form.phone || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.phone)) {
        ElMessage.error('请输入正确的手机号');
        return;
      }

      this.isCounting = true;
      this.countdown = 60;

      // 开始倒计时
      const interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(interval);
          this.isCounting = false;
        } else {
          this.countdown--;
        }
      }, 1000);

      // 这里模拟验证码发送成功的逻辑
      ElMessage.success('验证码发送成功');
    },






    // 获取验证码方法
    // async handleGetCaptcha() {
    //   if (!this.form.phone || !/^[1][3,4,5,7,8,9][0-9]{9}$/.test(this.form.phone)) {
    //     ElMessage.error('请输入正确的手机号');
    //     return;
    //   }
    //
    //   this.isCounting = true;
    //   this.countdown = 60;
    //
    //   // 向后台发送请求，调用发送短信的接口
    //   try {
    //     const response = await axios.post('/api/send-captcha', {
    //       phone: this.form.phone, // 传递手机号
    //     });
    //
    //     if (response.data.success) {
    //       ElMessage.success('验证码发送成功');
    //     } else {
    //       ElMessage.error('验证码发送失败，请稍后再试');
    //       this.isCounting = false; // 失败时停止倒计时
    //     }
    //   } catch (error) {
    //     console.error('验证码发送请求失败:', error);
    //     ElMessage.error('验证码发送失败，请稍后再试');
    //     this.isCounting = false; // 请求失败时停止倒计时
    //   }
    //
    //   // 开始倒计时
    //   const interval = setInterval(() => {
    //     if (this.countdown === 0) {
    //       clearInterval(interval);
    //       this.isCounting = false;
    //     } else {
    //       this.countdown--;
    //     }
    //   }, 1000);
    // },

    // 提交方法
    submit() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          this.loading = true;
          // 提交表单逻辑（例如提交绑定数据等）
          setTimeout(() => {
            this.loading = false;
            ElMessage.success('提交成功');
          }, 2000);
        } else {
          ElMessage.error('表单验证失败');
        }
      });
    },
  },
};
</script>

<style>
.telcode_input{
  width: calc(100% - 100px) !important;
}
.code_btn {
  width: 100px;
  height: 53px !important;
  line-height: 53px;
  text-align: center;
  margin-top: -25px;
  border-radius: 3px;
}

.get_captcha{
  border: none !important;
  float: right;
  margin-top: 30px;
}
.binding-form {
  max-width: 750px;
  margin: 45px auto 0;
  padding: 20px;
  border: none !important;
}

.binding-form .el-form-item__error {
  top: 86%;
}
.binding-form .el-input__wrapper {
  margin-bottom: 25px;
  background-color: var(--background-color) !important;
  height: 53px !important;
}



.password-change-card {
  max-width: 750px;
  margin: 45px auto 0;
  padding: 20px;
  border: none !important;
}
.binding-form .el-form-item__label {
  font-size: 17px;
  color: var(--text-color);
  width: 118px !important;
}
</style>
