<template>
  <el-container style="height: 100vh;display: flex;flex-direction: column;" class="theme_bg phone_editprofile">
    <!-- 侧边栏菜单 -->
    <el-menu
        default-active="EditProfile"
        class="el-menu-vertical-demo theme_bg"
        text-color="#fff"
        active-text-color="#409EFF"
        @select="handleMenuSelect"
    >
      <el-menu-item index="EditProfile">
        <i class="el-icon-user-solid"></i>
        <span>个人资料</span>
      </el-menu-item>
      <el-menu-item index="PasswordSet">
        <i class="el-icon-bell"></i>
        <span>安全与密码</span>
      </el-menu-item>

      <el-menu-item index="wechatBind">
        <i class="el-icon-bell"></i>
        <span>微信授权绑定</span>
      </el-menu-item>
      <!--        <el-menu-item index="privacy">-->
      <!--          <i class="el-icon-lock"></i>-->
      <!--          <span>隐私设置</span>-->
      <!--        </el-menu-item>-->
    </el-menu>

    <!-- 主内容区域 -->

      <!--      <el-header class="content-header">-->
      <!--        <div>{{ headerTitle }}</div>-->
      <!--      </el-header>-->
      <el-main class="content-body">
        <!-- 使用 v-if 显示不同的组件 -->
        <div v-if="activeComponent === 'EditProfile'">
          <EditProfile />
        </div>
        <div v-if="activeComponent === 'PasswordSet'">
          <el-header class="content-header">
            <div>{{ headerTitle }}</div>
          </el-header>
          <PasswordReset/>
        </div>
        <div v-if="activeComponent === 'wechatBind'">
          <el-header class="content-header">
            <div>{{ headerTitle }}</div>
          </el-header>
          <WechatBind />
        </div>
      </el-main>
    </el-container>

</template>

<script>
import EditProfile from '@/components/EditProfile.vue';
import PasswordReset from '@/components/PasswordReset.vue';
import WechatBind from '@/components/WechatBind.vue';
// import Notifications from '@/components/Notifications.vue';
// import PrivacySettings from '@/components/PrivacySettings.vue';

export default {
  name: 'UserProfile',
  components: {
    EditProfile,
    PasswordReset,
    WechatBind
    // PrivacySettings,
  },
  data() {
    return {
      activeComponent: 'EditProfile', // 默认显示编辑主页
      headerTitle: '编辑主页', // 标题对应的文字
    };
  },
  methods: {
    handleMenuSelect(index) {
      this.activeComponent = index;
      switch (index) {
        case 'EditProfile':
          this.headerTitle = '编辑主页';
          break;
        case 'PasswordSet':
          this.headerTitle = '修改密码';
          break;
        case 'wechatBind':
          this.headerTitle = '微信授权绑定';
          break;

      }
    },
  },
};
</script>

<style scoped>
.phone_editprofile .el-menu-item {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.el-menu-vertical-demo li.el-menu-item {
  border-radius: 8px;
}
.el-menu-vertical-demo li.el-menu-item:hover{
  background-color: var(--hover-li);
}
.el-menu-vertical-demo li.el-menu-item.is-active {
  background-color: var(--hover-li);
}
.el-menu{
  border-right: none !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.set_side{
  width: 200px;
  height: 100vh;
  border-right: 1px solid #3f3f3f;
  padding: 10px;
}
.sidebar {
  background-color: var(--background-color);
  color: #fff;
}

.content-header {
  padding: 20px;
  font-size: 19px;
}

.content-body {
  padding: 20px;
}
</style>
