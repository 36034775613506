// src/stores/messageStore.js
import { defineStore } from 'pinia';

export const UserStore = defineStore('user', {
    state: () => ({
        user_info: localStorage.getItem('user_info') ? JSON.parse(localStorage.getItem('user_info')) : {},
    }),
    actions: {
        setUserInfo(userInfo) {
            this.user_info = userInfo;
            localStorage.setItem('user_info', JSON.stringify(userInfo));
        },
        getUserInfo(){
            return this.user_info;
        },

    },
});
