<template>
  <div class="masonry-container">
    <div class="masonry-item" v-for="(image, index) in localImages"
         :key="index"
         @mouseover="hoverImage(index)"
         @mouseleave="leaveImage(index)"
          @click="go_detail(image)">
      <img
          v-if="image.isVisible"
          :src="image.cover_image"
          @load="handleImageLoad"
      />
      <div v-else class="placeholder">
        Loading...
      </div>

      <!-- 鼠标悬停时显示的遮罩层 -->
      <div class="overlay" v-if="image.isHovered">
        <div class="overlay-content">
          <div class="icon icon_group">
            <svg t="1729510917057" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="6137" width="32" height="32"><path d="M709.692235 120.470588C612.111059 120.470588 542.117647 208.414118 512 254.976 481.822118 208.474353 411.888941 120.470588 314.307765 120.470588 174.200471 120.470588 60.235294 246.723765 60.235294 401.889882c0 72.583529 49.212235 176.549647 108.965647 231.002353 82.642824 106.194824 314.970353 297.321412 343.642353 297.321412 29.214118 0 256.542118-187.392 340.690824-296.056471C914.311529 578.740706 963.764706 474.654118 963.764706 401.889882 963.764706 246.723765 849.799529 120.470588 709.692235 120.470588" fill="#ffffff" p-id="6138"></path></svg>
            {{ image.likes }}
          </div>
          <div class="icon icon_group">
            <svg t="1729510999646" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7311" width="32" height="32"><path d="M832 96H192a96 96 0 0 0-96 96v512a96 96 0 0 0 96 96h384l231.52 144.704a16 16 0 0 0 24.48-13.568V800a96 96 0 0 0 96-96V192a96 96 0 0 0-96-96zM310.624 537.376a32 32 0 0 0-45.248 45.248c0.864 0.864 2.016 2.112 3.456 3.648 9.504 10.112 31.68 33.76 66.496 55.04 40.64 24.832 98.336 46.688 176.672 46.688s136.064-21.856 176.672-46.72c34.816-21.248 56.96-44.896 66.496-55.008 1.44-1.568 2.592-2.784 3.456-3.648a32 32 0 0 0-45.248-45.248 292.448 292.448 0 0 0-5.216 5.44c-10.112 10.624-26.112 27.552-52.832 43.872-31.36 19.2-77.664 37.312-143.328 37.312s-111.936-18.144-143.328-37.312a244.384 244.384 0 0 1-52.8-43.904 296.896 296.896 0 0 0-5.248-5.44z" fill="#ffffff" p-id="7312"></path></svg>
            {{ image.comment_count }}
          </div>
        </div>
      </div>
      <!-- 鼠标悬停时显示的遮罩层 -->

    </div>
  </div>
</template>

<script>
import Masonry from 'masonry-layout';
import {exploreRequest} from "@/api/api";

export default {
  name: 'MasonryGrid',
  data() {
    return {
      masonry: null,
      observer: null,
      localImages: [],
      isHovered: false, // 控制鼠标悬停
    };
  },
  mounted() {

    this.getData()
  },
  beforeUnmount() {
    if (this.masonry) {
      this.masonry.destroy();
    }
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    //跳转详情
    go_detail(item){
      this.$router.push({name: 'TimeLine', params: {id: item.id}});
    },
    getData(){
      exploreRequest().then(res => {
        if(res.code===200){
          this.localImages = res.data;
          this.localImages.map((item)=>{
            item.isVisible = false;
            item.isHovered = false;
            if(!item.cover_image){
              if(item.medias&&item.medias.length>0){
                item.cover_image = 'https://bbs-oss.realtime.xyz/'+item.medias[0].media_url;
              }
            }
          })

          //根据图片数量重新排列一下，以9为倍数将前面N个图片放在第N+9个位置
          let len = this.localImages.length;
          let n = Math.floor(len/9);
          for(let i=0;i<n;i++){
            let temp = this.localImages[i];
            this.localImages[i] = this.localImages[i+9];
            this.localImages[i+9] = temp;
          }

          this.initMasonry();
          this.initIntersectionObserver();
        }

      });
    },
    // 鼠标悬停事件处理
    hoverImage(index) {
      this.localImages[index].isHovered = true;
    },
    leaveImage(index) {
      this.localImages[index].isHovered = false;
    },
    initMasonry() {
      this.$nextTick(() => {
        this.masonry = new Masonry(this.$el, {
          itemSelector: '.masonry-item',
          columnWidth: '.masonry-item',
          percentPosition: true,
          transitionDuration: 0, // 设置为0以禁用动画
          gutter: 0
        });
      });
    },
    handleImageLoad() {
      if (this.masonry) {
        this.masonry.layout();
      }
    },
    initIntersectionObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const image = entry.target;
            const imageIndex = image.getAttribute('data-index');
            this.localImages[imageIndex].isVisible = true; // 修改本地的 localImages
            this.observer.unobserve(image);
          }
        });
      });

      this.$nextTick(() => {
        const imageItems = this.$el.querySelectorAll('.masonry-item .placeholder');
        imageItems.forEach((item, index) => {
          item.setAttribute('data-index', index);
          this.observer.observe(item);
        });
      });
    }
  }
};
</script>

<style scoped>
/* 禁用 Masonry 动画效果 */
.masonry-enter-active,
.masonry-leave-active {
  transition: none !important;
}
.masonry-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 0px; /* 上下和左右间距都为 20px */
  margin: 0 auto;
}

.masonry-item {
  //width: 130px;
  //height: 130px;
  margin-bottom: 0px;
  overflow: hidden;
}
.masonry-item:nth-child(9n){
  //width: 260px;
  //height: 260px;
}
@media (max-width: 375px) {
  .masonry-item {
    width: 33.333% !important;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 66.66% !important;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 400px) {
  .masonry-item {
    width: 33.333% !important;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 66.66% !important;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 480px) {
  .masonry-item {
    width: 25% !important;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 50% !important;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 600px) {
  .masonry-item {
    width: 20%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 40%;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 767px) and (min-width: 600px) {
  .masonry-item {
    width: 16.666%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 33.333%;
    aspect-ratio: 1 / 1;
  }
}
@media (min-width: 769px) {
  .masonry-item {
    width: 16.666%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 33.333%;
    aspect-ratio: 1 / 1;
  }
}
/*
@media (max-width: 1024px) and (min-width: 768px) {
  .masonry-item {
    width: 16.666%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 33.333%;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 1280px) and (min-width: 1025px) {
  .masonry-item {
    width: 12.5%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 25%;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 1440px) and (min-width: 1281px) {
  .masonry-item {
    width: 12.5%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 25%;
    aspect-ratio: 1 / 1;
  }
}
@media (max-width: 1920px) and (min-width: 1440px) {
  .masonry-item {
    width: 12.5%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 25%;
    aspect-ratio: 1 / 1;
  }
}
@media (min-width: 1921px) {
  .masonry-item {
    width: 12.5%;
    aspect-ratio: 1 / 1;
  }
  .masonry-item:nth-child(9n){
    width: 25%;
    aspect-ratio: 1 / 1;
  }
}
*/
.masonry-item img {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 0px;
  transition: transform 0.3s ease;
  object-fit: cover;
}

.masonry-item img:hover {
  transform: scale(1.05);
}

.placeholder {
  width: 100%;
  height: 200px;
  background-color: #f0f0f0;
  border-radius: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #999;
  font-size: 14px;
}


/* 鼠标悬停时的遮罩层 */
.icon_group{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.overlay-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
  align-items: center;
  width: 100%;
}
@media (max-width: 1050px) {
  .overlay-content {
    flex-direction: column;
    gap: 10px;
  }
}
.icon {
  color: white;
  font-size: 0.7em;
  display: flex;
  align-items: center;
}

.icon svg {
  margin-right: 6px;
  width: 16px;
  height: 16px;
}


</style>
