<template>
  <div class="search-container theme_bg">
    <el-input v-model="query" @input="handleInput" placeholder="搜索">
      <template #prefix>
        <i class="el-icon-search"></i>
      </template>
      <template #suffix>
        <svg v-if="query" @click="clearQuery" t="1729579274116" class="icon input_del_icon" viewBox="0 0 1025 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8706" id="mx_n_1729579274117" width="32" height="32">
          <path d="M513.852 144.914c-203.711 0-368.936 165.192-368.936 368.936 0 203.756 165.203 368.936 368.936 368.936 203.756 0 368.936-165.143 368.936-368.936 0.005-203.711-165.177-368.936-368.936-368.936zM665.109 616.241c6.27 6.218 10.153 14.838 10.153 24.363 0 0.025 0 0.050 0 0.076 0 0.003 0 0.008 0 0.017 0 19.086-15.472 34.558-34.558 34.558-9.555 0-18.204-3.877-24.46-10.146l-102.39-102.331-102.319 102.364c-6.255 6.272-14.905 10.153-24.462 10.153-0.016 0-0.031 0-0.046 0-19.061 0-34.558-15.463-34.558-34.598 0-0.018 0-0.037 0-0.056 0-9.517 3.866-18.129 10.115-24.354l102.379-102.435-102.355-102.349c-6.25-6.245-10.117-14.875-10.117-24.408 0-0.021 0-0.042 0-0.063 0-19.093 15.486-34.583 34.586-34.583 0.010 0 0.023 0 0.036 0 9.533 0 18.162 3.865 24.409 10.116l102.319 102.378 102.39-102.352c6.256-6.25 14.896-10.116 24.438-10.116 19.098 0 34.579 15.482 34.579 34.579 0 0.003 0 0.007 0 0.007 0 0.020 0 0.044 0 0.067 0 9.537-3.882 18.168-10.15 24.398l-102.321 102.321 102.33 102.396z" fill="#8a8a8a" p-id="8707"></path>
        </svg>
      </template>
    </el-input>

    <div v-if="query" class="search-results">
      <el-scrollbar>
        <el-list>
          <el-list-item v-for="(result, index) in filteredResults" :key="index">
            <router-link :to="`/user/${result.name}`" class="result-link">
              <div class="result-item">
                <img :src="result.avatar" class="avatar" alt="avatar" />
                <div class="result-info">
                  <span>{{ result.name }}</span>
                  <span class="result-desc">{{ result.desc }}</span>
                </div>
                <svg @click.stop="removeResult(index)" aria-label="关闭" class="all_del_icon" fill="currentColor" height="16" role="img" viewBox="0 0 24 24" width="16">
                  <title>关闭</title>
                  <polyline fill="none" points="20.643 3.357 12 12 3.353 20.647" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"></polyline>
                  <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" x1="20.649" x2="3.354" y1="20.649" y2="3.354"></line>
                </svg>
              </div>
            </router-link>
          </el-list-item>
        </el-list>
      </el-scrollbar>
    </div>


  </div>
</template>

<script>
export default {
  name:'TopSearch',
  data() {
    return {
      query: '',
      recentSearches: [
        {
          name: 'yibo.w_85',
          avatar: require('@/assets/avatar1.jpg'),
          desc: '王一博 · 334 万位粉丝',
          link: '/user/yibo.w_85'
        },
        {
          name: 'xiaozhan_911005',
          avatar: require('@/assets/avatar1.jpg'),
          desc: '肖战 · 已关注',
          link: '/user/xiaozhan_911005'
        },
        {
          name: 'uniq_wangyibo_myanmar',
          avatar: require('@/assets/avatar1.jpg'),
          desc: '王一博',
          link: '/user/uniq_wangyibo_myanmar'
        }
      ],
      allResults: [
        {
          name: 'yibo.w_85',
          avatar: require('@/assets/avatar1.jpg'),
          desc: '王一博 · 334 万位粉丝'
        },
        {
          name: 'yibo85._ww',
          avatar: require('@/assets/avatar1.jpg'),
          desc: 'UNIQ'
        },
        {
          name: 'wangyibo.33',
          avatar: require('@/assets/avatar1.jpg'),
          desc: 'wang yibo'
        },
        {
          name: 'w.yibo8597',
          avatar: require('@/assets/avatar1.jpg'),
          desc: '王一博'
        },
        {
          name: 'uniq_wangyibo_myanmar',
          avatar: require('@/assets/avatar1.jpg'),
          desc: 'UNIQ Wang Yibo'
        }
      ]
    };
  },
  computed: {
    filteredResults() {
      return this.allResults.filter(result => result.name.includes(this.query));
    }
  },
  methods: {
    handleInput() {
      // 可以在此添加额外的输入处理逻辑。
    },
    clearQuery() {
      this.query = '';
    },
    removeResult(index) {
      this.allResults.splice(index, 1);
    },
    removeRecentSearch(index) {
      this.recentSearches.splice(index, 1);
    },
    clearAllRecentSearches() {
      this.recentSearches = [];
    },
    selectResult(result) {
      this.query = result.name;
      // 可以在这里添加额外的逻辑，例如触发搜索
    },
    goToUserPage(result) {
      // 跳转到用户主页的逻辑
      this.$router.push(`/user/${result.name}`);
    }
  }
};
</script>

<style scoped>
.input_del_icon {
  width: 20px;
  height: 20px;
}

.search-container {
  width: 100%;
  position: relative;
}

.el-input {
  width: 100%;
}

.search-results,
.recent-searches {
  position: absolute;
  top: 40px;
  width: 100%;
  background: var(--background-color);
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  max-height: 500px;
  overflow-y: auto;
  z-index: 10;
}

.recent-header {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  font-weight: bold;
}

.clear-all-button {
  color: #409EFF;
  font-size: 14px;
  cursor: pointer;
}

.result-item,
.recent-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.result-info,
.recent-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
}

.result-desc,
.recent-desc {
  font-size: 14px;
  color: #888;
}

.result-link {
  text-decoration: none;
  color: inherit;
}

.remove-button {
  color: #888;
  font-size: 16px;
}

.recent-list-item {
  border-bottom: 1px solid #f0f0f0;
}
</style>
