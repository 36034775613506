// src/stores/messageStore.js
import { defineStore } from 'pinia';
import {messageSendRequest, RecentConversationsRequest} from "@/api/api";
export const useMessageStore = defineStore('messageStore', {
    state: () => ({
        chats: [], // 聊天记录列表
        selectedChatId: null, // 当前选中的聊天 ID
        selectedChat: null, // 当前选中的聊天
    }),
    actions: {
        // 获取最近的聊天记录
        async getChats() {
            try {
                const res = await RecentConversationsRequest();
                if (res.code === 200) {
                    this.chats = res.data;
                }
            } catch (error) {
                console.error('获取聊天记录失败', error);
            }
        },
        // 创建一个新的聊天记录
        createChat(user) {
            const newChat = {
                sender:user,
                user:user,
                id: user.id,
                name: user.name,
                messages: [], // 初始时没有消息
                isNew: true, // 标记这是新创建的聊天
            };
            this.chats.unshift(newChat); // 将新聊天添加到顶部
            return newChat;
        },
        // 选择当前聊天
        selectChat(chatId) {
            this.selectedChatId = chatId;
        },
        // 发送消息
        async sendMessage(chat, content) {
            // 如果这是与该用户的第一次消息，则调用接口发送消息
            if (chat.isNew) {
                try {
                    const res = await messageSendRequest({
                        receiver_id: chat.userId,
                        content,
                    });
                    if (res.code === 200) {
                        chat.messages.push({ content, isSent: true }); // 将发送的消息添加到聊天记录
                        chat.isNew = false; // 发送第一条消息后，标记为不是新聊天
                    }
                } catch (error) {
                    console.error('发送消息失败', error);
                }
            } else {
                chat.messages.push({ content, isSent: true }); // 不是新消息，只更新聊天记录
            }
        },
    },
});
