<template>
  <div class="user-info-container">
    <!-- 使用 el-popover 来显示详细的 FollowComponent 信息 -->
    <el-popover
        placement="bottom"
        width="300"
        height="300"
        trigger="hover"
        v-model:visible="popoverVisible"
        popper-class="custom-popper"
        show-arrow="false"
    >
      <!-- FollowComponent 的使用，显示详细用户信息 -->
      <follow-component :user="userData" />

      <!-- 触发区域，用户头像和用户名 -->
      <template #reference>
        <div class="user-trigger">
          <el-avatar :src="userData.avatar" size="medium" />
          <span class="user-name">{{ userData.name }}</span>
          <div class="up_time">{{post_time}}</div>
        </div>
      </template>
    </el-popover>
  </div>
</template>

<script>
import FollowComponent from './FollowComponent.vue'; // 引入 FollowComponent

export default {
  props: {
    user: {
      type: Object,
      required: true,
    },
    post: {
      type: Object,
      required: true,
    },
    plan:{
      type:Object,
    }
  },
  components: {
    FollowComponent, // 注册 FollowComponent
  },
  data() {
    return {
      popoverVisible: false, // 控制 popover 显示状态
      userData: this.user,
      post_time:"",
    };
  },
  mounted() {
    //计算post发布时间，换算成X小时、x天前
    let post_time = new Date(this.post.created_at);
    let now_time = new Date();
    let time = now_time.getTime() - post_time.getTime();
    let hours = time / 1000 / 60 / 60;
    if (hours < 24) {
      this.post_time = "•" + Math.floor(hours) + "小时前";
    } else {
      this.post_time = "•" + Math.floor(hours / 24) + "天前";
    }
  },
  methods: {
    goUser(user){
      this.$router.push({name: 'User', params: {username: user.user_name}});
    },
    // 点击触发区域，显示 popover
    showPopover() {
      this.popoverVisible = true;
    },
    // 隐藏 popover
    hidePopover() {
      this.popoverVisible = false;
    },
  },
};
</script>

<style scoped>
.el-popover{
  --el-popover-padding: 0px 0px !important;
}
.el-popover.el-popper{
  padding: 0px 0px !important;
}
.un_popover{
  width: 300px;
  height: 386px;
}
.el-popper[data-popper-placement^=bottom]  >>> .el-popper__arrow {
  top: -5px;
  display: none !important;
}
.el-popper {
  background: var(--background-color) !important;
  border: none;
}
.el-popper__arrow {
  display: none;
}
.up_time {
  color: #909399;
  font-size: 12px;
  font-weight: normal;
}
.x_icon {
  margin: 0px 5px;
}
/* 主容器样式 */
.user-info-container {
  display: inline-block;
  cursor: pointer;
}

/* 用户头像和名称的触发器样式 */
.user-trigger {
  display: flex;
  align-items: center;
}

.user-name {
  margin-left: 9px;
  font-weight: bold;
  font-size: 14px;
}

.custom-popper {
  padding: 0px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  height: 320px !important;
}
</style>
