<template>
  <div class="theme-switcher">
        <div class="font_item">切换黑白主题</div>
        <el-switch v-model="isDarkMode" @change="toggleTheme" />
  </div>
</template>

<script>
import { useThemeStore } from '@/stores/themeStore';
import { mapState, mapActions } from 'pinia';

export default {
  name: 'ThemeSwitcher',
  computed: {
    ...mapState(useThemeStore, ['isDarkMode']),
    themeIcon() {
      return this.isDarkMode ? 'el-icon-moon' : 'el-icon-sun';
    },
  },
  methods: {
    ...mapActions(useThemeStore, ['toggleTheme']),
  },
  mounted() {
    const theme = this.isDarkMode ? 'dark' : 'light';
    document.documentElement.setAttribute('data-theme', theme);
  },
};
</script>

<style scoped>
.el-switch__core {
  background: #5d5d5d;
}
.font_item{
  margin-right: 10px;
}
.theme-switcher {
  background-color: var(--el-popover-background);
  color: var(--text-color);
  padding: 0px;
  border-radius: 10px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.el-popper.is-light>.el-popper__arrow:before {
  background: var(--el-popover-background) !important;
  border: 1px solid var(--el-popover-background) !important;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.el-icon {
  cursor: pointer;
  font-size: 20px;
}
</style>
