// src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store'; // 引入 Vuex Store

import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';//element plus 图标
import VueLazyload from 'vue-lazyload';
import { createPinia } from 'pinia';
// import './assets/styles/global.css'; // 引入全局样式

// 创建 Vue 应用实例
const app = createApp(App);

const pinia = createPinia();
app.use(pinia);


// 使用路由
app.use(router);
createApp(App).use(ElementPlus).use(router).mount('#app');

app.use(store); // 将 Store 安装到 Vue 实例中
app.use(ElementPlus);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {//element plus 图标
    app.component(key, component);
}

// 配置 Lazyload (你也可以添加 loading 或 error 占位图)
app.use(VueLazyload, {
    loading: 'https://via.placeholder.com/300x300?text=Loading...',  // 加载时占位图
    error: 'https://via.placeholder.com/300x300?text=Error',         // 加载失败时的占位图
});


// 挂载应用
app.config.devtools = true;  // 启用调试模式
app.mount('#app');

