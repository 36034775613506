<template>
  <div class="gallery-container">
    <el-tabs v-model="activeTab" class="custom-tabs" tab-position="top" @tab-click="doChange">
      <el-tab-pane label="作品集" name="作品集">
        <template #label>
          <svg aria-label="" class="x1lliihq x1n2onr6 x1roi4f4" fill="currentColor" height="12" role="img" viewBox="0 0 24 24" width="12"><title></title><path d="M10.201 3.797 12 1.997l1.799 1.8a1.59 1.59 0 0 0 1.124.465h5.259A1.818 1.818 0 0 1 22 6.08v14.104a1.818 1.818 0 0 1-1.818 1.818H3.818A1.818 1.818 0 0 1 2 20.184V6.08a1.818 1.818 0 0 1 1.818-1.818h5.26a1.59 1.59 0 0 0 1.123-.465Z" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path d="M18.598 22.002V21.4a3.949 3.949 0 0 0-3.948-3.949H9.495A3.949 3.949 0 0 0 5.546 21.4v.603" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><circle cx="12.072" cy="11.075" fill="none" r="3.556" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></circle></svg>
          <span>作品集</span>
        </template>
        <div class="image-grid" v-if="plans.length<=0">
          暂无数据
        </div>
        <div class="image-grid" v-else>
          <div
              class="image-item"
              v-for="(plan, index) in plans"
              :key="index"
              @mouseover="showOverlay(index)"
              @mouseleave="hideOverlay(index)"
              @click="goToPlanDetail(plan)"
          >
<!--            <div v-if="!plan.loaded" class="loader">加载中...</div>-->
            <img
                :src="'https://bbs-oss.realtime.xyz/'+plan.cover"
                alt="Post image"
                class="post-image"
                :ref="'image' + index"
            />
            <div class="overlay" v-if="plan.showOverlay">
              <div class="overlay-content">
                <div class="svg_group"><svg t="1730093305033" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4278" width="24" height="24"><path d="M512 901.746939c-13.583673 0-26.122449-4.179592-37.093878-13.061225-8.881633-7.314286-225.697959-175.020408-312.424489-311.379592C133.746939 532.37551 94.040816 471.24898 94.040816 384.522449c0-144.718367 108.146939-262.269388 240.326531-262.269388 67.395918 0 131.657143 30.82449 177.632653 84.636735 45.453061-54.334694 109.191837-84.636735 177.110204-84.636735 132.702041 0 240.326531 117.55102 240.326531 262.269388 0 85.159184-37.093878 143.673469-67.395919 191.216327l-1.044898 1.567346c-86.726531 136.359184-303.542857 304.587755-312.424489 311.379592-10.44898 8.359184-22.987755 13.061224-36.571429 13.061225z" fill="#ffffff" p-id="4279"></path></svg>
                  {{ plan.likes }}</div>
                <div class="svg_group"><svg t="1730093394135" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8858" width="24" height="24"><path d="M510.4 145.6c-202.6 0-366.8 159.6-366.8 356.5 0 102.1 44.3 194.2 115 259.2-15.6 23.3-34.4 44.1-56.2 61.4 1.1-0.2 1.7-0.2 1.3 0-7.8 5.3-13.1 14.4-13.1 24.7 0 13.2 8.6 24 20.3 27.9 14.6 2.2 29.4 3.7 44.7 3.7 18.5 0 36.5-1.9 54.2-5h0.1c10.8-2 21.4-4.3 31.7-7.2 26.7-8.2 53.5-20.2 53.5-20.2l8.3-3.8c17.3 6.9 41.1 10.7 60.8 12.8v-0.1c5.5 0.7 10.9 1.1 16.4 1.6 12.4 0.9 20.8 1.1 20.8 1.1v0.1c2.9 0.1 5.9 0.3 8.9 0.3 202.6 0 366.8-159.6 366.8-356.5 0.1-197-164.2-356.5-366.7-356.5z" p-id="8859" fill="#ffffff"></path></svg>
                  {{ plan.comments }}</div>
              </div>
            </div>
          </div>
        </div>

      </el-tab-pane>


      <el-tab-pane label="收藏" name="收藏">
        <template #label>
          <svg aria-label="" class="x1lliihq x1n2onr6 x1roi4f4" fill="currentColor" height="12" role="img" viewBox="0 0 24 24" width="12"><title></title><polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon></svg>
          <span>收藏</span>
        </template>
        <div class="image-grid" v-if="favorite_posts.length<=0">
          暂无数据
        </div>
        <div class="image-grid" v-else>
          <div
              class="image-item"
              v-for="(plan, index) in favorite_posts"
              :key="index"
              @mouseover="showOverlay2(index)"
              @mouseleave="hideOverlay2(index)"
              @click="goToFavoriteDetail(plan)"
          >
            <img
                :src="'https://bbs-oss.realtime.xyz/'+plan.cover_image"
                alt="Post image"
                class="post-image"
                :ref="'image' + index"
            />
            <div class="overlay" v-if="plan.showOverlay">
              <div class="overlay-content">
                <svg t="1730093305033" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="4278" width="24" height="24"><path d="M512 901.746939c-13.583673 0-26.122449-4.179592-37.093878-13.061225-8.881633-7.314286-225.697959-175.020408-312.424489-311.379592C133.746939 532.37551 94.040816 471.24898 94.040816 384.522449c0-144.718367 108.146939-262.269388 240.326531-262.269388 67.395918 0 131.657143 30.82449 177.632653 84.636735 45.453061-54.334694 109.191837-84.636735 177.110204-84.636735 132.702041 0 240.326531 117.55102 240.326531 262.269388 0 85.159184-37.093878 143.673469-67.395919 191.216327l-1.044898 1.567346c-86.726531 136.359184-303.542857 304.587755-312.424489 311.379592-10.44898 8.359184-22.987755 13.061224-36.571429 13.061225z" fill="#ffffff" p-id="4279"></path></svg>
                {{ plan.likes }}
                <svg t="1730093394135" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="8858" width="24" height="24"><path d="M510.4 145.6c-202.6 0-366.8 159.6-366.8 356.5 0 102.1 44.3 194.2 115 259.2-15.6 23.3-34.4 44.1-56.2 61.4 1.1-0.2 1.7-0.2 1.3 0-7.8 5.3-13.1 14.4-13.1 24.7 0 13.2 8.6 24 20.3 27.9 14.6 2.2 29.4 3.7 44.7 3.7 18.5 0 36.5-1.9 54.2-5h0.1c10.8-2 21.4-4.3 31.7-7.2 26.7-8.2 53.5-20.2 53.5-20.2l8.3-3.8c17.3 6.9 41.1 10.7 60.8 12.8v-0.1c5.5 0.7 10.9 1.1 16.4 1.6 12.4 0.9 20.8 1.1 20.8 1.1v0.1c2.9 0.1 5.9 0.3 8.9 0.3 202.6 0 366.8-159.6 366.8-356.5 0.1-197-164.2-356.5-366.7-356.5z" p-id="8859" fill="#ffffff"></path></svg>
                {{ plan.comment_count }}
              </div>
            </div>
          </div>
        </div>

      </el-tab-pane>

    </el-tabs>


  </div>
</template>

<script>
// import ImgTest from "@/components/ImgTest.vue";

import { getPlanRequest, getUserFavoriteRequest} from "@/api/api";
import {StatusStore} from "@/stores/StatusStore";
import { mapActions, mapState } from 'pinia';
import {ElMessage} from "element-plus";
export default {
  components: {},
  computed:{
  ...mapState(StatusStore, ['UserIsCollect','selectPlan']),//引入store中的变量方便全局管控
    IsCollect() {
      return this.UserIsCollect;
    },
  },
  props: {
    user_name: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      username:this.user_name,
      activeTab: "作品集",
      loadedCount: 3,
      plans: [],
      favorite_posts:[],
      observer: null,
    };
  },
  mounted() {
    // this.setupObserver();
    this.getUserPlans();
    this.getUserFavorite();
    this.init();
  },
  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  methods: {
    ...mapActions(StatusStore, ['setUserIsCollect','getUserIsCollect']),//引入store中的事件方便全局调用
    init(){
      if(this.getUserIsCollect()){
        this.activeTab='收藏';
      }else{
        this.activeTab='作品集';
      }
    },
    doChange(targetName){
      console.log(targetName.paneName)
        this.setUserIsCollect(targetName.paneName==='收藏')
    },
    getUserPlans(){
      //获取用户已有的计划
      getPlanRequest({user_name:this.user_name}).then(res => {
        this.plans = res.data;
        // for (let i = 0; i <this.plans.length ; i++) {
        //   // this.plans[i].comments=0;
        // }
        console.log(res);
      })
    },
    goToFavoriteDetail(post){
      this.$router.push({name: 'TimeLine', params: {id: post.id}});
    },
    getUserFavorite(){
      //获取用户收藏的帖子
      getUserFavoriteRequest({user_name:this.user_name}).then(res => {
        this.favorite_posts = res.data.data;
        // for (let i = 0; i <this.plans.length ; i++) {
        //   this.favorite_posts[i].comments=0;
        // }
        console.log(res);
      })
    },
    setupObserver() {
      this.observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && this.loadedCount < this.plans.length) {
            const index = entry.target.dataset.index;
            this.plans[index].loaded = true;
            this.loadedCount += 1;
            if (this.loadedCount >= 3) {
              this.observer.disconnect();
              setTimeout(() => {
                this.setupObserver();
              }, 500);
            } else {
              this.observer.unobserve(entry.target);
            }
          }
        });
      });

      this.$nextTick(() => {
        this.plans.forEach((image, index) => {
          const imgElement = this.$refs[`image${index}`];
          if (imgElement && imgElement[0]) {
            imgElement[0].dataset.index = index;
            this.observer.observe(imgElement[0]);
          }
        });
      });
    },
    onImageLoad(index) {
      this.plans[index].loaded = true;
    },
    showOverlay(index) {
      this.plans[index].showOverlay = true;
    },
    hideOverlay(index) {
      this.plans[index].showOverlay = false;
    },
    showOverlay2(index) {
      this.favorite_posts[index].showOverlay = true;
    },
    hideOverlay2(index) {
      this.favorite_posts[index].showOverlay = false;
    },
    goToPlanDetail(plan) {
      if(plan.nodes.length>0){
        this.$router.push({name: 'TimeLine', params: {id: plan.nodes[0].id}});
      }else{
        ElMessage({
          message: '该计划还没有上传作品',
          type: 'info',
        });
      }

    },
  },
};
</script>

<style scoped>
.gallery-container {
  max-width: 935px;
  margin: 0 auto;
  padding: 20px;
}
.svg_group{
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 768px) {
  .gallery-container {
    padding: 0px !important;
    margin-bottom: 80px;
  }
  .svg_group{
    flex-direction: row;
    margin-right: 0px;
  }
}
/* 自定义Tab样式 */
.custom-tabs {
  display: flex;
  justify-content: center;
  border-bottom: none;
  margin-bottom: 10px;
  position: relative;
  padding-top: 10px;
}

.custom-tabs >>> .el-tabs__nav-wrap:after {
  background-color: var(--background-color) !important;
}
/* 顶部边线 */
.custom-tabs::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #8f8f8f;
}

.custom-tabs >>> .el-tabs__item {
  font-size: 13px;
  color: #9e9e9e;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  margin: 0 20px;
  position: relative;
  justify-content: center;
}

/* 激活状态样式 */
.custom-tabs >>> .el-tabs__item.is-active {
  color: var(--text-color);
  font-weight: bold;
  font-size: 13px;
}
.custom-tabs >>> .el-tabs__item svg {
  margin-right: 8px;
}
/* 将Tab指示线放在顶部并自适应文字宽度 */
.custom-tabs >>> .el-tabs__active-bar {
  position: absolute;
  top: 0; /* 将指示线放在顶部 */
  height: 1px;
  background-color: var(--text-color);
  width: auto; /* 指示线自适应宽度 */
  padding: 0 10px; /* 增加左右内边距，使指示线与文字宽度对齐 */
  left: 0%;
  transform: translateX(-50%);
}

.custom-tabs >>> .el-tabs__item i {
  font-size: 18px;
  margin-right: 4px;
  display: inline-block;
}

.custom-tabs >>> .el-tabs__item span {
  display: inline-block;
}

.image-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  //grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 3px;
}

.image-item {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.post-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-item:hover .post-image {
  transform: scale(1.1);
}

.loader {
  width: 100%;
  height: 100%;
  background: #f3f3f3;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: #999;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
@media (max-width: 768px) {
  .overlay-content {
    flex-direction: column;
  }

}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.5s ease;
}

.image-item:hover .overlay {
  opacity: 1;
}

.overlay-content {
  color: white;
  display: flex;
  gap: 10px;
  font-size: 14px;
}
</style>
