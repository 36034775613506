<!-- PortfolioEdit.vue -->
<template>
  <div class="protfilo_inner">
    <div class="edit_content">
      <div class="edit_plan_btn">
        <svg t="1731239005550" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3647" width="28" height="28"><path d="M874.666667 768a21.333333 21.333333 0 0 1 21.333333 21.333333v42.666667a21.333333 21.333333 0 0 1-21.333333 21.333333h-341.333334a21.333333 21.333333 0 0 1-21.333333-21.333333v-42.666667a21.333333 21.333333 0 0 1 21.333333-21.333333h341.333334zM607.786667 125.141333a21.333333 21.333333 0 0 1 30.165333 0l211.2 211.2a21.333333 21.333333 0 0 1 0 30.165334L389.546667 826.154667a21.333333 21.333333 0 0 1-17.066667 6.144l-193.536-17.621334a21.333333 21.333333 0 0 1-19.328-19.285333l-17.578667-193.621333a21.333333 21.333333 0 0 1 6.144-16.981334z m15.061333 105.642667l-393.130667 393.130667 10.069334 110.634666 110.634666 10.026667 259.456-259.498667-118.869333-118.869333 60.330667-60.330667 118.869333 118.869334 73.301333-73.301334-120.661333-120.661333z" fill="#ffffff" p-id="3648"></path></svg>
        编辑计划</div>
    </div>
    <div class="portfolio-list">
      <!-- 新建项目框 -->
      <div class="create-new">
        <div class="create-new-content">
          <el-icon>
            <plus/>
          </el-icon>
          <span>更新进度</span>
        </div>
      </div>

      <!-- 作品项 -->
      <div class="portfolio-item" v-for="(item, index) in displayedItems" :key="index">
        <div class="portfolio-thumbnail">
          <img :src="item.thumbnail" alt="Portfolio Thumbnail" v-if="item.thumbnail"/>
          <div class="no-thumbnail" v-else>Image not available</div>
          <div class="img_edit">
            <svg t="1731220446522" class="icon" viewBox="0 0 1028 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="3340" width="24" height="24">
              <path
                  d="M730.852918 818.004669c15.738521 0 28.488716 13.34786 28.488716 29.883269s-12.750195 29.883268-28.488716 29.883268H226.921401c-15.738521 0-28.488716-13.34786-28.488716-29.883268s12.750195-29.883268 28.488716-29.883269h503.931517z m-505.226459-40.342412l68.432685-191.850584 111.564202 117.142413-179.996887 74.708171z m202.807782-96.82179L315.973541 562.801556l305.905058-318.456031 112.560312 118.038911-306.00467 318.456031z m326.922957-342.960311l-112.560311-118.038911 56.280156-59.069261c18.627237-19.523735 48.809339-19.523735 67.536187 0l45.024124 47.215565c18.627237 19.523735 18.627237 51.299611 0 70.823346l-56.280156 59.069261z"
                  p-id="3341" fill="#ffffff"></path>
              <path
                  d="M989.143969 1024H34.871595c-19.224903 0-34.863813-15.638911-34.863813-34.863813V34.863813C0.007782 15.638911 15.646693 0 34.871595 0h631.533074c19.224903 0 34.863813 15.638911 34.863813 34.863813s-15.638911 34.863813-34.863813 34.863813H69.735409v884.544748h884.544747V357.603113c0-19.224903 15.638911-34.863813 34.863813-34.863813s34.863813 15.638911 34.863813 34.863813v631.533074c0 19.224903-15.638911 34.863813-34.863813 34.863813z"
                  p-id="3342" fill="#ffffff"></path>
            </svg>
          </div>

          <el-tag :type="item.published ? 'success' : 'warning'" :class="item.published ? 'published' : 'unpublished'"
                  class="status-tag">
            {{ item.published ? 'Published' : 'unPublished' }}
          </el-tag>
        </div>
        <div class="portfolio-info">
          <h4 class="portfolio-title">{{ item.title }}</h4>

          <div class="portfolio-stats">
            <div class="stats_1">
              <svg t="1731212486444" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="2365" width="16" height="16">
                <path
                    d="M510.557138 204.354248c-302.839283 0-458.323497 306.150702-458.323497 306.150702s117.330242 306.189587 458.323497 306.189587c308.802088 0 458.300984-304.995389 458.300984-304.995389S818.167075 204.354248 510.557138 204.354248L510.557138 204.354248 510.557138 204.354248zM511.245823 701.866279c-110.729917 0-190.772928-83.72589-190.772928-191.364399 0-107.647719 80.049151-191.352119 190.772928-191.352119 110.723777 0 190.763718 83.697237 190.763718 191.352119C702.010565 618.140389 621.970624 701.866279 511.245823 701.866279L511.245823 701.866279 511.245823 701.866279zM511.245823 395.675668c-63.286372 0.145309-114.460892 53.321416-114.460892 114.827235 0 61.473073 51.175543 114.821095 114.460892 114.821095 63.282279 0 114.453728-53.352115 114.453728-114.821095C625.703645 448.975595 574.529125 395.556964 511.245823 395.675668L511.245823 395.675668 511.245823 395.675668z"
                    fill="#ffffff" p-id="2366"></path>
              </svg>
              <span class="views">{{ item.views }}</span>
            </div>
            <div class="stats_2">
              <svg t="1731212540294" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="3473" width="16" height="16">
                <path d="M806.868 406h-154.16c-0.479 2-0.757 2-0.757 2h166.673c-3.702-1-7.647-2-11.756-2z"
                      fill="#ffffff" p-id="3474"></path>
                <path
                    d="M622.48 405.937c8.383-21.278 104.826-220.109-30.992-302.775 0 0-86.128-29.741-94.63 57.167C490.01 230.336 492 339.554 372 383.342v541.792l430.925-1.529c20.869 0 37.868-16.917 37.868-37.786l34.049-442.096c0-4.212-0.698-8.249-1.969-12.032-3.789-11.272-12.719-20.161-24.029-23.864"
                    fill="#ffffff" p-id="3475"></path>
                <path
                    d="M342 850.428C342 892.165 308.165 926 266.428 926h-41.856C182.835 926 149 892.165 149 850.428V438.572C149 396.835 182.835 363 224.572 363h41.856C308.165 363 342 396.835 342 438.572v411.856z"
                    fill="#ffffff" p-id="3476"></path>
              </svg>
              {{ item.likes }}
            </div>
            <div class="stats_3">
              <svg t="1731215349137" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="4618" width="16" height="16">
                <path
                    d="M512 85.333333c235.637333 0 426.666667 191.029333 426.666667 426.666667S747.637333 938.666667 512 938.666667a424.778667 424.778667 0 0 1-219.125333-60.501334 2786.56 2786.56 0 0 0-20.053334-11.765333l-104.405333 28.48c-23.893333 6.506667-45.802667-15.413333-39.285333-39.296l28.437333-104.288c-11.008-18.688-18.218667-31.221333-21.802667-37.909333A424.885333 424.885333 0 0 1 85.333333 512C85.333333 276.362667 276.362667 85.333333 512 85.333333z m-102.218667 549.76a32 32 0 1 0-40.917333 49.216A223.178667 223.178667 0 0 0 512 736c52.970667 0 103.189333-18.485333 143.104-51.669333a32 32 0 1 0-40.906667-49.216A159.189333 159.189333 0 0 1 512 672a159.189333 159.189333 0 0 1-102.218667-36.906667z"
                    fill="#ffffff" p-id="4619"></path>
              </svg>
              {{ item.comments }}
            </div>
          </div>
        </div>
      </div>

    </div>
    <div class="dashed-line" v-if="hasMore" @click="loadMore">点击加载更多</div>
  </div>
</template>

<script>
import {ref, onMounted} from 'vue';
import {ElMessage, ElTag, ElIcon} from 'element-plus';

export default {
  name: 'PortfolioEdit',
  components: {
    ElTag,
    ElIcon,
  },
  setup() {
    const items = ref([]);
    const displayedItems = ref([]);
    const itemsPerPage = 4;
    const currentPage = ref(1);
    const hasMore = ref(true);

    const loadData = () => {
      items.value = [
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo1.jpg'),
          published: false,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo2.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo3.jpg'),
          published: true,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo4.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo5.jpg'),
          published: true,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo1.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo1.jpg'),
          published: false,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo2.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo3.jpg'),
          published: true,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo4.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo5.jpg'),
          published: true,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo1.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo1.jpg'),
          published: false,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo2.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo3.jpg'),
          published: true,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo4.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
        {
          title: 'Frozen Effect - Elemental VFX Example Long Title',
          thumbnail: require('@/assets/demo5.jpg'),
          published: true,
          views: 835,
          likes: 41,
          comments: 4,
        },
        {
          title: 'Thunder Effect - Elemental VFX',
          thumbnail: require('@/assets/demo1.jpg'),
          published: true,
          views: 641,
          likes: 37,
          comments: 1,
        },
      ];
    };

    const loadMore = () => {
      const start = (currentPage.value - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      const newItems = items.value.slice(start, end);
      if (newItems.length === 0) {
        hasMore.value = false;
        ElMessage.info('没有更多数据了');
        return;
      }
      displayedItems.value.push(...newItems);
      currentPage.value++;
    };

    onMounted(() => {
      loadData();
      loadMore();
    });

    return {
      displayedItems,
      loadMore,
      hasMore,
    };
  },
};
</script>

<style>
.edit_content{

}
.edit_plan_btn {
  width: 120px;
  height: 45px;
  background: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8e8e8e;
  border-radius: 8px;
  margin-bottom: 10px;
}
.dashed-line {
  display: flex;
  align-items: center;
  color: #777; /* 文本颜色 */
  font-size: 16px; /* 文本大小 */
  font-weight: bold;
  cursor: pointer;
  margin-top: 20px;
}

.dashed-line::before,
.dashed-line::after {
  content: '';
  flex: 1;
  border-bottom: 1px dashed #777; /* 虚线样式 */
  margin: 0 10px; /* 虚线和文字的间距 */
}
.stats_1 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats_2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.stats_3 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.img_edit {
  position: absolute;
  bottom: 3px;
  right: 3px;
  z-index: 10;
}

.unpublished .el-tag__content {
  color: #cc9f02;
}

.published .el-tag__content {
  color: #01a94f;
}
</style>
<style scoped>

.portfolio-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-start;
}

.create-new {
  width: calc(25% - 10px); /* 默认三列布局 */
  background-color: #1e1e1e;
  border-radius: 0px;
  overflow: hidden;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
}

.portfolio-item {
  width: calc(25% - 10px); /* 默认三列布局 */
  background-color: #1e1e1e;
  border-radius: 0px;
  overflow: hidden;
  color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.portfolio-thumbnail {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1; /* 保持正方形 */
  background-color: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.portfolio-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.portfolio-info {
  width: 100%;
  padding: 10px;
  background-color: #2e2e2e;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: #fff;
}

.portfolio-title {
  font-size: 13px;
  font-weight: normal;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-line-clamp: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.status-tag {
  margin-top: 5px;
  position: absolute;
  left: 4px;
  bottom: 4px;
  z-index: 10;
  background: #2e2e2e;
  border: 1px solid #b0afaf;
}

.portfolio-stats {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 10px;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.portfolio-stats .views {
  margin-right: 5px;
}

.portfolio-stats el-icon {
  margin: 0 5px;
}

.no-thumbnail {
  color: #aaa;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .create-new {
    width: calc(25% - 10px); /* 中等屏幕时变为两列 */
  }

  .portfolio-item {
    width: calc(25% - 10px); /* 中等屏幕时变为两列 */
  }
}

@media (max-width: 768px) {
  .portfolio-item {
    width: calc(33.33% - 10px); /* 小屏幕时变为一列 */
  }

  .create-new {
    width: calc(33.33% - 10px); /* 中等屏幕时变为两列 */
  }
}
</style>
