<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main style="padding: 0px !important;">
        <TimelineAnchor/>

      </el-main>
    </el-container>
    <BottomMenu/>
  </div>
</template>

<script>

import BottomMenu from "@/components/BottomMenu.vue";
import TimelineAnchor from "@/components/TimelineAnchor.vue";
import {getPostDetailRequest} from "@/api/api";
import LeftSidebar from "@/components/LeftSidebar.vue";
import { PostDetailStore } from "@/stores/PostDetailStore";
import { mapActions } from 'pinia';
export default {
  components: {
    LeftSidebar,
    TimelineAnchor,
    BottomMenu,

  },
  data() {
    return {
      //从路由参数中获取文章id
      post_id: this.$route.params.id,
      postDetail: {},
    };
  },

  mounted() {
    this.getData();
  },
  methods: {
    ...mapActions(PostDetailStore, ['setPostInfo']),
    getData() {
      getPostDetailRequest({id: this.post_id}).then(res => {
        this.postDetail=res.data;
        this.setPostInfo(res.data);
        console.log(res);
      });
    },
  }
};
</script>

<style>
body{
  margin: 0px !important;
}
</style>
<style scoped>
.img_container{
  max-width: 967px;
  margin: 30px auto;
}

@media (max-width: 767px) {
  .el-main {
    --el-main-padding: 0px !important;
    box-sizing: border-box;
    display: block;
    flex: 1;
    flex-basis: auto;
    overflow: auto;
    padding: 10px 0px !important;
  }
}


/* 左菜单 */
.left_side {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side_kong {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

.left_side_kong::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}
@media (max-width: 1160px) {

  .left_side {
    height: 100vh;
    width: 80px !important;
  }

  .left_side_kong {
    height: 100vh;
    width: 80px !important;
  }
}
@media (max-width: 767px) {
  .left_side {
    display: none;
  }

  .left_side_kong {
    display: none;
  }
}
.left_side {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
/* 左菜单结束 */
</style>
