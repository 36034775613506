<template>
  <div class="calendar-container">
    <div class="calendar-header">
      <div @click="changeMonth(-1)" class="calendar-nav-btn">
        <svg t="1732508630460" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2326" width="30" height="30"><path d="M56.889 512c0-251.36400001 203.747-455.111 455.111-455.111S967.111 260.636 967.111 512 763.364 967.111 512 967.111 56.889 763.364 56.889 512z m532.11 179l-179-179 179-179a28.444 28.444 0 0 0-40.22-40.221l-199.111 199.11a28.444 28.444 0 0 0 1e-8 40.221l199.10999999 199.111A28.444 28.444 0 0 0 589 691.001z" fill="" p-id="2327"></path></svg>
      </div>
      <div class="monthLabel">{{ monthLabel }}
        <div class="total-days">
          预计总天数：{{ totalDays }} 天
        </div>
      </div>
      <div @click="changeMonth(1)" class="calendar-nav-btn">
        <svg t="1732508696392" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2962" width="30" height="30"><path d="M967.111 512c0 251.364-203.747 455.111-455.111 455.111S56.889 763.364 56.889 512 260.636 56.889 512 56.889 967.111 260.636 967.111 512z m-532.11-179l179 179-179 179a28.444 28.444 0 0 0 40.22 40.221l199.111-199.11a28.444 28.444 0 0 0 0-40.221l-199.11-199.111A28.444 28.444 0 0 0 435 332.999z" fill="" p-id="2963"></path></svg>
      </div>
    </div>
    <!-- 显示预计总天数 -->

    <div class="calendar-body">
      <div v-for="(week, index) in calendarDates" :key="index" class="calendar-week">
        <div
            v-for="(date, index) in week"
            :key="index"
            :class="['calendar-date', isMarked(date) ? 'marked' : '']"
        >
          {{ date.split('-')[2] }} <!-- 只显示日期的"几号" -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    startDate: {
      type: String,
      required: true
    },
    uploadCount: {
      type: Number,
      required: true
    },
    intervalDays: {
      type: Number,
      required: true
    }
  },
  data() {
    const today = new Date(); // 获取当前日期
    const initialStartDate = this.startDate ? new Date(this.startDate) : today;

    return {
      currentDate: new Date(this.startDate), // 当前月份的起始时间，用于控制月份显示
      markedDates: [], // 标记的所有日期
      calendarDates: [], // 当前月份的日历数据
      monthLabel: this.formatMonthLabel(initialStartDate), // 当前月份标签
      totalDays: 0, // 预计总天数
    };

  },
  watch: {
    startDate: {
      immediate: true,
      handler(newStartDate) {
        this.currentDate = new Date(newStartDate); // 更新当前显示月份
        this.generateMarkedDates(); // 更新标记日期
        this.generateCalendarDates(); // 更新日历视图
      }
    },
    uploadCount: {
      immediate: true,
      handler() {
        this.generateMarkedDates();
      }
    },
    intervalDays: {
      immediate: true,
      handler() {
        this.generateMarkedDates();
      }
    }
  },
  created() {
    this.generateMarkedDates();
    this.generateCalendarDates();
    const today = new Date(); // 获取当前日期
    const initialStartDate = this.startDate ? new Date(this.startDate) : today;
    this.monthLabel = this.formatMonthLabel(initialStartDate), // 当前月份标签
    console.log(this.monthLabel);
  },
  methods: {

    formatMonthLabel(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      return `${year}年${month}月`;
    },
    generateMarkedDates() {
      if (this.startDate && this.uploadCount && this.intervalDays) {
        const dates = [];
        let currentDate = new Date(this.startDate); // 确保从传入的开始时间计算
        for (let i = 0; i < this.uploadCount; i++) {
          dates.push(currentDate.toISOString().split('T')[0]); // 格式化日期
          currentDate.setDate(currentDate.getDate() + this.intervalDays);
        }
        this.markedDates = dates;

        // 更新总天数
        this.totalDays = this.intervalDays * (this.uploadCount - 1) + 1;
      }
    },
    generateCalendarDates() {
      const currentYear = this.currentDate.getFullYear();
      const currentMonth = this.currentDate.getMonth();
      const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);
      const daysInMonth = lastDayOfMonth.getDate();

      this.monthLabel = this.formatMonthLabel(this.currentDate);

      // 生成按周分组的日期数组
      const weeks = [];
      let week = [];
      for (let day = 1; day <= daysInMonth; day++) {
        const dateStr = `${currentYear}-${String(currentMonth + 1).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
        week.push(dateStr);

        // 每周结束或是最后一天
        if (week.length === 7 || day === daysInMonth) {
          weeks.push(week);
          week = [];
        }
      }
      this.calendarDates = weeks;
    },
    changeMonth(offset) {
      this.currentDate.setMonth(this.currentDate.getMonth() + offset);
      this.generateCalendarDates();
    },
    isMarked(date) {
      return this.markedDates.includes(date); // 检查标记的日期
    }
  }
};

</script>

<style scoped>
.calendar-container {
  font-family: 'Arial', sans-serif;
  width: 100%;
  max-width: 320px;
  background-color: var(--time-component-container);
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background-color: var(--time-component-header);
  font-size: 16px;
  font-weight: 600;
  color: #333;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.calendar-nav-btn {
  font-size: 18px;
  background-color: transparent;
  border: none;
  color: #333;
  cursor: pointer;
  transition: color 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
}
.calendar-nav-btn svg{
  fill: var(--btn-time-component);
}

.calendar-nav-btn:hover {
  color: #007BFF;
}

.total-days {
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  color: var(--text-color);
  height: 29px;
  margin-top: 0px;
  line-height: 35px;
}
.monthLabel {
  display: flex
;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.calendar-body {
  padding: 12px 5px;
}

.calendar-week {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 8px;
  gap: calc(14.285714285714% - 32px);
  margin-left: calc(7.143% - 11px);
}

.calendar-date {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  background-color: var(--time-component-header);
  border-radius: 50%;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.calendar-date.marked {
  background-color: #FF6347;
  color: white;
}

.calendar-date:hover {
  background-color: #a12803;
}
</style>
