<template>
  <div class="common-layout theme_bg">
    <el-container>
      <LeftSidebar/>
      <el-main>
        <div class="profile-card">
          <div class="ccc_w">
            <div class="ccc">
              <div class="avatar-section">
                <el-image class="avatar-container" :src="user_info.avatar">
                </el-image>
              </div>
              <div class="ccc_inner">
                <div class="info-section">
                  <div class="info_header">
                    <div class="username">
                      <span>{{ user_info.name }}</span>


                      <svg aria-label="选项" class="phone_show_only" fill="currentColor" height="24"
                           role="img" viewBox="0 0 24 24" width="24"><title>选项</title>
                        <circle cx="12" cy="12" fill="none" r="8.635" stroke="currentColor" stroke-linecap="round"
                                stroke-linejoin="round" stroke-width="2"></circle>
                        <path
                            d="M14.232 3.656a1.269 1.269 0 0 1-.796-.66L12.93 2h-1.86l-.505.996a1.269 1.269 0 0 1-.796.66m-.001 16.688a1.269 1.269 0 0 1 .796.66l.505.996h1.862l.505-.996a1.269 1.269 0 0 1 .796-.66M3.656 9.768a1.269 1.269 0 0 1-.66.796L2 11.07v1.862l.996.505a1.269 1.269 0 0 1 .66.796m16.688-.001a1.269 1.269 0 0 1 .66-.796L22 12.93v-1.86l-.996-.505a1.269 1.269 0 0 1-.66-.796M7.678 4.522a1.269 1.269 0 0 1-1.03.096l-1.06-.348L4.27 5.587l.348 1.062a1.269 1.269 0 0 1-.096 1.03m11.8 11.799a1.269 1.269 0 0 1 1.03-.096l1.06.348 1.318-1.317-.348-1.062a1.269 1.269 0 0 1 .096-1.03m-14.956.001a1.269 1.269 0 0 1 .096 1.03l-.348 1.06 1.317 1.318 1.062-.348a1.269 1.269 0 0 1 1.03.096m11.799-11.8a1.269 1.269 0 0 1-.096-1.03l.348-1.06-1.317-1.318-1.062.348a1.269 1.269 0 0 1-1.03-.096"
                            fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path>
                      </svg>
                    </div>
                    <div class="actions">

                    </div>
                  </div>
                  <div class="stats">
                    <div class="stat-item">
                      <span class="stat-number">{{ user_info.followers_count }}</span>
                      <span class="stat-label">帖子</span>
                    </div>
                    <div class="stat-item">
                      <span class="stat-number">{{user_info.followers_count}}</span>
                      <span class="stat-label">粉丝</span>
                    </div>
                    <div class="stat-item">
                      <span class="stat-number">{{user_info.following_count}}</span>
                      <span class="stat-label">关注</span>
                    </div>
                  </div>
                </div>
              </div>

            </div>
            <div class="jj_text_pc">
              {{user_info.desc?user_info.desc:'这个人很懒，什么都没留下'}}
            </div>

            <div class="jj_text_mobile">
              {{user_info.desc?user_info.desc:'这个人很懒，什么都没留下'}}
            </div>

          </div>
        </div>

        <UserGalleryContent :user_name="user_name"/>

      </el-main>
    </el-container>

    <!--    底部菜单组件-->
    <BottomMenu/>
  </div>
</template>

<script>
import UserGalleryContent from "@/components/UserGalleryContent.vue";
import BottomMenu from "@/components/BottomMenu.vue";
import { userInfoRequest} from "@/api/api";
import LeftSidebar from "@/components/LeftSidebar.vue";

export default {
  name: 'UserProfile',
  components: {
    LeftSidebar,
    BottomMenu,
    UserGalleryContent,
  },
  data() {
    return {
      //从路由参数中获取文章id
      user_name: this.$route.params.username,
      user_info: {},
      avatarUrl: '', // 替换为实际头像 URL
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      userInfoRequest({user_name:this.user_name}).then(response => {
        if (response.code === 200) {
          console.log(response)
          this.user_info=response.data;
          // this.username = response.data.username;
          // this.avatarUrl = response.data.avatarUrl;
        }

      }).catch(error => {
        console.log(error)
      });
    },
    goToUserProfileSetUp() {
      this.$router.push({name: 'UserProfileSetUp'});
    },
    goToPhoneEditProfile() {
      this.$router.push({name: 'PhoneEditProfile'});
    },
  }
};
</script>

<style scoped>
.avatar-container {
  width: 150px;
  height: 150px;
  border-radius: 50%;
}
.avatar-section {
  width: 150px;
  height: 150px;
}

.ccc {
  display: flex;
  flex-direction: row;
}

.ccc_w {
  display: flex;
  flex-direction: column;
}

.avatar_img {
  width: 150px;
  height: 150px;
}

.info_header {
  display: flex;
  flex-direction: row;
}

.actions {
  margin-left: 30px;
}

.jj_text_pc {
  width: 312px;
  font-size: 13px;
  margin-top: -50px;
  margin-left: 205px;
}

.jj_text_mobile {
  display: none;
}

@media (max-width: 1200px) {
  .avatar-section {
    margin-right: 30px !important;
  }

  .actions {
    margin-left: 20px !important;
  }
}

@media (max-width: 992px) {
  .jj_text_pc {
    margin-left: 173px !important;
  }

  .avatar-section {
    margin-right: 20px !important;
  }

  .actions {
    margin-left: 10px !important;
  }
}

@media (max-width: 768px) {
  .el-main {
    padding: 20px 0px !important;
  }

  .actions {
    gap: 3px !important;
  }

  .stat-item {
    flex-direction: column !important;
  }

  .avatar-section {
    width: 77px;
    height: 77px;
  }

  .stats {
    margin-top: 0px !important;
  }

  .jj_text_mobile {
    display: block;
    text-align: left;
    width: 70%;
    margin-top: 20px;
    font-size: 13px;
  }

  .bt_left {
    margin-left: 0px !important;
  }

  .actions {
    margin-left: 0px !important;
    display: flex !important;
    flex-direction: row !important;
    align-items: flex-start !important;
    margin: 10px 0px;
  }

  .ccc {
    display: flex;
    flex-direction: row;
  }

  .avatar_img {
    width: 77px;
    height: 77px;
  }


  .avatar-section {
    margin-right: 22px !important;
  }

  .info_header {
    display: flex;
    flex-direction: column;
  }

  .jj_text_pc {
    display: none;
  }

  .jj_text_mobile {
    display: block;
  }
}

@media (max-width: 600px) {
  .jj_text_mobile {
    display: block;
    text-align: left;
    width: 100% !important;
  }
}


/* 左菜单 */
.left_side {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side_kong {
  height: 100vh;
  width: 215px;
  scrollbar-width: none;
}

.left_side::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

.left_side_kong::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

@media (max-width: 1160px) {

  .left_side {
    height: 100vh;
    width: 80px !important;
  }

  .left_side_kong {
    height: 100vh;
    width: 80px !important;
  }
}

@media (max-width: 767px) {
  .left_side {
    display: none;
  }

  .left_side_kong {
    display: none;
  }
}

.left_side {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}

/* 左菜单结束 */


.profile-card {
  display: flex;
  flex-direction: row;
  padding: 20px;
  max-width: 935px;
  margin: auto;
  background-color: var(--background-color);
  border-radius: 12px;
  gap: 20px;
}

.avatar-section {
  flex: 0 0 auto;
  margin-right: 55px;
}


.info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.username {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 1.1rem;
  margin-bottom: 10px;
}

.stats {
  display: flex;
  gap: 20px;
  margin-top: 10px;
}

.stat-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.stat-number {
  font-weight: bold;
  font-size: 1rem;
}

.stat-label {
  font-weight: 500;
  font-size: 1.0rem;
  margin-left: 4px;
}

.actions {
  display: flex;
  gap: 10px;
}

@media (max-width: 768px) {
  .profile-card {
    flex-direction: column;
    text-align: center;
  }

  .stats {
    justify-content: space-around;
    width: 100%;
  }
}
</style>