<template>
  <div class="signup-container theme_bg">
    <div class="signup-box">
      <h1 class="logo">Realtime.Art</h1>
      <p class="subtitle">注册 RealTime.Art，分享创意</p>
      <el-form :model="form" :rules="rules" ref="signupForm" @submit.prevent="onSubmit" class="signup-form">
        <el-form-item prop="username">
          <el-input v-model="form.username" placeholder="账号" @focus="handleFocus('username')" @blur="handleBlur('username')" :class="{ active: activeInput === 'username' }" />
        </el-form-item>
        <el-form-item prop="email">
          <el-input v-model="form.email" placeholder="邮箱" @focus="handleFocus('email')" @blur="handleBlur('email')" :class="{ active: activeInput === 'email' }" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input v-model="form.password" placeholder="密码" type="password" @focus="handleFocus('password')" @blur="handleBlur('password')" :class="{ active: activeInput === 'password' }" />
        </el-form-item>
        <el-form-item prop="config_password">
          <el-input v-model="form.config_password" placeholder="确认密码" type="password" @focus="handleFocus('config_password')" @blur="handleBlur('config_password')" :class="{ active: activeInput === 'config_password' }" />
        </el-form-item>
        <el-form-item prop="fullName">
          <el-input v-model="form.fullName" placeholder="昵称" @focus="handleFocus('fullName')" @blur="handleBlur('fullName')" :class="{ active: activeInput === 'fullName' }" />
        </el-form-item>
        <el-form-item prop="invite_code">
          <el-input v-model="form.invite_code" placeholder="邀请码" @focus="handleFocus('invite_code')" @blur="handleBlur('invite_code')" :class="{ active: activeInput === 'invite_code' }" />
        </el-form-item>
        <el-button type="primary" size="large" class="signup-button" @click="onSubmit">注册</el-button>
      </el-form>
      <div class="login-link" @click="goToLoginPage">有账号了？<div style="color: #3897f0">请登录</div></div>
    </div>
    <div class="download-app">
      <ResponsiveFooter/>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage } from 'element-plus';
import ResponsiveFooter from "@/components/ResponsiveFooter.vue";
import {postRequest} from "@/api/api";
export default {
  name: 'InstagramSignupClone',
  components: {
    ResponsiveFooter,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        config_password: '',
        fullName: '',
        username: '',
        invite_code: '',
      },
      activeInput: null,
      rules: {
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            validator: (rule, value, callback) => {
              // const phoneRegex = /^[0-9]{7,}$/;
              const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (value.includes('@')) {
                if (!emailRegex.test(value)) {
                  callback(new Error('请输入正确的邮箱地址'));
                } else {
                  callback();
                }
              } else {
                callback(new Error('请输入正确的邮箱地址'));
                // if (!phoneRegex.test(value)) {
                //   callback(new Error('你的电话号码似乎不正确，请输入包括国家/地区代码的完整号码。'));
                // } else {
                //   callback();
                // }
              }
            },
            trigger: ['blur', 'change'],
          },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { min: 6, message: '密码长度不少于 6 个字符', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,}$/,
            message: '密码必须包含字母、数字和特殊字符',
            trigger: 'blur',
          },
        ],
        config_password: [
          { required: true, message: '请输入确认密码', trigger: 'blur' },
          { min: 6, message: '密码长度不少于 6 个字符', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*]).{6,}$/,
            message: '密码必须包含字母、数字和特殊字符',
            trigger: 'blur',
          },
        ],
        fullName: [
          { required: true, message: '请输入昵称昵称', trigger: 'blur' },
        ],
        username: [
          { required: true, message: '请输入账号', trigger: 'blur' },
          { min: 4, message: '账号长度不能少于 4 个字符', trigger: 'blur' },
        ],
        invite_code: [
          { required: true, message: '请输入邀请码', trigger: 'blur' },
        ],
      },
    };
  },
  methods: {
    goToLoginPage() {
      this.$router.push({ name: 'LoginPage' });
    },
    handleFocus(input) {
      this.activeInput = input;
    },
    handleBlur(input) {
      if (this.activeInput === input) {
        this.activeInput = null;
      }
    },
    onSubmit() {
      this.$refs.signupForm.validate((valid) => {
        if (valid) {
          postRequest("/auth/register", this.form)
              .then(result=>{
                console.log(result);
                if(result.code===200){
                  this.$router.push({ name: 'LoginPage' });
                  ElMessage({
                    message: '注册成功',
                    type: 'success',
                  });
                }else{
                  ElMessage({
                    message: result.message,
                    type: 'error',
                  });
                }
          }).catch(error=>{
            console.log(error);
            ElMessage({
              message: '注册失败',
              type: 'error',
            });
          });

        } else {
          console.log('Validation Failed');
          ElMessage({
            message: '请完成所有必填项并确保输入正确',
            type: 'error',
          });
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.download-app {
  margin-top: 20px;
  text-align: center;
  position: fixed;
  bottom: 50px;
}
.signup-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.signup-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  text-align: center;
}

.logo {
  font-family: 'Lobster', cursive;
  font-size: 36px;
  margin-bottom: 10px;
}

.subtitle {
  font-size: 16px;
  margin-bottom: 20px;
}

.signup-form {
  width: 100%;
}

.signup-button {
  width: 100%;
  background-color: #3897f0;
  color: #fff;
  margin-top: 10px;
}

.login-link {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}



.app-links img {
  width: 120px;
  margin: 10px;
}

.el-input.active::placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  color: #bbb;
}
</style>
