<template>
  <el-drawer
      title="通知"
      :model-value="visible"
      custom-class="recommend-drawer"
      direction="ltr"
      :show-close="false"
      size="350px"
      @close="handleClose"
      append-to-body
      :z-index="1001"
      :modal="true"
      :modal-append-to-body="true"
  >
    <div class="drawer-content " @click.stop>
      <div class="notification">
        <svg
            class="icon-heart"
            fill="currentColor"
            height="50"
            role="img"
            viewBox="0 0 48 48"
            width="50"
        >
          <title>帖子动态</title>
          <path
              d="M34.6 3.1c-4.5 0-7.9 1.8-10.6 5.6-2.7-3.7-6.1-5.5-10.6-5.5C6 3.1 0 9.6 0 17.6c0 7.3 5.4 12 10.6 16.5.6.5 1.3 1.1 1.9 1.7l2.3 2c4.4 3.9 6.6 5.9 7.6 6.5.5.3 1.1.5 1.6.5s1.1-.2 1.6-.5c1-.6 2.8-2.2 7.8-6.8l2-1.8c.7-.6 1.3-1.2 2-1.7C42.7 29.6 48 25 48 17.6c0-8-6-14.5-13.4-14.5z"
          ></path>
        </svg>
        <p>帖子动态</p>
        <p>获得点赞或评论的帖子会显示在这里。</p>
      </div>
      <div class="recommendations">
        <h3>你的通知</h3>
        <div class="notification-container">
          <div v-for="(notification, index) in notifications" :key="index" class="notification-item" @click="navigateTo(notification)">
            <div class="notification-header">
              <el-avatar :src="notification.sender.avatar" size="mini"></el-avatar>
              <span class="notification-username">{{ notification.sender.name }}</span>
              <span class="notification-time">{{ parseTime(notification.created_at) }}</span>
            </div>
            <div class="notification-content">
              <p v-if="notification.type === 'follow'">{{ notification.sender.name }} 关注了你</p>
              <p v-if="notification.type === 'like_post'">{{ notification.sender.name }} 点赞了你的作品</p>
              <p v-if="notification.type === 'like_comment'">{{ notification.sender.name }} 点赞了你的评论</p>
              <p v-if="notification.type === 'reply_comment'">{{ notification.sender.name }} 回复了你的评论</p>
              <p v-if="notification.type === 'message'">{{ notification.sender.name }} 给你发送了消息</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import {noticeRequest} from "@/api/api";

export default {
  name: "NoticeDrawer",
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:visible"],
  data() {
    return {
      notifications: [
      ],
    };
  },
  mounted() {
    this.getData()
  },
  methods: {
    parseTime(data_time){
      let post_time = new Date(data_time);
      let now_time = new Date();
      let time = now_time.getTime() - post_time.getTime();
      let hours = time / 1000 / 60 / 60;
      if (hours < 24) {
        post_time =Math.floor(hours) + "小时前";
      } else {
        post_time = Math.floor(hours / 24) + "天前";
      }
      return post_time;
    },
    getData(){
      noticeRequest({}).then(res=>{
        console.log(res)
        if(res.code===200){
          this.notifications=res.data;
        }
      })
    },
    // 点击通知跳转
    navigateTo(notification) {
      if(notification.type==='follow'){
        //关注信息跳转到用户主页
        this.$router.push(`/user/${notification.sender.user_name}`);
      }else if(notification.type==='like_post'){
        //点赞帖子，跳转到作品详情
        this.$router.push(`/post/${notification.reference_id}`);
      }else if(notification.type==='like_comment'){
        //点赞评论，跳转到作品详情
        this.$router.push(`/post/${notification.post}`);
      }else if(notification.type==='reply_comment'){
        //回复评论，跳转到作品详情
        this.$router.push(`/post/${notification.post}`);
      }else if(notification.type==='message'){
        //收到消息，跳转到消息页面。并激活该用户的聊天
        this.$router.push(`/Messagepage`);
      }
    },
    handleClose() {
      this.$emit("update:visible", false); // 通知父组件更新 `visible` 状态
    },
  },
};
</script>

<style>
@media (max-width: 767px) {
  .recommend-drawer {
    display: none;
  }
}
.recommend-drawer {
  padding: 0;
  border-left: var(--left-sidebar-border-right);
  left: 60px !important;
  box-shadow: var(--el-drawer-box-shadow) !important;
}
.el-overlay {
  background-color: transparent !important;
}

.drawer-content {
  padding: 20px;
}

.notification {
  text-align: center;
  margin-bottom: 20px;
}

.icon-heart {
  color: #888;
}

.recommendations {
  border-top: 1px solid #e0e0e0;
  padding-top: 10px;
}

.notification-container {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.notification-item {
  display: flex;
  flex-direction: column;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: box-shadow 0.3s;
}

.notification-item:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
}

.notification-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.notification-username {
  font-weight: bold;
}

.notification-time {
  font-size: 12px;
  color: #888;
}

.notification-content p {
  margin: 0;
  font-size: 14px;
}

.el-avatar {
  border-radius: 50%;
}
</style>
