<template>
  <el-aside class="left_side">
    <el-menu
        class="left-sidebar leftsidebar"
        :collapse="isCollapsed"
        collapse-transition="false"
        background-color="`var(--menu-background-color)`"
        text-color="#000000"
        active-text-color="#ff0000"
    >
      <el-menu-item class="item_style" index="1">
        <template #title>
          <div class="phone_logo logo_svg">
            <svg width="60" class="ml_10" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg"
                 viewBox="0 0 1499.81 1499.81">
              <defs></defs>
              <path class="cls-1" d="M350.96,532.43h134.98v59.99h-75.74v150.73h-59.24v-210.72Z"/>
              <path class="cls-1"
                    d="M1163.27,773.15h75.74c7.5,7.75,23.17,23.96,23.17,23.96,0,0-33.67,33.78-50.17,50.28h24c5.75,6,11.5,12,17.25,18v15.75h-75.74c-7.75-8.25-15.5-16.5-23.25-24.75,16.5-16.25,50.27-49.52,50.27-49.52,0,0-17.78.03-26.27.03-5-5.25-10-10.5-15-15.75v-18Z"/>
              <path class="cls-1"
                    d="M1145.11,908.14h-33.75v-51.74c-12.25-12.25-24.5-24.5-36.75-36.75l24-24c9.75,9.5,19.5,19,29.25,28.5,9.75-9.5,19.5-19,29.25-28.5,8,7.75,16,15.5,24,23.25l-36,36v53.24Z"/>
              <path class="cls-1"
                    d="M980.13,796.4c8,7.75,16,15.5,24,23.25-7.75,8-15.5,16-23.25,24-8-7.75-16-15.5-24-23.25,7.75-8,15.5-16,23.25-24Z"/>
              <path class="cls-1"
                    d="M501.69,532.43h133.48v59.99h-74.24v15h74.24v59.99h-74.24v15.75h74.24v59.99h-133.48v-210.72Z"/>
              <path class="cls-1"
                    d="M785.9,532.43v210.72h-59.24c-.25-19.75-.5-39.5-.75-59.24-9,21.75-18,43.5-27,65.24-18.25-7.5-36.5-15-54.74-22.5,27.49-64.74,55-129.49,82.49-194.23h59.24Z"/>
              <path class="cls-1" d="M801.65,532.43h59.24v210.72h-59.24v-210.72Z"/>
              <path class="cls-1" d="M876.64,683.16h133.48v59.99h-133.48v-59.99Z"/>
              <path class="cls-1" d="M277.47,757.41h133.48v59.99h-133.48v-59.99Z"/>
              <path class="cls-1" d="M425.95,757.41h59.24v59.99h-59.24v-59.99Z"/>
              <path class="cls-1"
                    d="M800.9,757.41h134.98v210.72h-134.98v-59.99h75.74v-14.25h-75.74v-59.99h75.74v-16.5h-75.74v-59.99Z"/>
              <path class="cls-1"
                    d="M575.18,758.16h210.72v210.72h-59.24v-151.48h-15.75v151.48h-59.24v-151.48h-17.25v151.48h-59.24v-210.72Z"/>
              <path class="cls-1"
                    d="M1062.38,820.05c10,9.75,31.74,30.34,31.74,30.34l-23.58,24.27s-20.67-21.03-30.42-31.02c-10.25,10-20.5,20-30.75,30-7.75-7.75-15.5-15.5-23.25-23.25,10-10,20-20,30-30-9.75-9.75-19.5-19.5-29.25-29.25v-2.25c7.5-7.5,15-15,22.5-22.5,10,10,20,20,30,30,10-10,20-20,30-30,8,7.75,16,15.5,24,23.25l-30.99,30.4Z"/>
              <path class="cls-1" d="M500.94,833.9h59.99v133.48h-59.99v-133.48Z"/>
              <path class="cls-1" d="M350.21,834.65h59.99v133.48h-59.99v-133.48Z"/>
            </svg>

          </div>
          <div class="index_logo logo_svg">
            <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1499.81 1499.81">
              <defs></defs>
              <path class="cls-1" d="M350.96,532.43h134.98v59.99h-75.74v150.73h-59.24v-210.72Z"/>
              <path class="cls-1"
                    d="M1163.27,773.15h75.74c7.5,7.75,23.17,23.96,23.17,23.96,0,0-33.67,33.78-50.17,50.28h24c5.75,6,11.5,12,17.25,18v15.75h-75.74c-7.75-8.25-15.5-16.5-23.25-24.75,16.5-16.25,50.27-49.52,50.27-49.52,0,0-17.78.03-26.27.03-5-5.25-10-10.5-15-15.75v-18Z"/>
              <path class="cls-1"
                    d="M1145.11,908.14h-33.75v-51.74c-12.25-12.25-24.5-24.5-36.75-36.75l24-24c9.75,9.5,19.5,19,29.25,28.5,9.75-9.5,19.5-19,29.25-28.5,8,7.75,16,15.5,24,23.25l-36,36v53.24Z"/>
              <path class="cls-1"
                    d="M980.13,796.4c8,7.75,16,15.5,24,23.25-7.75,8-15.5,16-23.25,24-8-7.75-16-15.5-24-23.25,7.75-8,15.5-16,23.25-24Z"/>
              <path class="cls-1"
                    d="M501.69,532.43h133.48v59.99h-74.24v15h74.24v59.99h-74.24v15.75h74.24v59.99h-133.48v-210.72Z"/>
              <path class="cls-1"
                    d="M785.9,532.43v210.72h-59.24c-.25-19.75-.5-39.5-.75-59.24-9,21.75-18,43.5-27,65.24-18.25-7.5-36.5-15-54.74-22.5,27.49-64.74,55-129.49,82.49-194.23h59.24Z"/>
              <path class="cls-1" d="M801.65,532.43h59.24v210.72h-59.24v-210.72Z"/>
              <path class="cls-1" d="M876.64,683.16h133.48v59.99h-133.48v-59.99Z"/>
              <path class="cls-1" d="M277.47,757.41h133.48v59.99h-133.48v-59.99Z"/>
              <path class="cls-1" d="M425.95,757.41h59.24v59.99h-59.24v-59.99Z"/>
              <path class="cls-1"
                    d="M800.9,757.41h134.98v210.72h-134.98v-59.99h75.74v-14.25h-75.74v-59.99h75.74v-16.5h-75.74v-59.99Z"/>
              <path class="cls-1"
                    d="M575.18,758.16h210.72v210.72h-59.24v-151.48h-15.75v151.48h-59.24v-151.48h-17.25v151.48h-59.24v-210.72Z"/>
              <path class="cls-1"
                    d="M1062.38,820.05c10,9.75,31.74,30.34,31.74,30.34l-23.58,24.27s-20.67-21.03-30.42-31.02c-10.25,10-20.5,20-30.75,30-7.75-7.75-15.5-15.5-23.25-23.25,10-10,20-20,30-30-9.75-9.75-19.5-19.5-29.25-29.25v-2.25c7.5-7.5,15-15,22.5-22.5,10,10,20,20,30,30,10-10,20-20,30-30,8,7.75,16,15.5,24,23.25l-30.99,30.4Z"/>
              <path class="cls-1" d="M500.94,833.9h59.99v133.48h-59.99v-133.48Z"/>
              <path class="cls-1" d="M350.21,834.65h59.99v133.48h-59.99v-133.48Z"/>
            </svg>


          </div>
        </template>
      </el-menu-item>

      <el-menu-item class="menu_hover" index="1" @click="goToHomePage">
        <template #title>
          <el-icon class="logo_svg">
            <svg aria-label="首页" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                 viewBox="0 0 24 24" width="24"><title>首页</title>
              <path
                  d="M9.005 16.545a2.997 2.997 0 0 1 2.997-2.997A2.997 2.997 0 0 1 15 16.545V22h7V11.543L12 2 2 11.543V22h7.005Z"
                  fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2"></path>
            </svg>
          </el-icon>
          <span class="menu-text">首页</span>
        </template>
      </el-menu-item>
      <el-menu-item class="menu_hover" index="2" @click="toggleSearchDrawer">
        <SearchDrawer class="recommend-drawer theme_bg" :modal="true" v-model:visible="SearchDrawerVisible"
                      @close="handleSearchDrawerClose"/>
        <template #title>
          <el-icon class="logo_svg">
            <svg aria-label="搜索" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                 viewBox="0 0 24 24" width="24"><title>搜索</title>
              <path d="M19 10.5A8.5 8.5 0 1 1 10.5 2a8.5 8.5 0 0 1 8.5 8.5Z" fill="none" stroke="currentColor"
                    stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
              <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    x1="16.511" x2="22" y1="16.511" y2="22"></line>
            </svg>
          </el-icon>
          <span class="menu-text">搜索</span>
        </template>
      </el-menu-item>
      <el-menu-item class="menu_hover" index="3" @click="goToExplorePage">
        <template #title>
          <el-icon class="logo_svg">
            <svg aria-label="探索" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                 viewBox="0 0 24 24" width="24"><title>探索</title>
              <polygon fill="none" points="13.941 13.953 7.581 16.424 10.06 10.056 16.42 7.585 13.941 13.953"
                       stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon>
              <polygon fill-rule="evenodd" points="10.06 10.056 13.949 13.945 7.581 16.424 10.06 10.056"></polygon>
              <circle cx="12.001" cy="12.005" fill="none" r="10.5" stroke="currentColor" stroke-linecap="round"
                      stroke-linejoin="round" stroke-width="2"></circle>
            </svg>
          </el-icon>
          <span class="menu-text">探索</span>
        </template>
      </el-menu-item>
      <!--    <el-menu-item class="menu_hover" index="4" @click="goToSignUp">
            <template #title>
              <el-icon class="logo_svg">
                <svg aria-label="Reels" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                     viewBox="0 0 24 24" width="24"><title>Reels</title>
                  <line fill="none" stroke="currentColor" stroke-linejoin="round" stroke-width="2" x1="2.049" x2="21.95"
                        y1="7.002" y2="7.002"></line>
                  <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        x1="13.504" x2="16.362" y1="2.001" y2="7.002"></line>
                  <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        x1="7.207" x2="10.002" y1="2.11" y2="7.002"></line>
                  <path
                      d="M2 12.001v3.449c0 2.849.698 4.006 1.606 4.945.94.908 2.098 1.607 4.946 1.607h6.896c2.848 0 4.006-.699 4.946-1.607.908-.939 1.606-2.096 1.606-4.945V8.552c0-2.848-.698-4.006-1.606-4.945C19.454 2.699 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.546 2 5.704 2 8.552Z"
                      fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                      stroke-width="2"></path>
                  <path
                      d="M9.763 17.664a.908.908 0 0 1-.454-.787V11.63a.909.909 0 0 1 1.364-.788l4.545 2.624a.909.909 0 0 1 0 1.575l-4.545 2.624a.91.91 0 0 1-.91 0Z"
                      fill-rule="evenodd"></path>
                </svg>
              </el-icon>
              <span class="menu-text">SignUp</span>
            </template>
          </el-menu-item>-->
      <el-menu-item class="menu_hover" index="5" @click="goToMessagePage">
        <template #title>
          <el-icon class="logo_svg">
            <svg aria-label="Messenger" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                 viewBox="0 0 24 24" width="24"><title>Messenger</title>
              <path
                  d="M12.003 2.001a9.705 9.705 0 1 1 0 19.4 10.876 10.876 0 0 1-2.895-.384.798.798 0 0 0-.533.04l-1.984.876a.801.801 0 0 1-1.123-.708l-.054-1.78a.806.806 0 0 0-.27-.569 9.49 9.49 0 0 1-3.14-7.175 9.65 9.65 0 0 1 10-9.7Z"
                  fill="none" stroke="currentColor" stroke-miterlimit="10" stroke-width="1.739"></path>
              <path
                  d="M17.79 10.132a.659.659 0 0 0-.962-.873l-2.556 2.05a.63.63 0 0 1-.758.002L11.06 9.47a1.576 1.576 0 0 0-2.277.42l-2.567 3.98a.659.659 0 0 0 .961.875l2.556-2.049a.63.63 0 0 1 .759-.002l2.452 1.84a1.576 1.576 0 0 0 2.278-.42Z"
                  fill-rule="evenodd"></path>
            </svg>
          </el-icon>
          <span class="menu-text">消息</span>
        </template>
      </el-menu-item>
      <el-menu-item class="menu_hover" index="6" @click="toggleNoticeDrawer">
        <NoticeDrawer class="recommend-drawer theme_bg" :modal="true" v-model:visible="drawerVisible"
                      @close="handleDrawerClose"/>
        <template #title>
          <el-icon class="logo_svg">
            <svg aria-label="通知" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                 viewBox="0 0 24 24" width="24"><title>通知</title>
              <path
                  d="M16.792 3.904A4.989 4.989 0 0 1 21.5 9.122c0 3.072-2.652 4.959-5.197 7.222-2.512 2.243-3.865 3.469-4.303 3.752-.477-.309-2.143-1.823-4.303-3.752C5.141 14.072 2.5 12.167 2.5 9.122a4.989 4.989 0 0 1 4.708-5.218 4.21 4.21 0 0 1 3.675 1.941c.84 1.175.98 1.763 1.12 1.763s.278-.588 1.11-1.766a4.17 4.17 0 0 1 3.679-1.938m0-2a6.04 6.04 0 0 0-4.797 2.127 6.052 6.052 0 0 0-4.787-2.127A6.985 6.985 0 0 0 .5 9.122c0 3.61 2.55 5.827 5.015 7.97.283.246.569.494.853.747l1.027.918a44.998 44.998 0 0 0 3.518 3.018 2 2 0 0 0 2.174 0 45.263 45.263 0 0 0 3.626-3.115l.922-.824c.293-.26.59-.519.885-.774 2.334-2.025 4.98-4.32 4.98-7.94a6.985 6.985 0 0 0-6.708-7.218Z"></path>
            </svg>
          </el-icon>
          <span class="menu-text">通知</span>
        </template>
      </el-menu-item>

      <el-menu-item class="menu_hover" index="7" @click="goUpload">

        <template #title>
          <el-icon class="logo_svg">
            <svg aria-label="新帖子" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="24" role="img"
                 viewBox="0 0 24 24" width="24"><title>新帖子</title>
              <path
                  d="M2 12v3.45c0 2.849.698 4.005 1.606 4.944.94.909 2.098 1.608 4.946 1.608h6.896c2.848 0 4.006-.7 4.946-1.608C21.302 19.455 22 18.3 22 15.45V8.552c0-2.849-.698-4.006-1.606-4.945C19.454 2.7 18.296 2 15.448 2H8.552c-2.848 0-4.006.699-4.946 1.607C2.698 4.547 2 5.703 2 8.552Z"
                  fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                  stroke-width="2"></path>
              <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    x1="6.545" x2="17.455" y1="12.001" y2="12.001"></line>
              <line fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    x1="12.003" x2="12.003" y1="6.545" y2="17.455"></line>
            </svg>
          </el-icon>
          <span class="menu-text">上传</span>
        </template>
      </el-menu-item>
      <!--    <el-menu-item class="menu_hover" index="8" @click="goToTimeLine">
            <template #title>
              <el-icon class="logo_svg">
                <user/>
              </el-icon>
              <span class="menu-text">时间轴</span>
            </template>
          </el-menu-item>-->
      <el-menu-item class="menu_hover" index="8" @click="goToUserProfile">
        <template #title>
          <el-icon class="logo_svg">
            <user/>
          </el-icon>
          <span class="menu-text">主页</span>
        </template>
      </el-menu-item>


      <el-popover
          placement="top-start"
          title=""
          :width="250"
          trigger="hover"
      >
        <div class="more_list">
          <div class="more_list_item">
            <div class="logo_svg more_svg">
              <svg aria-label="主题图标" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="18" role="img"
                   viewBox="0 0 24 24" width="18"><title>主题图标</title>
                <path
                    d="M11.502,22.99805A11.4313,11.4313,0,0,1,.49512,14.83691a.99889.99889,0,0,1,.251-.998,1.01148,1.01148,0,0,1,.99707-.249,9.43041,9.43041,0,0,0,2.75879.40821A9.5082,9.5082,0,0,0,13.5957,1.74023a1.00039,1.00039,0,0,1,1.24707-1.248A11.501,11.501,0,0,1,11.502,22.99805ZM3.08984,15.91211A9.49991,9.49991,0,0,0,21.002,11.498,9.57875,9.57875,0,0,0,15.916,3.08594,11.5083,11.5083,0,0,1,3.08984,15.91211Z"></path>
              </svg>
            </div>
            <ThemeSwitcher/>
          </div>
          <div class="more_list_item" @click="goCollect">
            <div class="logo_svg more_svg">
              <svg aria-label="收藏夹" class="x1lliihq x1n2onr6 x5n08af" fill="currentColor" height="18" role="img"
                   viewBox="0 0 24 24" width="18"><title>收藏夹</title>
                <polygon fill="none" points="20 21 12 13.44 4 21 4 3 20 3 20 21" stroke="currentColor"
                         stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></polygon>
              </svg>
            </div>
            收藏夹
          </div>
          <div class="more_list_item" @click="logout">
            <div class="logo_svg more_svg">
              <svg t="1730162228810" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg"
                   p-id="9985" width="18" height="18">
                <path
                    d="M822.751 209.777c-13.447-13.447-35.242-13.447-48.707 0-13.465 13.483-13.465 35.277 0 48.725 144.48 144.497 144.48 379.592 0 524.09-144.443 144.461-379.574 144.461-524.089 0-144.479-144.498-144.479-379.593 0-524.09 13.466-13.447 13.466-35.241 0-48.725-13.465-13.447-35.258-13.447-48.706 0C118.232 292.794 72.496 403.144 72.496 520.528S118.232 748.298 201.249 831.279c85.659 85.715 198.195 128.535 310.75 128.535s225.09-42.82 310.752-128.535c83.018-82.98 128.752-193.367 128.752-310.751S905.769 292.794 822.751 209.777zM511.999 675.958c19.041 0 34.457-15.452 34.457-34.476L546.456 98.625c0-19.023-15.416-34.438-34.457-34.438S477.542 79.602 477.542 98.625l0 542.857C477.542 660.506 492.958 675.958 511.999 675.958z"
                    fill="" p-id="9986"></path>
              </svg>
            </div>
            退出
          </div>

        </div>


        <template #reference v-if="token">
          <el-menu-item class="menu_bottom menu_hover" index="9">
            <template #title>
              <el-icon class="logo_svg">
                <Operation/>
              </el-icon>
              <span class="menu-text">更多</span>
            </template>
          </el-menu-item>
        </template>
      </el-popover>


      <el-menu-item class="menu_bottom menu_hover" @click="goLogin" index="9" v-if="!token">
        <template #title>
          <el-icon class="logo_svg">
            <svg t="1731672963237" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
                 p-id="2412" width="32" height="32">
              <path
                  d="M511.338 421.875l-221.281 238.02 64.44 60 110.79-119.221v336.521h92.16v-336.47l110.795 119.17 64.432-60z"
                  fill="#ffffff" p-id="2413"></path>
              <path
                  d="M882.262 86.803H141.739C63.463 86.803 0.007 150.259 0.007 228.535v566.929c0 78.276 63.456 141.732 141.732 141.732h157.663v-80H141.739c-34.039 0-61.732-27.693-61.732-61.732V228.536c0-34.04 27.693-61.732 61.732-61.732h740.522c34.04 0 61.732 27.693 61.732 61.732v566.929c0 34.039-27.692 61.732-61.732 61.732H724.6v80h157.662c78.276 0 141.732-63.456 141.732-141.732V228.536c0-78.277-63.456-141.733-141.732-141.733z"
                  fill="#ffffff" p-id="2414"></path>
            </svg>
          </el-icon>
          <span class="menu-text">登录</span>
        </template>
      </el-menu-item>
    </el-menu>
  </el-aside>
  <el-aside class="left_side_kong"></el-aside>
</template>

<script>
import NoticeDrawer from '../components/NoticeDrawer.vue';
import ThemeSwitcher from "@/components/ThemeSwitcher.vue";
import SearchDrawer from "@/components/SearchDrawer.vue";
import {logoutRequest} from "@/api/api";
import {ElMessageBox} from "element-plus";
import {modalStore} from "@/stores/modalStore";
import {StatusStore} from "@/stores/StatusStore";
import { mapActions } from 'pinia';
export default {
  name: "SidebarMenu",
  computed: {
  },
  components: {
    SearchDrawer,
    ThemeSwitcher,
    NoticeDrawer,
  },
  data() {
    return {
      token: localStorage.getItem('token'),
      isCollapsed: false,
      drawerVisible: false,
      SearchDrawerVisible: false,
      createPostVisible: false,
      planModalVisible: false,
    };
  },
  methods: {
    ...mapActions(StatusStore, ['setUserIsCollect','getUserIsCollect']),//引入store中的事件方便全局调用
    handlePlanCreated(data) {
      console.log(data)
      this.planModalVisible = false
      // this.createPostVisible=true;
    },
    checkLogin() {
      let token = localStorage.getItem('token')
      console.log(token)
      if (!token||token === "") {
        this.$message({
          message: '请先登录',
          type: 'warning',
        });
        this.$router.push({name: 'LoginPage'});
        return false;//TODO 需要调试的时候改为ture就不会校验登录
      }
      return true;
    },
    goLogin(){
      this.$router.push({name: 'LoginPage'});
    },
    goUpload() {
      if (this.checkLogin()){
        const PlanModalStore = modalStore();
        PlanModalStore.showPlanMenuModal();
      }
    },
    goCollect(){
      this.setUserIsCollect(true)
      this.$router.push({name: 'UserProfile'});
    },
    goToTimeLine() {
      if (this.checkLogin())
        this.$router.push({name: 'TimeLine'});
    },
    goToMessagePage() {
      if (this.checkLogin())
        this.$router.push({name: 'MessagePage'});
    },
    goToSignUp() {
      // if(this.checkLogin())
      this.$router.push({name: 'LoginPage'});
    },
    logout() {
      ElMessageBox.confirm('确认要退出登录?',{
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        customClass: 'my_custom_message_box',
      }).then(() => {
            logoutRequest().then(res => {
              console.log(res)
              if (res.code === 200) {
                this.$message({
                  message: '退出成功',
                  type: 'success',
                });
                localStorage.setItem('token', '');
                localStorage.setItem('user_info', '');
                this.$router.push({name: 'LoginPage'});
              }
            })
          })
          .catch(() => {
            // catch error
          })
    },
    goToUserProfile() {
      if (this.checkLogin())
        this.$router.push({name: 'UserProfile'});
    },
    goToHomePage() {
      this.$router.push({name: 'HomePage'});
    },
    goToExplorePage() {
      this.$router.push({name: 'ExplorePage'});
    },
    updateMenuCollapse() {
      this.isCollapsed = window.innerWidth <= 767;
    },
    toggleSearchDrawer() {

      this.SearchDrawerVisible = !this.SearchDrawerVisible;
      const sidebar = document.querySelector('.left-sidebar');
      if (this.SearchDrawerVisible) {
        sidebar.classList.add('collapsed');
      } else {
        sidebar.classList.remove('collapsed');
      }
    },
    handleSearchDrawerClose() {
      const sidebar = document.querySelector('.left-sidebar');
      sidebar.classList.remove('collapsed');
    },
    toggleNoticeDrawer() {
      console.log(this.checkLogin())
      if (this.checkLogin()){
        this.drawerVisible = !this.drawerVisible;
        const sidebar = document.querySelector('.left-sidebar');
        if (this.drawerVisible) {
          sidebar.classList.add('collapsed');
        } else {
          sidebar.classList.remove('collapsed');
        }
      }

    },
    handleDrawerClose() {
      const sidebar = document.querySelector('.left-sidebar');
      sidebar.classList.remove('collapsed');
    },
  },
  watch: {
    visible(newVal) {
      const sidebar = document.querySelector('.left-sidebar');
      if (newVal) {
        sidebar.classList.add('collapsed');
      } else {
        sidebar.classList.remove('collapsed');
      }
    },
  },
  mounted() {
    this.updateMenuCollapse();
    window.addEventListener('resize', this.updateMenuCollapse);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.updateMenuCollapse);
  },
};
</script>

<style>
.el-menu-item{
  color: var(--text-color) !important;
}
.my_custom_message_box .el-button{
  background-color: #363636;
  color: #fff;
  border: none;
}
.my_custom_message_box .el-button:hover {
  background-color: #2a2929; /* 鼠标悬停时的背景色 */
}
.my_custom_message_box .el-button--primary:hover {
  background-color: #317bc5; /* 鼠标悬停时的背景色 */
}
.my_custom_message_box .el-button--primary{
  background-color: #409EFF; /* 主要按钮背景色 */
}
</style>

<style scoped>


.ml_10 {
  margin-left: -10px;
}

.cls-1 {
  fill: var(--text-color);
  fill-rule: evenodd;
}

.more_svg svg {
  fill: var(--text-color);
}

.el-icon svg {
  height: 1.4em;
  width: 1.4em;
}

span.menu-text {
  font-size: 1.2em;
}

.el-popper__arrow, .el-popper__arrow:before {
  height: 0px !important;
  position: absolute;
  width: 0px !important;
  z-index: -1;
  border: none !important;
}

.el-popper.is-light > .el-popper__arrow:before {
  background: #262626;
  border: 1px solid #262626;
}

.more_list_item {
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.more_svg {
  margin-right: 5px;
}

.el-popper__arrow {
  display: none !important;
}

.logo_svg {
  color: var(--icon-color) !important;
}

.recommend-drawer {
  padding: 0;
  border-left: 1px solid #e0e0e0;
  left: 60px !important;
  box-shadow: none;
}

/* 动画样式 */
.left-sidebar {
  transition: width 0.3s ease, height 0.3s ease;
}

.collapsed .menu-text {
  display: none;
}

.left-sidebar.collapsed {
  width: 60px;
  border-right: var(--left-sidebar-border-right);
}

.left-sidebar.collapsed .phone_logo {
  display: block;
}

.left-sidebar.collapsed .index_logo {
  display: none;
}

/* 动画样式 */

.menu_hover:hover {
  background: var(--menu-background-color-hover);
}

.left-sidebar {
  position: relative;
  height: 100vh;
  width: 200px;
  border-right: none !important;
}

.menu_bottom {
  position: absolute;
  bottom: 40px;
  width: 100%;
}

.item_style {
  margin-top: 14px;
  margin-bottom: 26px;
}

.item_style:hover {
  background: var(--background-color);
}

.index_logo {
  display: block;
  width: 100px;
}

.index_logo img {
  width: 100%;
}

.menu-text {
  margin-left: 10px;
}

.phone_logo {
  display: none;
}

@media (max-width: 1160px) {
  .phone_logo {
    display: block;
  }

  .index_logo {
    display: none;
  }

  .left-sidebar {
    height: 100vh;
    width: 80px;
    border-right: var(--left-sidebar-border-right);
  }

  .menu-text {
    display: none;
  }
}

@media (max-width: 768px) {
  .left-sidebar {
    display: none;
  }

  .recommend-drawer {
    display: none;
  }
}
</style>
<style scoped>
.left_side {
  height: 100vh;
  width: 200px !important;
  scrollbar-width: none;
  border-right: var(--left-sidebar-border-right);
}

.left_side_kong {
  height: 100vh;
  width: 200px;
  scrollbar-width: none;
}

.left_side::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}

.left_side_kong::-webkit-scrollbar {
  display: none; /* 针对 Chrome 和 Safari 浏览器 */
}
@media (max-width: 1160px) {
  .left_side {
    height: 100vh;
    width: 80px !important;
  }

  .left_side_kong {
    height: 100vh;
    width: 80px !important;
  }
}
@media (max-width: 768px) {
  .left_side {
    display: none;
  }

  .left_side_kong {
    display: none;
  }
}
.left_side {
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 1000;
}
</style>
