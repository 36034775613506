<template>
  <div class="advanced-timeline">
    <div class="timeline-container">
      <div class="timeline" id="picker">
        <!--  时间轴部分-开始      -->
        <div
            v-for="(upload, index) in filledUploads"
            :key="index"
            class="timeline-item"
        >
          <el-tooltip :content="upload.date" placement="right">
            <div
                :class="[
                    'timeline-dot',
                    getStatusClass(upload.status),
                    {
                      'active': currentUpload && currentUpload.date === upload.date,
                      'no-content': !(upload.images && upload.images.length > 0)
                    }
                  ]"
                @click="(upload.status !== 'filler' && upload.images && upload.images.length > 0) ? loadContent(upload) : null"
            >
              <svg v-if="upload.status=='missed'" t="1730885423632" class="icon missed_icon" viewBox="0 0 1024 1024"
                   version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="7477" width="32" height="32">
                <path
                    d="M512 0.003C229.231 0.003 0 229.232 0 512.002S229.231 1024 512 1024s512-229.229 512-511.998S794.769 0.003 512 0.003z m0 921.598c-226.218 0-409.597-183.384-409.597-409.599S285.782 102.403 512 102.403s409.597 183.384 409.597 409.599S738.218 921.601 512 921.601z"
                    fill="#f60707" p-id="7478"></path>
                <path d="M349.935 226.917l449.217 449.217L684.236 791.05 235.02 341.833z" fill="#f60707"
                      p-id="7479"></path>
                <path d="M235.012 676.141l449.217-449.217L799.145 341.84 349.928 791.057z" fill="#f60707"
                      p-id="7480"></path>
              </svg>
            </div>
          </el-tooltip>
          <!-- 保留 active-line，用于显示当前选中的时间点 -->
          <div class="timeline-content">
            <div
                v-if="currentUpload && currentUpload.date === upload.date"
                class="active-line"
            ></div>
          </div>
        </div>
        <!--  时间轴部分-结束      -->
      </div>
      <div class="content-display">
        <div class="art_img_content" v-if="currentUpload">
          <div class="art_all_left">
            <div class="pre_item_btn"  @click="prevPlan">
              <svg t="1731034015207" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="14646" width="48" height="48">
                <path
                    d="M620.74 260.842l36.027 36.027-216.303 216.301 216.303 216.301-36.027 36.026-252.329-252.329 252.329-252.329z"
                    p-id="14647" fill="#ffffff"></path>
              </svg>
            </div>
            <div class="next_item_btn"  @click="nextPlan">
              <svg t="1731034112258" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="15175" id="mx_n_1731034112259" width="48" height="48">
                <path
                    d="M403.26 763.158l-36.027-36.027 216.303-216.301-216.303-216.301 36.027-36.026 252.329 252.329-252.329 252.329z"
                    p-id="15176" fill="#ffffff"></path>
              </svg>
            </div>
            <button class="pre_btn_style" @click="prevNode" v-if="canGoToPrevUpload"
                    :style="{ opacity: preBtnOpacity }">
              <svg t="1730881073372" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="4904" width="32" height="32">
                <path
                    d="M865 733.6l-332.8-270c-11-8.9-26.7-8.9-37.7 0L167.2 728.1c-12.9 10.4-14.9 29.3-4.5 42.2 10.4 12.9 29.3 14.9 42.2 4.5l308.3-249.2 313.9 254.7c5.6 4.5 12.2 6.7 18.9 6.7 8.7 0 17.4-3.8 23.3-11.1 10.5-12.9 8.5-31.8-4.3-42.3z"
                    fill="#409eff" p-id="4905"></path>
                <path
                    d="M204.9 504.8l308.3-249.2 313.9 254.7c5.6 4.5 12.2 6.7 18.9 6.7 8.7 0 17.4-3.8 23.3-11.1 10.4-12.9 8.5-31.8-4.4-42.2l-332.8-270c-11-8.9-26.7-8.9-37.7 0L167.2 458.1c-12.9 10.4-14.9 29.3-4.5 42.2 10.5 12.9 29.3 14.8 42.2 4.5z"
                    fill="#409eff" p-id="4906"></path>
              </svg>
            </button>

            <div class="timeline_img_content" ref="timelineImgContent" @wheel="handleWheel">
              <template v-for="(media, idx) in postDetail.medias">
                <el-image v-if="!isVideo(media.media_url)" class="art_img_item"
                          :key="'image-' + idx"
                          :src="'http://bbs-oss.realtime.xyz/'+media.media_url"
                          style="width: 100%; height: auto;"
                ></el-image>
                <video v-else class="art_img_item" controls
                       :key="'video-' + idx"
                       :src="'http://bbs-oss.realtime.xyz/'+media.media_url"
                       style="width: 100%; height: auto;"
                ></video>
              </template>
            </div>

            <button class="next_btn_style" @click="nextNode" v-if="canGoToNextUpload"
                    :style="{ opacity: nextBtnOpacity }">
              <svg t="1730881146329" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="6286" id="mx_n_1730881146330" width="32" height="32">
                <path
                    d="M517 543.9c-7.1 0-14.2-2.4-20.1-7.1L143 249.7c-13.7-11.1-15.8-31.2-4.7-44.9 11.1-13.7 31.2-15.8 44.9-4.7L517 471l327.9-265c13.7-11.1 33.8-9 44.9 4.8 11.1 13.7 8.9 33.8-4.8 44.9L537 536.8c-5.8 4.7-12.9 7.1-20 7.1z"
                    fill="#409eff" p-id="6287"></path>
                <path
                    d="M517 831c-7.1 0-14.2-2.4-20.1-7.1L143 536.8c-13.7-11.1-15.8-31.2-4.7-44.9s31.2-15.7 44.9-4.7L517 758.1l327.9-265c13.7-11 33.8-9 44.9 4.8 11.1 13.7 8.9 33.8-4.8 44.9L537 823.9c-5.8 4.7-12.9 7.1-20 7.1z"
                    fill="#409eff" p-id="6288"></path>
              </svg>
            </button>
          </div>
          <div class="art_all_right">
            <div class="close_timeline" @click="goBack()">
              <svg t="1731028520876" class="icon" viewBox="0 0 1024 1024" version="1.1"
                   xmlns="http://www.w3.org/2000/svg" p-id="9753" width="32" height="32">
                <path
                    d="M940.8 83.2c25.6 25.6 25.6 64 0 83.2L595.2 512l345.6 345.6c25.6 25.6 25.6 64 0 83.2-25.6 25.6-64 25.6-83.2 0L512 595.2l-345.6 345.6c-25.6 25.6-64 25.6-83.2 0-25.6-25.6-25.6-64 0-83.2L428.8 512 83.2 166.4c-25.6-25.6-25.6-64 0-83.2s64-25.6 83.2 0L512 428.8l345.6-345.6C876.8 57.6 921.6 57.6 940.8 83.2z"
                    fill="" p-id="9754"></path>
              </svg>
            </div>
            <InstagramComment :postId="post_id"/>
          </div>
        </div>
        <div v-else>
          <p>没有内容可显示</p>
        </div>

      </div>


    </div>

  </div>
</template>

<script>
import {authors} from '../data/authors.js';
import {ElMessage, ElTooltip} from 'element-plus';
import InstagramComment from "@/components/InstagramComment.vue";
import {useRouter} from 'vue-router';
import {PostDetailStore} from "@/stores/PostDetailStore";
import {mapActions} from 'pinia';
import {getPostDetailRequest} from "@/api/api";

export default {
  setup() {
    const router = useRouter();

    function goBack() {
      router.go(-1);
    }

    return {goBack};
  },
  components: {
    InstagramComment,
    ElTooltip,
    // 如果有其他组件，需要在这里添加
  },
  data() {
    return {
      preBtnOpacity: 0.1, // 初始透明度为 10%
      nextBtnOpacity: 0.1, // 初始透明度为 10%
      uploads: [],
      currentUpload: null, // 当前显示的上传内容
      dimension: '1', // 默认维度为作品
      post_id: this.$route.params.id,
      postDetail: {},
    };
  },
  mounted() {

    // 初始化选择第一个有图片内容的上传
    if (this.filledUploads.length > 0) {
      this.currentUpload =
          this.filledUploads.find(
              (upload) => upload.images && upload.images.length > 0
          ) || null;
    }
    this.getData();

  },

  computed: {
    filledUploads() {
      const filled = [];
      const uploads = this.filteredUploads;

      for (let i = 0; i < uploads.length; i++) {
        const currentUpload = uploads[i];
        filled.push(currentUpload);

        if (i < uploads.length - 1) {
          const nextUpload = uploads[i + 1];
          const daysBetween = this.calculateDaysBetween(
              currentUpload.date,
              nextUpload.date
          );
          if (daysBetween > 1) {
            for (let j = 1; j < daysBetween; j++) {
              filled.push({
                date: this.getDateString(
                    new Date(currentUpload.date).setDate(
                        new Date(currentUpload.date).getDate() + j
                    )
                ),
                status: 'filler', // 用于标记填充点
                images: [],
                feedbacks: [],
              });
            }
          }
        }
      }
      return filled;
    },
    filteredUploads() {
      const authorA = authors.find((author) => author.name === '作者A');
      if (authorA) {
        const artwork = authorA.artworks.find((art) => art.id === 'A1'); // 只展示作品 A1
        if (artwork) {
          const uploads = artwork.versions.flatMap((version) => {
            return version.uploads.map((upload) => ({
              ...upload,
              uploadPlan: version.uploadPlan, // 将 uploadPlan 信息附加到上传记录中
            }));
          });
          // 按日期排序
          uploads.sort((a, b) => new Date(a.date) - new Date(b.date));
          return uploads;
        }
      }
      return [];
    },
    canGoToPrevUpload() {
      if (!this.currentUpload) return false;
      const currentIndex = this.filledUploads.findIndex(
          (upload) => upload.date === this.currentUpload.date
      );
      for (let i = currentIndex - 1; i >= 0; i--) {
        const upload = this.filledUploads[i];
        if (upload.images && upload.images.length > 0) {
          return true; // 有可用的上一个时间点
        }
      }
      return false; // 没有可用的上一个时间点
    },
    canGoToNextUpload() {
      if (!this.currentUpload) return false;
      const currentIndex = this.filledUploads.findIndex(
          (upload) => upload.date === this.currentUpload.date
      );
      for (let i = currentIndex + 1; i < this.filledUploads.length; i++) {
        const upload = this.filledUploads[i];
        if (upload.images && upload.images.length > 0) {
          return true; // 有可用的下一个时间点
        }
      }
      return false; // 没有可用的下一个时间点
    },
  },
  methods: {
    ...mapActions(PostDetailStore, ['setPostInfo']),
    isVideo(url) {
      const videoExtensions = ['mp4', 'webm', 'ogg'];
      const extension = url.split('.').pop().toLowerCase();
      return videoExtensions.includes(extension);
    },
    getData() {
      getPostDetailRequest({id: this.post_id}).then(res => {
        this.postDetail = res.data;
        this.setPostInfo(res.data);
        console.log(res);
      });
    },

    handleWheel(event) {
      const container = this.$refs.timelineImgContent;
      const scrollTop = container.scrollTop;
      const scrollHeight = container.scrollHeight;
      const clientHeight = container.clientHeight;
      const deltaY = event.deltaY;

      // 当滚动到顶部并继续向上滚动
      if (scrollTop === 0 && deltaY < 0) {
        this.preBtnOpacity = 1;
      } else {
        this.preBtnOpacity = 0.1;
      }

      // 当滚动到底部并继续向下滚动
      if (scrollTop + clientHeight >= scrollHeight && deltaY > 0) {
        this.nextBtnOpacity = 1;
      } else {
        this.nextBtnOpacity = 0.1;
      }
    },
    getStatusClass(status) {
      if (status === 'planed') return 'planed';
      if (status === 'uploaded') return 'uploaded';
      if (status === 'late') return 'late';
      return status === 'filler' ? 'filler' : 'missed';
    },
    loadContent(upload) {
      if (upload.status !== 'filler' && upload.images && upload.images.length > 0) {
        this.currentUpload = upload; // 设置当前上传内容
      }
    },
    handleDimensionChange(index) {
      this.dimension = index; // 更新当前维度
    },
    calculateDaysBetween(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = end - start;
      return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // 转换为天数
    },
    getDateString(date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${(d.getMonth() + 1)
          .toString()
          .padStart(2, '0')}-${d.getDate().toString().padStart(2, '0')}`;
    },
    //上一个计划
    prevPlan() {
      ElMessage({
        message: '暂无内容可以切换',
        type: 'error',
      });
    },
    //上一个计划
    nextPlan() {
      ElMessage({
        message: '暂无内容可以切换',
        type: 'error',
      });
    },
    //上一个节点
    prevNode() {
      if(this.preBtnOpacity === 1){
        //透明度变成100%才算可以点击
        ElMessage({
          message: '没有更多版本了',
          type: 'error',
        });
      }

      // if (!this.currentUpload) return;
      // const currentIndex = this.filledUploads.findIndex(
      //     (upload) => upload.date === this.currentUpload.date
      // );
      // for (let i = currentIndex - 1; i >= 0; i--) {
      //   const upload = this.filledUploads[i];
      //   if (upload.images && upload.images.length > 0) {
      //     this.currentUpload = upload;
      //     return;
      //   }
      // }
    },
    nextNode() {
      if(this.nextBtnOpacity === 1){
        //透明度变成100%才算可以点击
        ElMessage({
          message: '没有更多版本了',
          type: 'error',
        });
      }

      // if (!this.currentUpload) return;
      // const currentIndex = this.filledUploads.findIndex(
      //     (upload) => upload.date === this.currentUpload.date
      // );
      // for (let i = currentIndex + 1; i < this.filledUploads.length; i++) {
      //   const upload = this.filledUploads[i];
      //   if (upload.images && upload.images.length > 0) {
      //     this.currentUpload = upload;
      //     return;
      //   }
      // }
    },
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .art_all_left {
    width: 100% !important;
  }

  .art_all_right {
    width: 100% !important;
  }

  .art_img_content {
    width: 100% !important;
    height: auto !important;
    display: flex;
    justify-content: center !important;
    flex-direction: column !important;
  }

  .next_btn_style {
    bottom: 55px !important;
  }

  .pre_btn_style {
    top: 50px !important;
  }
}

.missed_icon {
  width: 15px;
  height: 15px;
}

.art_img_item {
  margin-bottom: 15px;
  overflow: visible !important;
}

.art_all_left {
  width: calc(100% - 450px);
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  justify-content: center;
}

.timeline_img_content {
  width: 100%;
  //height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-top: 15px;
}

.pre_item_btn {
  position: absolute;
  left: -15px;
  top: calc(50vh - 30px);
  z-index: 10;
}

.pre_item_btn svg {
}

.next_item_btn svg {
}

.next_item_btn {
  position: absolute;
  right: -15px;
  top: calc(50vh - 30px);
  z-index: 10;
}

.art_all_right {
  width: 450px;
  position: relative;
}

.close_timeline {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 10;
}

@media (max-width: 768px) {
  .close_timeline {
    position: fixed;
    right: 5px;
    top: 27px;
  }
}

.close_timeline svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

.art_img_content {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.advanced-timeline {
  padding: 0px;
}

.timeline-container {
  display: flex; /* 使时间点和内容区域左右排列 */
}

.timeline {
  margin-left: 0px;
  width: 30px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}

.timeline-item {
  display: flex;
  align-items: center;
  flex-grow: 1; /* 让节点在容器内均匀分布 */
}

.timeline-item {
  min-height: 10px; /* 设置最小高度 */
  max-height: 15px; /* 设置最大高度 */
}

.timeline-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  margin-right: 10px;
  transition: background-color 0.3s;
  cursor: pointer;
}

.timeline-dot.no-content {
  opacity: 0.5;
  cursor: not-allowed;
  width: 3px;
  height: 3px;
}

.timeline-dot.uploaded {
  background-color: #3d44ad; /* Green */
}

.timeline-dot.late {
  background-color: #a37a00; /* Orange */
}

.timeline-dot.planed {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--text-color);
}

.timeline-dot.missed {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-dot.filler {
  background-color: #B0BEC5; /* Grey */
}

.timeline-dot.active {
  border: 2px solid #f904ea; /* Green border for active */
}

.timeline-content {
  display: flex;
  flex-direction: column;
}

.active-line {
  width: 100%; /* 横线的宽度 */
  height: 2px; /* 横线的高度 */
  background-color: #4CAF50; /* 横线的颜色 */
  margin-top: 5px; /* 横线与时间戳之间的间距 */
}

.content-display {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  width: calc(100% - 30px);
  overflow-y: scroll;
  position: relative;
}

.pre_btn_style {
  position: absolute;
  z-index: 10;
  top: 0px;
  background: #363636;
  border: none;
  height: 41px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.next_btn_style {
  position: absolute;
  z-index: 10;
  bottom: 0px;
  background: #363636;
  border: none;
  height: 41px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 768px) {
  .next_btn_style {
    position: absolute;
    z-index: 0;
  }
}

.content-container {
  margin-top: 20px;
  padding: 10px;
  border: 1px solid #ccc;
}

button {
  margin: 10px; /* 按钮间距 */
  padding: 10px; /* 按钮内边距 */
}
</style>
