<template>
  <div class="advanced-timeline">
    <!-- 中线 -->
    <div class="timeline_mid_line" ref="midLine"></div>

    <!-- 可滚动的时间轴容器 -->
    <div
        class="timeline-container-line"
        ref="scrollContainer"
        @wheel="handleWheel"
    >

      <div style="width:40px;height: 50vh;"></div>
      <div class="timeline">
        <!-- 遍历填充后的上传点 -->
        <div
            v-for="(upload, index) in filledUploads"
            :key="index"
            class="timeline-item"
            ref="timelineDots"
        >
          <!-- 悬浮提示 -->
          <el-tooltip :content="upload.date" placement="right">
            <div
                :class="[
                'timeline-dot',
                getStatusClass(upload.status),
                {
                  active:
                    currentUpload && currentUpload.date === upload.date,
                  'no-content':
                    !(upload.images && upload.images.length > 0),
                },
              ]"
                @click="handleClick(upload)"
            >
              <!-- 根据状态显示图标 -->
              <svg
                  v-if="upload.status === 'missed'"
                  t="1730885423632"
                  class="icon missed_icon"
                  viewBox="0 0 1024 1024"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  p-id="7477"
                  width="32"
                  height="32"
              >
                <path
                    d="M512 0.003C229.231 0.003 0 229.232 0 512.002S229.231 1024 512 1024s512-229.229 512-511.998S794.769 0.003 512 0.003z m0 921.598c-226.218 0-409.597-183.384-409.597-409.599S285.782 102.403 512 102.403s409.597 183.384 409.597 409.599S738.218 921.601 512 921.601z"
                    fill="#f60707"
                    p-id="7478"
                ></path>
                <path
                    d="M349.935 226.917l449.217 449.217L684.236 791.05 235.02 341.833z"
                    fill="#f60707"
                    p-id="7479"
                ></path>
                <path
                    d="M235.012 676.141l449.217-449.217L799.145 341.84 349.928 791.057z"
                    fill="#f60707"
                    p-id="7480"
                ></path>
              </svg>
            </div>
          </el-tooltip>

          <!-- 活动线条 -->
          <div class="timeline-content">
            <div
                v-if="currentUpload && currentUpload.date === upload.date"
                class="active-line"
            ></div>
          </div>
        </div>
      </div>

      <div style="width:40px;height: 50vh;"></div>
    </div>

    <!-- 底部内容 -->
    <div class="timeline-footer">
      <!-- 根据需求填充 -->
    </div>
  </div>
</template>

<script>
import { ElTooltip } from "element-plus";
import { authors } from "../data/authors.js";

export default {
  components: { ElTooltip },
  data() {
    return {
      uploads: [],
      currentUpload: null, // 当前显示的上传内容
      currentIndex: 0, // 当前选中的点的索引，基于 activeUploads
      isScrolling: false, // 标记是否正在滚动


      // 存储所有 timeline-item 的位置
      timelineItemPositions: [],
      // 是否已经到达第一个时间点
      atTop: false,
      // 是否已经到达最后一个时间点
      atBottom: false
    };
  },
  computed: {
    // 填充上传点，包括 'filler' 点
    filledUploads() {
      const filled = [];
      const uploads = this.filteredUploads;

      for (let i = 0; i < uploads.length; i++) {
        const currentUpload = uploads[i];
        filled.push(currentUpload);

        if (i < uploads.length - 1) {
          const nextUpload = uploads[i + 1];
          const daysBetween = this.calculateDaysBetween(
              currentUpload.date,
              nextUpload.date
          );
          if (daysBetween > 1) {
            for (let j = 1; j < daysBetween; j++) {
              const date = new Date(currentUpload.date);
              date.setDate(date.getDate() + j);
              filled.push({
                date: this.getDateString(date),
                status: "filler", // 用于标记填充点
                images: [],
                feedbacks: [],
              });
            }
          }
        }
      }
      return filled;
    },
    // 筛选出状态为 'active' 且有内容的上传点，并记录其在 filledUploads 中的索引
    activeUploads() {
      return this.filledUploads.reduce((acc, upload, index) => {
        if (upload.status !== "filler" && upload.images && upload.images.length > 0) {
          acc.push({ upload, filledIndex: index });
        }
        return acc;
      }, []);
    },
    // 过滤特定作者和作品的上传点
    filteredUploads() {
      const authorA = authors.find((author) => author.name === "作者A");
      if (authorA) {
        const artwork = authorA.artworks.find((art) => art.id === "A1"); // 只展示作品 A1
        if (artwork) {
          const uploads = artwork.versions.flatMap((version) => {
            return version.uploads.map((upload) => ({
              ...upload,
              uploadPlan: version.uploadPlan, // 将 uploadPlan 信息附加到上传记录中
            }));
          });
          // 按日期排序
          uploads.sort((a, b) => new Date(a.date) - new Date(b.date));
          return uploads;
        }
      }
      return [];
    },
  },
  mounted() {
    this.calculateTimelineItemPositions();

    this.$nextTick(() => {
      this.calculateInitialIndex();
    });
    window.addEventListener("resize", this.calculateInitialIndex);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.calculateInitialIndex);
  },
  methods: {
    // 计算所有 timeline-item 的位置
    calculateTimelineItemPositions() {
      this.$nextTick(() => {
        this.timelineItemPositions = [];
        const items = this.$refs.timelineItems; // 假设你使用了 ref 来获取所有 timeline-item
        if(items)
        items.forEach(item => {
          this.timelineItemPositions.push(item.offsetTop);
        });
      });
    },
// 监听滚动事件
    handleScroll(event) {
      const container = this.$refs.timelineContainer; // 假设你有一个容器引用
      const scrollTop = container.scrollTop;
      const containerHeight = container.clientHeight;

      // 获取第一个和最后一个 timeline-item 的位置
      const firstItemPosition = this.timelineItemPositions[0];
      const lastItemPosition = this.timelineItemPositions[this.timelineItemPositions.length - 1];

      // 判断是否已经到达最顶部
      if (scrollTop <= firstItemPosition) {
        this.atTop = true;
      } else {
        this.atTop = false;
      }

      // 判断是否已经到达最底部
      if (scrollTop + containerHeight >= lastItemPosition + this.$refs.timelineItems[this.timelineItemPositions.length - 1].offsetHeight) {
        this.atBottom = true;
      } else {
        this.atBottom = false;
      }

      // 如果已到达顶部或底部，则禁用进一步的滚动
      if (this.atTop && event.deltaY < 0) {
        event.preventDefault(); // 禁止向上滚动
      } else if (this.atBottom && event.deltaY > 0) {
        event.preventDefault(); // 禁止向下滚动
      }
    },

    // 加载并显示上传内容
    loadContent(upload) {
      if (
          upload.status !== "filler" &&
          upload.images &&
          upload.images.length > 0
      ) {
        this.currentUpload = upload; // 设置当前上传内容
        console.log("加载内容:", upload);
      }
    },
    // 计算两个日期之间的天数
    calculateDaysBetween(startDate, endDate) {
      const start = new Date(startDate);
      const end = new Date(endDate);
      const timeDiff = end - start;
      return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); // 转换为天数
    },
    // 将日期对象转换为字符串格式 YYYY-MM-DD
    getDateString(date) {
      const d = new Date(date);
      return `${d.getFullYear()}-${(d.getMonth() + 1)
          .toString()
          .padStart(2, "0")}-${d.getDate().toString().padStart(2, "0")}`;
    },
    // 根据状态返回对应的 CSS 类
    getStatusClass(status) {
      if (status === "planed") return "planed";
      if (status === "uploaded") return "uploaded";
      if (status === "late") return "late";
      return status === "filler" ? "filler" : "missed";
    },
    // 判断上传项是否包含内容
    hasContent(upload) {
      return upload.images && upload.images.length > 0; // 判断上传项是否包含内容
    },
    // 处理点击事件
    handleClick(upload) {
      if (upload.status !== "filler" && this.hasContent(upload)) {
        this.currentUpload = upload;
        this.$emit("loadContent", upload); // 触发父组件的事件
        console.log("当前选中的时间点:", upload);
        // 获取填充后的索引
        const activeUploadObj = this.activeUploads.find(obj => obj.upload.date === upload.date);
        if (activeUploadObj) {
          this.scrollToIndex(this.activeUploads.indexOf(activeUploadObj));
        }
      }
    },
    // 滚动到指定索引的上传点
    scrollToIndex(index) {
      const activeUploads = this.activeUploads;
      const uploadObj = activeUploads[index];
      if (uploadObj) {
        const dotIndex = uploadObj.filledIndex;
        if (dotIndex !== -1 && this.$refs.timelineDots && this.$refs.timelineDots[dotIndex]) {
          const dotWrapper = this.$refs.timelineDots[dotIndex];
          const container = this.$refs.scrollContainer;

          // 计算目标点的滚动距离
          const targetOffsetTop = dotWrapper.offsetTop;
          const containerHeight = container.clientHeight;
          const targetHeight = dotWrapper.clientHeight;
          const scrollDistance = targetOffsetTop - (containerHeight / 2) + (targetHeight / 2);

          // 执行滚动
          container.scrollTo({
            top: scrollDistance,
            behavior: 'smooth'
          });

          // 更新当前展示的上传内容
          this.currentUpload = uploadObj.upload;
          console.log("当前选中的时间点:", uploadObj.upload);
        } else {
          console.log("未找到对应的 timelineDots 元素");
        }
      }
    },
    // 处理鼠标滚轮事件
    handleWheel(event) {
      console.log("handleWheel 被调用，deltaY:", event.deltaY);
      if (this.isScrolling) {
        console.log("正在滚动，忽略此次事件");
        return;
      }

      this.isScrolling = true;

      // 获取当前选中的上传点和下一个标记点
      const currentUpload = this.currentUpload;
      const currentIndex = this.activeUploads.findIndex(uploadObj => uploadObj.upload.date === currentUpload.date);
      const nextUpload = this.activeUploads[currentIndex + 1];

      // 如果滚动方向是向下
      if (event.deltaY > 0 && nextUpload) {
        // 计算滚动到下一个点的距离
        this.scrollToNextPoint(currentIndex + 1);
      }
      // 如果滚动方向是向上
      else if (event.deltaY < 0 && currentIndex > 0) {
        // 计算滚动到上一个点的距离
        this.scrollToNextPoint(currentIndex - 1);
      }

      // 设置一个延迟，直到滚动完成
      setTimeout(() => {
        this.isScrolling = false;
        console.log("滚动完成，isScrolling 设置为 false");
      }, 500); // 延迟时间可根据需要调整
    },
    scrollToNextPoint(index) {
      const uploadObj = this.activeUploads[index];
      if (uploadObj) {
        const dotIndex = uploadObj.filledIndex;
        if (dotIndex !== -1 && this.$refs.timelineDots && this.$refs.timelineDots[dotIndex]) {
          const dotWrapper = this.$refs.timelineDots[dotIndex];
          const container = this.$refs.scrollContainer;

          // 获取当前时间点的偏移量
          const targetOffsetTop = dotWrapper.offsetTop;
          const containerHeight = container.clientHeight;
          const targetHeight = dotWrapper.clientHeight;
          const scrollDistance = targetOffsetTop - (containerHeight / 2) + (targetHeight / 2);

          // 滚动到目标点
          container.scrollTo({
            top: scrollDistance,
            behavior: 'smooth'
          });

          // 更新当前选中的时间点
          this.currentUpload = uploadObj.upload;
          console.log("当前选中的时间点:", uploadObj.upload);
        } else {
          console.log("未找到对应的 timelineDots 元素");
        }
      }
    },

    // 计算并初始化当前选中的上传点索引
    calculateInitialIndex() {
      // 找到初始时与中线最近的 activeUpload 点
      this.$nextTick(() => {
        const midLine = this.$refs.midLine.getBoundingClientRect().top + this.$refs.midLine.clientHeight / 2;
        console.log("midLine position:", midLine);
        let closestIndex = 0;
        let minDistance = Infinity;

        if(this.activeUploads)
        this.activeUploads.forEach((uploadObj, index) => {
          const dotIndex = uploadObj.filledIndex;
          if (dotIndex !== -1 && this.$refs.timelineDots && this.$refs.timelineDots[dotIndex]) {
            const dot = this.$refs.timelineDots[dotIndex].querySelector(".timeline-dot");
            if (dot) {
              const rect = dot.getBoundingClientRect();
              const distance = Math.abs(rect.top + rect.height / 2 - midLine);
              console.log(`上传点 ${uploadObj.upload.date} 距离中线的距离:`, distance);
              if (distance < minDistance) {
                minDistance = distance;
                closestIndex = index;
              }
            }
          }
        });

        this.currentIndex = closestIndex;
        if (this.activeUploads[this.currentIndex]) {
          this.currentUpload = this.activeUploads[this.currentIndex].upload;
          console.log("初始化当前选中的时间点:", this.currentUpload);
          this.scrollToIndex(this.currentIndex);
        } else {
          console.log("没有有效的 activeUploads 可供初始化");
        }
      });
    },
  },
};
</script>





<style scoped>
.advanced-timeline {
  height: 50vh;
  position: relative;
}
.timeline_mid_line{
  position: absolute;
  top: 50%;
  width: 100%;
  height: 3px;
  background-color: #505050;
  z-index: 10;
}
.timeline-container-line{
  height: 50vh;
  overflow-y: scroll;
}
.timeline {
  margin-left: 0px;
  width: 40px;
  height: 100vh; /* 固定高度为视口高度的 100% */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.timeline-item {
  display: flex;
  align-items: center;
  flex-grow: 1; /* 让节点在容器内均匀分布 */
}
.timeline-item {
  min-height: 10px; /* 设置最小高度 */
  max-height: 15px;/* 设置最大高度 */
}

.timeline-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  position: relative;
  transition: background-color 0.3s;
  cursor: pointer;
}

.timeline-dot.no-content {
  opacity: 0.5;
  cursor: not-allowed;
  width: 3px;
  height: 3px;
}

.timeline-dot.uploaded {
  background-color: #3d44ad; /* Green */
}

.timeline-dot.late {
  background-color: #a37a00; /* Orange */
}

.timeline-dot.planed {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 2px solid var(--text-color);
}

.timeline-dot.missed {
  width: 12px;
  height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline-dot.filler {
  background-color: #B0BEC5; /* Grey */
}

.timeline-dot.active {
  border: 2px solid #f904ea; /* Green border for active */
}

.timeline-content {
  display: flex;
  flex-direction: column;
}

.active-line {
  width: 100%; /* 横线的宽度 */
  height: 2px; /* 横线的高度 */
  background-color: #4CAF50; /* 横线的颜色 */
  margin-top: 5px; /* 横线与时间戳之间的间距 */
}

</style>
