<template>
  <div class="login-container theme_bg">
    <div class="login-box">
      <h1 class="logo">Realtime.Art</h1>
      <p class="message">请先验证您的邮箱,如未收到邮件请尝试重新发送验证码</p>
      <el-form :model="form" ref="formRef" class="verification-form">

        <el-form-item prop="code" :rules="codeRules">

              <el-input class="get-code-input" v-model="form.code" placeholder="请输入验证码" />

              <el-button
                  :disabled="isCounting"
                  @click="handleGetCode"
                  class="get-code-btn"
                  type="info"
              >
                {{ isCounting ? countdown + 's' : '再次发送' }}
              </el-button>

        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submit" :loading="loading" class="submit-btn">
            提交
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="download-app">
      <ResponsiveFooter/>
    </div>
  </div>
</template>

<script>
import { ElForm, ElFormItem, ElInput, ElButton, ElMessage} from 'element-plus';
import ResponsiveFooter from "@/components/ResponsiveFooter.vue";
import {reSendEmailRequest, verifyCodeRequest} from "@/api/api";


export default {
  name: 'EmailVerification',
  components: {
    ResponsiveFooter,
    ElForm,
    ElFormItem,
    ElInput,
    ElButton,

  },
  data() {
    return {
      form: {
        email: '',
        code: '',
      },
      loading: false,
      countdown: 0,
      isCounting: false,

      codeRules: [
        {required: true, message: '验证码不能为空', trigger: 'blur'},
        {len: 6, message: '验证码应该是6位', trigger: 'blur'},
      ],
    };
  },
  methods: {
    // 获取验证码
    async handleGetCode() {

      this.isCounting = true;
      this.countdown = 60;

      // 调用后端接口发送验证码
      try {
        reSendEmailRequest().then(response => {
          if (response.code === 200) {
            ElMessage.success('验证码已发送到您的邮箱,请注意查收');
          } else {
            ElMessage.error(response.message || '验证码发送失败');
            this.isCounting = false; // 失败时停止倒计时
          }
        }).catch(error => {
          console.error('发送验证码失败:', error);
          ElMessage.error('发送验证码失败，请稍后再试');
          this.isCounting = false; // 请求失败时停止倒计时
        });

      } catch (error) {
        console.error('发送验证码失败:', error);
        ElMessage.error('发送验证码失败，请稍后再试');
        this.isCounting = false; // 请求失败时停止倒计时
      }

      // 开始倒计时
      const interval = setInterval(() => {
        if (this.countdown === 0) {
          clearInterval(interval);
          this.isCounting = false;
        } else {
          this.countdown--;
        }
      }, 1000);
    },

    // 提交验证码
    async submit() {
      this.$refs.formRef.validate(async (valid) => {
        if (valid) {
          this.loading = true;
          // 调用后端接口验证验证码
          try {
            verifyCodeRequest(this.form).then(response => {
              if (response.code === 200) {
                ElMessage.success('邮箱验证成功');
                // 验证成功后跳转到首页或其他页面
                this.$router.push({name: 'HomePage'});
              } else {
                ElMessage.error(response.message || '验证码错误');
              }
            }).catch(error => {
              console.error('验证验证码失败:', error);
              ElMessage.error('验证验证码失败，请稍后再试');
            }).finally(() => {
              this.loading = false;
            });
          } catch (error) {
            console.error('验证验证码失败:', error);
            ElMessage.error('验证验证码失败，请稍后再试');
          } finally {
            this.loading = false;
          }
        } else {
          ElMessage.error('请输入有效的信息');
        }
      });
    },
  },
};
</script>

<style scoped>
.get-code-btn {
  width: 86px;
  font-size: 13px;
}
.get-code-btn:hover {
  background: #525252;
  font-size: 13px;
}
.get-code-input{
  width: calc(100% - 86px);
}
.message {
  font-size: 18px;
  color: #333;
  margin-bottom: 20px;
}

.verification-form {
  width: 100%;
}

.download-app {
  margin-top: 20px;
  text-align: center;
  position: fixed;
  bottom: 50px;
}
.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.login-box {
  width: 300px;
  padding: 20px;
  border: 1px solid #ddd;
  background-color: var(--background-color);
  text-align: center;
}

.logo {
  font-family: 'Lobster', cursive;
  font-size: 36px;
  margin-bottom: 20px;
}

.login-form {
  width: 100%;
}

.submit-btn {
  width: 100%;
  background-color: #3897f0;
  color: #fff;
  margin-top: 10px;
}

.forgot-password-link {
  margin-top: 15px;
  color: #00376b;
  cursor: pointer;
}

.signup-link {
  margin-top: 20px;
}


.app-links img {
  width: 120px;
  margin: 10px;
}

.el-input.active::placeholder {
  transform: translateY(-20px);
  font-size: 12px;
  color: #5e5e5e;
}
</style>
